import { ObjectOfInterest } from 'api/schema'
import { Feature, MapBrowserEvent, Overlay } from 'ol'
import { Point } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import CircleStyle from 'ol/style/Circle'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import { useEffect, useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import useMapStore from 'store/map'

import styles from './popup/RobotsLayerPopup.module.css'

interface Popup {
  imgSrc: string
}

interface Props {
  objectsOfInterest: ObjectOfInterest[]
}

const ObjectsOfInterestLayer = ({ objectsOfInterest }: Props) => {
  const map = useMapStore(s => s.map)
  const [popupData, setPopupData] = useState<Popup>({ imgSrc: '' })
  const popupRef = useRef<any>(null)

  const handleClosePopupClick = () => {
    popupRef.current.style.display = 'none'
  }

  useEffect(() => {
    if (!objectsOfInterest || !map) return
    const features = objectsOfInterest
      .filter(o => !!o.point.coordinates)
      .map(o => {
        return new Feature({
          geometry: new Point(fromLonLat(o.point.coordinates!)),
          // name: o.name,
          id: o.id,
          imgSrc: o.image,
        })
      })

    const layer = new VectorLayer({
      source: new VectorSource({
        features,
      }),
      properties: {
        name: 'Objects of interest',
        showInLayerList: true,
      },
      style: new Style({
        image: new CircleStyle({
          radius: 8,
          fill: new Fill({
            color: '#9747FF',
          }),
          stroke: new Stroke({
            color: 'white',
            width: 2,
          }),
        }),
      }),
      zIndex: 3,
    })

    map.addLayer(layer)

    const clickHandler = (e: MapBrowserEvent<any>) => {
      const feature = map.forEachFeatureAtPixel(e.pixel, f => f)
      if (!feature) {
        popupRef.current.style.display = 'none'
        return setPopupData({ imgSrc: '' })
      }

      const { imgSrc } = feature.getProperties()
      if (!imgSrc) return

      setPopupData({ imgSrc: imgSrc.replace('https://testfms.168robotics.com', '') })
      popupRef.current.style.display = 'block'
      popupOverlay.setPosition((feature.getGeometry() as Point).getCoordinates())
    }

    map.on('singleclick', clickHandler)

    const popupOverlay = new Overlay({
      element: popupRef.current,
    })
    map.addOverlay(popupOverlay)

    return () => {
      map.removeLayer(layer)
      map.un('singleclick', clickHandler)
      map.removeOverlay(popupOverlay)
    }
  }, [objectsOfInterest, map])
  return (
    <div ref={popupRef} className={styles['ol-popup']} style={{ display: 'none' }}>
      <button className={styles['ol-popup-closer']} onClick={handleClosePopupClick}>
        <MdClose size={20} />
      </button>
      <div>
        <img src={popupData.imgSrc} style={{ maxWidth: '200px', height: 'auto' }} />
      </div>
    </div>
  )
}

export default ObjectsOfInterestLayer
