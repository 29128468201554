import { ReactComponent as ZoneEmpty } from 'images/icons/zone_empty.svg'
import { useTranslation } from 'react-i18next'

import CollapsibleTable from './CollapsibleTableRouting/CollapsibleTableRouting'

import style from './TableRoutingList.module.css'

interface PropsTableUnit {
  showTable?: boolean
}
const TableUnit = ({ showTable = false }: PropsTableUnit) => {
  const { t } = useTranslation()

  return (
    <>
      {showTable ? (
        <CollapsibleTable />
      ) : (
        <div>
          <div className={style.emptyWrapper}>
            <ZoneEmpty />
            <div className={style.emptyTitle}>{t('units.no_zones_title')}</div>
            <div className={style.emptyDescription}>{t('units.no_zones_description')}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default TableUnit
