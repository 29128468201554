import styled from '@emotion/styled'
import { InputBase, TableCell } from '@mui/material'

export const StyledTableCellCollapse = styled(TableCell)(() => ({
  maxWidth: '80px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: 0,
  fontSize: '14px',
  fontWeight: '600',
  fontFamily: 'Montserrat',
  lineHeight: '24px',
  color: '#424040',
  '&:first-of-type': {
    width: '5%',
    borderBottom: 'unset',
  },
  '&:last-child': {
    width: '5%',
    borderBottom: 'unset',
  },
}))

export const StyledTableCell = styled(StyledTableCellCollapse)({
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '15px',
  color: '#9E9E9E',
})

export const BootstrapSelect = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    borderRadius: '8px',
    border: '1px solid var(--Base-40, #E9ECF4)',
    padding: 0,
  },
  '& .MuiSelect-select': {
    border: 'none',
  },
}))

export const BootstrapInput = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    borderRadius: '8px',
    border: 0,
    height: '50px !important',
    padding: 0,
  },
  '& .MuiSelect-select': {
    width: '100%',
    display: 'flex',
  },
}))
