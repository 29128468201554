import BaseMap from 'components/Map/BaseMap'
import CleaningRoutesLayer from 'components/Map/CleaningRoutesLayer'
import CleaningStartFinishPoints from 'components/Map/CleaningStartFinishPoints'
import CleaningZonesLayer from 'components/Map/CleaningZonesLayer'
import DrawModules from 'components/Map/DrawModules'
import GeoTiffsLayer from 'components/Map/GeoTiffsLayer'
import Layers from 'components/Map/Layers'
import MapContainer from 'components/Map/MapContainer'
import MissionGeoTiffsLayer from 'components/Map/MissionGeoTiffsLayer'
import RestrictedZonesLayer from 'components/Map/RestrictedZonesLayer'
import RobotsLayer from 'components/Map/RobotsLayer'
import TransferRoutesLayer from 'components/Map/TransferRoutesLayer'
import UnitsLayer from 'components/Map/UnitsLayer'
import Widgets from 'components/Map/Widgets'
import LayerList from 'components/Map/widgets/LayerList'
import Measurment from 'components/Map/widgets/Measurment'
import Search from 'components/Map/widgets/Search/Search'
import ZoomToRobot from 'components/Map/widgets/ZoomToRobot'
import { Feature as GeoJSONFeature } from 'geojson'
import useAllRobots from 'hooks/useAllRobots'
import useAllUnits from 'hooks/useAllUnits'
import useMission from 'hooks/useMission'
import useRobotZoom from 'hooks/useRobotZoom'
import isEqual from 'lodash.isequal'
import { Feature } from 'ol'
import { Point } from 'ol/geom'
import { fromLonLat } from 'ol/proj'
import React, { useEffect, useMemo, useState } from 'react'
import useMapStore from 'store/map'
import useMissionStore from 'store/mission'
import { MISSION_MAP_DRAWING_MODES } from 'utils/constants'
import { ICleaningZone, ILaunchPoint, IMapboxElement, ITrajectory } from 'utils/interfaces'
import { useShallow } from 'zustand/react/shallow'

interface MissionMapProps {
  children?: React.ReactNode
}

const MissionMap = React.forwardRef<IMapboxElement, MissionMapProps>(() => {
  const {
    mapMode,
    editingLaunchPoint,
    launchPoints,
    restrictZones,
    editingZone,
    cleaningZones,
    transferRoutes,
    missionPoints,
    editingTransfer,
    geoTiffLayers,
    tempZone,
    tempLaunchPoint,
    tempVisualSettings,
    tempTransfer,
    cleaningRoutes,
    visualSettings,
    createFeature,
    editFeature,
  } = useMissionStore(
    useShallow(state => ({
      mapMode: state.mapMode,
      editingLaunchPoint: state.editingLaunchPoint,
      launchPoints: state.launchPoints,
      launchPointsState: state.launchPoints,
      restrictZones: state.restrictZones,
      editingZone: state.editingZone,
      cleaningZones: state.cleaningZones,
      transferRoutes: state.transferRoutes,
      missionPoints: state.missionPoints,
      editingTransfer: state.editingTransfer,
      geoTiffLayers: state.geoTiffLayers,
      tempZone: state.tempZone,
      tempLaunchPoint: state.tempLaunchPoint,
      tempTransfer: state.tempTransfer,
      tempVisualSettings: state.tempVisualSettings,
      cleaningRoutes: state.cleaningRoutes,
      visualSettings: state.visualSettings,
      createFeature: state.createFeature,
      editFeature: state.editFeature,
    }))
  )
  const { data: robots = [], isFetched } = useAllRobots()
  const { data: units } = useAllUnits()
  const setDisplayedGeotiffs = useMapStore(s => s.setDisplayedGeotiffs)
  const { data: missionInfoData } = useMission()
  const [robotOnMap, setRobotOnMap] = useState<Feature<Point>>()
  const zoomToRobot = useRobotZoom({ robotOnMap, missionInfoData })

  const onFeatureCreated = (evt: { features: GeoJSONFeature[] }) => {
    createFeature(evt)
  }

  const onFeatureGeometryEdited = (evt: { features: GeoJSONFeature[]; action: string }) => {
    editFeature(evt)
  }

  const CheckChangesVisualSettings = () => {
    return !isEqual(tempVisualSettings, visualSettings) ? tempVisualSettings : visualSettings
  }

  const launchPointsData: ILaunchPoint[] = launchPoints.filter((launchPoint: any) => launchPoint !== editingLaunchPoint)

  const restrictZonesData = restrictZones.filter((restrictZone: any) => restrictZone.id !== editingZone?.id)

  const cleaningZonesData: ICleaningZone[] = cleaningZones.filter(
    (cleaningZone: any) => cleaningZone.id !== editingZone?.id
  )

  const transferRouteData = useMemo(() => {
    let routes: Array<ITrajectory> = []
    routes.push(...missionPoints.routes, ...transferRoutes)
    routes = routes.filter(route => route.id !== editingTransfer?.id)
    return routes
  }, [missionPoints.routes, transferRoutes, editingTransfer])

  const startPointData = useMemo(() => {
    if (missionPoints.startPoint !== missionPoints.editingPoint) {
      return missionPoints.startPoint
    }
  }, [missionPoints.startPoint, missionPoints.editingPoint])

  const finishPointData = useMemo(() => {
    if (missionPoints.finishPoint !== missionPoints.editingPoint) {
      return missionPoints.finishPoint
    }
  }, [missionPoints.finishPoint, missionPoints.editingPoint])

  useEffect(() => {
    isFetched &&
      robots.filter(r =>
        r.job?.missionName === missionInfoData?.mission?.name
          ? setRobotOnMap(
              new Feature(new Point(fromLonLat([r.lastPosition!.coordinates[0], r.lastPosition!.coordinates[1]])))
            )
          : null
      )
  }, [robots, isFetched, missionInfoData])

  useEffect(() => {
    if (mapMode === MISSION_MAP_DRAWING_MODES.VIEW_GEOTIFF) setDisplayedGeotiffs(geoTiffLayers)
  }, [geoTiffLayers, mapMode, robots])

  return (
    <MapContainer>
      <Layers>
        <BaseMap />
        <RobotsLayer robots={robots} />
        <UnitsLayer units={units} />
        <DrawModules
          mode={mapMode}
          tempZone={tempZone}
          tempLaunchPoint={tempLaunchPoint}
          tempMissionPoint={missionPoints.tempPoint}
          tempTransferRoute={tempTransfer}
          onCreate={onFeatureCreated}
          onUpdate={onFeatureGeometryEdited}
        />
        <CleaningZonesLayer
          cleaningZones={cleaningZonesData}
          zoomToZone={true}
          visualSettings={CheckChangesVisualSettings()}
        />
        <RestrictedZonesLayer restrictedZones={restrictZonesData} visualSettings={CheckChangesVisualSettings()} />
        <CleaningStartFinishPoints startPoint={startPointData} finishPoint={finishPointData} />
        <CleaningRoutesLayer
          cleaningRoutes={cleaningRoutes}
          launchPoints={launchPointsData}
          visualSettings={CheckChangesVisualSettings()}
        />
        <TransferRoutesLayer transferRoutes={transferRouteData} visualSettings={CheckChangesVisualSettings()} />
        {mapMode === MISSION_MAP_DRAWING_MODES.VIEW_GEOTIFF ? (
          <GeoTiffsLayer />
        ) : (
          <MissionGeoTiffsLayer geoTiffs={geoTiffLayers} />
        )}
      </Layers>
      <Widgets>
        <ZoomToRobot onClick={zoomToRobot} />
        <LayerList />
        <Measurment />
        <Search />
      </Widgets>
    </MapContainer>
  )
})

export default MissionMap
