import { Box, Stack, Typography } from '@mui/material'
import robotImage from 'images/robot.png'
import { ROBOT_MODES, robotModes } from 'utils/constants'

interface Props {
  activeMode?: ROBOT_MODES
}

const RobotImageBlock = ({ activeMode }: Props) => {
  return (
    <Stack direction="column" borderRadius={2} paddingX={2} height="100%" bgcolor="info.main">
      <Box width="100%" height="70%">
        <img src={robotImage} alt="robot" width="100%" height="100%" style={{ objectFit: 'contain' }} />
      </Box>
      <Typography variant="caption" align="center" marginTop={2}>
        Operation mode:
      </Typography>
      <Typography variant="h6" align="center">
        {activeMode !== undefined ? robotModes[activeMode] : '-'}
      </Typography>
    </Stack>
  )
}

export default RobotImageBlock
