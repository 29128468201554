import { Box, Grid, Stack, Typography } from '@mui/material'
import unitPopupImage from 'images/UnitPopupImage.png'
import { Fragment } from 'react'
import { LuParkingCircle } from 'react-icons/lu'
import { MdOutlineBattery0Bar, MdOutlineLocalCarWash } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'
import { SLOT_STATUSES } from 'utils/constants'
import { UnitPopupData } from 'utils/interfaces'
import { StatusUtils } from 'utils/interfaces'

interface Props {
  data?: UnitPopupData
}

const UnitPopup = ({ data }: Props) => {
  const navigate = useNavigate()

  if (!data) return null

  const { id, name, jobName, missionCompletion, robotsCount, slotsStatuses } = data

  const handleClick = () => {
    navigate(`${PATH.UNITS_LIST}/${id}`)
  }

  const statusUtils: StatusUtils = {
    0: <LuParkingCircle />,
    1: <MdOutlineLocalCarWash />,
    2: <MdOutlineBattery0Bar />,
  }

  const statusIcon = (status: SLOT_STATUSES[]): JSX.Element[] | string => {
    if (status.length != 0) {
      return status.map(s => <Fragment key={s}>{statusUtils[s]}</Fragment>)
    }
    return '-'
  }

  return (
    <Grid display="flex" container>
      <Grid item xs={4} marginLeft={0.5}>
        <Box bgcolor="info.main" borderRadius={2}>
          <img src={unitPopupImage} width="60" height="60" alt="robot" style={{ objectFit: 'contain' }} />
          <Box
            bgcolor="#30B03D"
            width="5%"
            height="5%"
            borderRadius="50%"
            position="absolute"
            top="28%"
            left="28%"
          ></Box>
        </Box>
      </Grid>
      <Grid item xs={5} marginLeft={1}>
        <Box display="flex">
          <Typography onClick={() => handleClick()} variant="body1" sx={{ cursor: 'pointer' }}>
            {name}
          </Typography>
        </Box>
        <Typography variant="caption" color="#9E9E9E">
          {`Robots: ${robotsCount}`}
        </Typography>
        {jobName !== undefined && (
          <Typography variant="caption" color="#9E9E9E">
            {`${jobName}`}
          </Typography>
        )}
      </Grid>
      <Stack width="100%" height={{ sm: '40%' }} direction="row" spacing={1} margin={0.5}>
        <Grid
          item
          xs={6}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          borderRadius={2}
          padding={1}
          bgcolor="info.main"
        >
          <Typography variant="caption" color="#9E9E9E">
            Time left
          </Typography>
          <Typography variant="textPopup">{'-'}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          borderRadius={2}
          padding={1}
          bgcolor="info.main"
        >
          <Typography variant="caption" color="#9E9E9E">
            Completed
          </Typography>
          <Typography variant="textPopup">{missionCompletion ?? '-'}</Typography>
        </Grid>
      </Stack>
      <Grid
        item
        display="flex"
        flexDirection="column"
        xs={12}
        height="100%"
        borderRadius={2}
        bgcolor="info.main"
        padding={1}
        margin={0.5}
      >
        <Typography variant="caption" color="#9E9E9E">
          Boxing status
        </Typography>
        <Box flexDirection="row">{slotsStatuses !== undefined && statusIcon(slotsStatuses)}</Box>
      </Grid>
    </Grid>
  )
}

export default UnitPopup
