import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as NotificationSvg } from 'images/notification.svg'
import getTestId from 'utils/getTestId'

import style from './NotificationsUser.module.css'

const NotificationsUser = () => {
  return (
    <div className={style.userMenuBlock} data-test-id={getTestId('user-notifications')}>
      <IconButton size="medium" sx={{ border: 1, borderColor: '#D7D7D7' }}>
        <Box
          sx={{
            position: 'absolute',
            bottom: '26px',
            left: '23px',
            backgroundColor: '#EE6612',
            height: 10,
            width: 10,
            borderRadius: '50%',
            border: 'solid white',
            borderWidth: '2px',
          }}
        ></Box>
        <NotificationSvg />
      </IconButton>
    </div>
  )
}

export default NotificationsUser
