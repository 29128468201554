import { useMutation } from '@tanstack/react-query'
import { MainService, MissionRead } from 'api/schema'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'

const useDuplicateMission = () => {
  const navigate = useNavigate()

  return useMutation<MissionRead, Error, number>({
    mutationFn: MainService.mainMissionDuplicateCreate,
    onSuccess: newMission => navigate(`${PATH.MISSION}${newMission.id}`),
  })
}

export default useDuplicateMission
