import { Box, Grid, Typography } from '@mui/material'
import { ReactComponent as MissionEndSvg } from 'images/icons/mission_end.svg'
import { ReactComponent as MissionStartSvg } from 'images/icons/mission_start.svg'
import Control from 'ol/control/Control'
import { useEffect, useRef, useState } from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import useMapStore from 'store/map'
import { IVisualizationSettings } from 'utils/interfaces'

import RouteSvg from './RouteSvg'
import ZoneSvg from './ZoneSvg'

import style from './Legend.module.css'

interface Props {
  visualSettings: IVisualizationSettings
}

const Legend = ({ visualSettings }: Props) => {
  const map = useMapStore(s => s.map)
  const controlRef = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!map || !controlRef) return

    const legendControl = new Control({
      element: controlRef.current as HTMLInputElement,
    })

    map.addControl(legendControl)

    return () => {
      map?.removeControl(legendControl)
    }
  }, [map, controlRef])

  return (
    <div>
      <div
        ref={controlRef}
        className="ol-unselectable ol-control"
        style={{ top: '20px', left: '.5em', backgroundColor: 'transparent', display: 'flex' }}
      >
        {isOpen && (
          <div className={style.legend}>
            <Grid container>
              <Grid item xs={6} display="flex" alignItems="center">
                <Box marginRight={1}>
                  <ZoneSvg border={visualSettings.clean_border_color} fill={visualSettings.clean_fill_color} />
                </Box>
                <Typography variant="body2">Cleaning areas</Typography>
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center">
                <Box marginRight={1}>
                  <RouteSvg color={visualSettings.transfer_route_color} />
                </Box>
                <Typography variant="body2">Transport routes</Typography>
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center">
                <Box marginRight={1}>
                  <ZoneSvg border={visualSettings.restrict_border_color} fill={visualSettings.restrict_fill_color} />
                </Box>
                <Typography variant="body2">Restricted areas</Typography>
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center">
                <Box marginRight={1}>
                  <MissionStartSvg />
                </Box>
                <Typography variant="body2">Mission start</Typography>
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center">
                <Box marginRight={1}>
                  <RouteSvg color={visualSettings.cleaning_route_color} />
                </Box>
                <Typography variant="body2">Cleaning route</Typography>
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center">
                <Box marginRight={1}>
                  <MissionEndSvg />
                </Box>
                <Typography variant="body2">Mission end</Typography>
              </Grid>
            </Grid>
          </div>
        )}
        <button onClick={() => setIsOpen(!isOpen)} className={style.button}>
          {isOpen ? (
            <MdKeyboardArrowLeft color="#B7B7B7" size="16px" />
          ) : (
            <MdKeyboardArrowRight color="#B7B7B7" size="16px" />
          )}
          Legend
        </button>
      </div>
    </div>
  )
}

export default Legend
