const RouteSvg = ({ color }: { color: string }) => (
  <svg width="32" height="10" viewBox="0 0 32 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.99996C3.16667 2.33329 7.6 -1.40004 8 4.99996C8.5 13 19 5.5 21.5 4C24 2.5 30.5 4 31 4"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default RouteSvg
