import { useMutation } from '@tanstack/react-query'
import { RobotApi } from 'api/RobotApi'
import { MissionJob } from 'api/schema'

interface SendData {
  missionId: number
  robotId: number
}
const useSelectMission = () =>
  useMutation<MissionJob, Error, SendData>({
    mutationFn: RobotApi.createJob,
  })

export default useSelectMission
