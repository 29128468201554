import { Polygon } from 'ol/geom'
import { useState } from 'react'
import useMapStore from 'store/map'

const useZoomToPolygon = () => {
  const map = useMapStore(s => s.map)
  const missionExtent = useMapStore(s => s.missionExtent)

  const [prevExtent, setPrevExtent] = useState<number[] | null>(missionExtent)

  const zoomToPolygon = (polygon: Polygon) => {
    const polygonExtent = polygon.getExtent()
    const targetExtent = polygonExtent.join('') === prevExtent?.join('') ? missionExtent : polygonExtent
    targetExtent && map?.getView().fit(targetExtent, { padding: [100, 100, 100, 100] })
    setPrevExtent(targetExtent)
  }

  return { zoomToPolygon }
}

export default useZoomToPolygon
