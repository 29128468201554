import { Box } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { StyledTableCell } from 'utils/themeUnit'

import Row from './Row'

interface Props {
  rows?: { id: number; zone: string; part: string; length: number; area: number; workingTime: string; works: Work[] }[]
}

interface Work {
  id: number
  zone: string
  part: string
  length: number
  area: number
  workingTime: string
}

export default function CollapsibleTableRouting({ rows }: Props) {
  return (
    <Box paddingX={'16px'} bgcolor="info.main" height={'100%'}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>Zones</StyledTableCell>
            <StyledTableCell>Part</StyledTableCell>
            <StyledTableCell>Length, m</StyledTableCell>
            <StyledTableCell>Area, m2</StyledTableCell>
            <StyledTableCell sx={{ width: '20%' }}>Working time </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map(({ id, zone, part, length, area, workingTime, works }) => (
              <Row
                key={id}
                id={id}
                zone={zone}
                dateNext={part}
                datePrevious={length}
                dateSecond={area}
                dateWorks={workingTime}
              >
                {works.map(({ id, zone, part, length, area }: Work) => (
                  <Row key={id} id={id} zone={zone} dateNext={part} datePrevious={length} dateSecond={area} />
                ))}
              </Row>
            ))}
        </TableBody>
      </Table>
    </Box>
  )
}
