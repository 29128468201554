import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import useUnit from 'hooks/useUnit'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import useUnitTelemetryStore from 'store/unitTelemetry'
import { ROBOT_MODES, robotModes } from 'utils/constants'

const useRobotsInUnitTable = () => {
  const params = useParams()
  const { data: unit } = useUnit(Number(params.unitId))
  const robots = useUnitTelemetryStore(s => s.robotsTelemetry)
  const isService = robots.length === 0
  const columnsUnitRobots: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.1,
      disableColumnMenu: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <Link to={`/asset/${params.row.id}`}>{params.row.name}</Link>
      ),
    },
    {
      field: isService ? 'mission_name' : 'missionName',
      headerName: 'Mission',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, Date>) =>
        isService ? (
          <Link to={`/mission/${params.row.mission_id}`}>{params.row.mission_name}</Link>
        ) : (
          <Link to={`/mission/${params.row.missionId}`}>{params.row.missionName}</Link>
        ),
    },
    {
      field: isService ? 'mission_progress' : 'missionProgress',
      headerName: 'Completed, %',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: isService ? 'active_mode' : 'activeMode',
      headerName: 'Control Mode',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: ({ row }: any) =>
        isService ? robotModes[row.active_mode as ROBOT_MODES] : robotModes[row.activeMode as ROBOT_MODES],
    },
    {
      field: isService ? 'battery_power' : 'batteryPower',
      headerName: 'Battery level, %',
      flex: 1,
      disableColumnMenu: true,
    },
  ]

  return {
    columnsUnitRobots,
    robots: isService ? (unit?.robots as []) : robots,
    countRobots: isService ? unit?.robots_count : robots.length,
  }
}

export default useRobotsInUnitTable
