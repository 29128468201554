import { useQuery } from '@tanstack/react-query'
import { CleaningRoutes, MainService } from 'api/schema'
import useMapStore from 'store/map'

const useAllCleaningRoutes = () => {
  const zoom = useMapStore(s => s.zoom)
  const extent = useMapStore(s => s.extent)

  return useQuery<CleaningRoutes[], Error>({
    queryKey: ['cleaningRoutes', zoom, extent.join(',')],
    queryFn: () => MainService.mainCleaningRoutesLayerList(extent.join(',')),
    refetchOnWindowFocus: true,
    enabled: zoom > 17 && extent.length > 0,
    staleTime: 1000 * 60, // 1 min
  })
}
export default useAllCleaningRoutes
