import Loader from 'components/Loader/Loader'
import useMission from 'hooks/useMission'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useMissionStore from 'store/mission'
import { useShallow } from 'zustand/react/shallow'

import MissionView from './MissionView/MissionView'

import style from './Mission.module.css'

const Mission = () => {
  const params = useParams()

  const missionId = parseFloat(params.missionId || '')

  const { setMission, resetMission } = useMissionStore(
    useShallow(state => ({
      setMission: state.setMission,
      resetMission: state.resetMission,
    }))
  )
  const { data: mission, error, isLoading, isFetched } = useMission(missionId)

  useEffect(() => {
    isFetched && setMission(mission!)
    return () => resetMission()
  }, [isFetched])

  if (isLoading) return <Loader />

  if (error) return <span>{error.message}</span>

  return (
    <div className={style.container}>
      <MissionView />
    </div>
  )
}

export default Mission
