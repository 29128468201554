import { Box, Grid, Stack, Typography } from '@mui/material'

interface Props {
  name?: string
  missionName?: string
  cleaned?: number
  robots?: { id: number; name: string }[]
}

const CleaningZonePopup = ({ name, missionName, cleaned, robots = [] }: Props) => {
  return (
    <Grid display="flex" container spacing={1}>
      <Grid item xs={12}>
        <Box display="flex">
          <Typography variant="body1">{name}</Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          borderRadius={2}
          paddingX={1}
          bgcolor="info.main"
        >
          <Typography variant="caption" align="left" color="#9E9E9E" marginTop={1}>
            Mission
          </Typography>
          <Stack direction="row" alignItems="baseline">
            <Typography variant="body2" align="left" marginRight="2px">
              {missionName ?? '-'}
            </Typography>
          </Stack>

          <div></div>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          borderRadius={2}
          paddingX={1}
          bgcolor="info.main"
        >
          <Typography variant="caption" align="left" color="#9E9E9E" marginTop={1}>
            Completed
          </Typography>
          <Stack direction="row" alignItems="baseline">
            <Typography variant="h6" align="left" marginRight="2px">
              {cleaned?.toFixed() ?? '-'}
            </Typography>
            <Typography variant="caption">%</Typography>
          </Stack>
          <div></div>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          borderRadius={2}
          paddingX={1}
          bgcolor="info.main"
        >
          <Typography variant="caption" align="left" color="#9E9E9E" marginTop={1}>
            Robots
          </Typography>
          <Typography variant="body2" align="left" marginBottom={1}>
            {robots.map(r => r.name).join(', ')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CleaningZonePopup
