import RoutingUnitList from '../../components/UnitMission/RoutingUnitList/RoutingUnitList'
import ScenariosUnitList from '../../components/UnitMission/ScenariosUnitList/ScenariosUnitList'
import { Box, Chip, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import ReturnButton from 'components/Button/ReturnButton'
import { ReactComponent as GeoTiff } from 'images/icons/geotiff.svg'
import { ReactComponent as GeoTiffOn } from 'images/icons/geotiffOn.svg'
import { ReactComponent as RoutingIconOff } from 'images/icons/routingOff.svg'
import { ReactComponent as RoutingIconOn } from 'images/icons/routingOn.svg'
import { ReactComponent as ScenariosIconOff } from 'images/icons/scenariosOff.svg'
import { ReactComponent as ScenariosIconOn } from 'images/icons/scenariosOn.svg'
import { ReactComponent as SettingsOff } from 'images/icons/settingsOff.svg'
import { ReactComponent as SettingsOn } from 'images/icons/settingsOn.svg'
import MissionMap from 'pages/Mission/MissionView/MissionMap/MissionMap'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'

import style from './UnitMissionPage.module.css'

const UnitMissionPage = () => {
  const [activeTab, setActiveTab] = useState<'routung' | 'scenarios' | 'geoTiff' | 'settings'>('routung')
  const navigate = useNavigate()

  const displayTab = {
    routung: <RoutingUnitList showTitle={'Select Unit'} />,
    scenarios: <ScenariosUnitList />,
    geoTiff: <div>geotiff</div>,
    settings: <div>settings</div>,
  }

  return (
    <div className={style.container}>
      <Grid container height="100%" width="100%">
        <Grid xs={12} md={6} item paddingY={2} height="100%">
          <Box
            height="100%"
            maxHeight="100%"
            paddingRight={{ xs: 0, md: 2 }}
            display="flex"
            flexDirection="column"
            alignItems=""
          >
            <Box height="100%" width="100%" display="flex" flexDirection="column">
              <Stack direction="row" alignItems="center" width="100%" height="9%">
                <ReturnButton onClick={() => navigate(PATH.ROBOT_LIST)} />
                <Stack direction="column" height="48px" maxHeight="48px" justifyContent="space-between" mx={4}>
                  <Typography variant="h5">{'Test'}</Typography>
                  <Typography variant="caption">ID: {'1'}</Typography>
                </Stack>
                <Chip sx={{ color: '#424040' }} label={'Partially calculated'} variant="filled" size="small" />
              </Stack>
              <Box height="91%">
                <Box height="8%">
                  <Tabs
                    value={activeTab}
                    onChange={(e, newValue) => {
                      setActiveTab(newValue)
                    }}
                    variant="fullWidth"
                    centered
                    indicatorColor="secondary"
                    TabIndicatorProps={{ sx: { display: 'none' } }}
                    aria-label="icon label tabs example"
                  >
                    <Tab
                      icon={activeTab === 'routung' ? <RoutingIconOn /> : <RoutingIconOff />}
                      iconPosition="start"
                      value="routung"
                      label="Routing"
                    />
                    <Tab
                      icon={activeTab === 'scenarios' ? <ScenariosIconOn /> : <ScenariosIconOff />}
                      iconPosition="start"
                      value="scenarios"
                      label="Scenarios"
                    />
                    <Tab
                      icon={activeTab === 'geoTiff' ? <GeoTiffOn /> : <GeoTiff />}
                      iconPosition="start"
                      value="geoTiff"
                      label="GeoTIFF"
                    />
                    <Tab
                      icon={activeTab === 'settings' ? <SettingsOn /> : <SettingsOff />}
                      iconPosition="start"
                      value="settings"
                      label="Settings"
                    />
                  </Tabs>
                </Box>
                <Box borderRadius={2} height="92%">
                  {displayTab[activeTab]}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={6} item height="100%" paddingY={2}>
          <Box width="100%" height="100%" borderRadius={2} overflow="hidden">
            <MissionMap />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default UnitMissionPage
