import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import OverlayNoRowsTable from 'components/OverlayNoRowsTable/OverlayNoRowsTable'
import { ReactComponent as AttenSvg } from 'images/icons/attention.svg'
import { ReactComponent as SortIcon } from 'images/icons/sort_icon.svg'
import { Link, useNavigate } from 'react-router-dom'
import { getPercentComplete } from 'utils/calcFunctions'
import { PATH } from 'utils/constants'
import { IRobotInfo } from 'utils/interfaces'
import { StyledDataGrid } from 'utils/themeGrid'

import style from './RobotListView.module.css'

const RobotList = ({ robots, error }: { robots: IRobotInfo[]; error?: any }) => {
  const navigate = useNavigate()

  const columnsRobotList: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      disableColumnMenu: true,
      flex: 0.05,
    },
    {
      field: 'name',
      headerName: 'Name',
      disableColumnMenu: true,
      flex: 0.15,
      renderCell: params => {
        const value = params.value
        const checkIcon = params.row.networkStatus === 'online'
        return (
          <div className={style.iconBox}>
            {value}
            {!checkIcon && (
              <div className={style.iconFlex}>
                <AttenSvg />
              </div>
            )}
          </div>
        )
      },
    },
    {
      field: 'mission',
      headerName: 'Mission',
      disableColumnMenu: true,
      flex: 0.15,
      valueGetter: params => params.row.job?.missionName,
    },
    {
      field: 'controlMode',
      headerName: 'Control mode',
      disableColumnMenu: true,
      flex: 0.1,
    },
    {
      field: 'batteryPower',
      headerName: 'Battery level, %',
      sortable: true,
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: params => params.row.batteryPower?.toFixed(),
    },
    {
      field: 'completed',
      headerName: 'Completed, %',
      flex: 0.12,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: ({ row }: GridValueGetterParams<IRobotInfo>) =>
        getPercentComplete(row.job?.progress, row.job?.overall),
    },
    {
      field: 'unitId',
      headerName: 'Unit ID',
      flex: 0.05,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const value = params?.value as string
        return <div>{value && <Link to={`/units/${value}`}>{value}</Link>}</div>
      },
    },
  ]

  const goToRobot = (missionId: number) => {
    navigate(`${PATH.ROBOT_LIST}/${missionId}`)
  }
  const handleClick = (params: GridCellParams) => {
    const cellValue = params.row.id
    const clickedField = params.colDef.field
    if (clickedField !== 'unitId') {
      goToRobot(cellValue)
    }
  }

  if (error) return <div>{error.message}</div>

  return (
    <>
      <StyledDataGrid
        rows={robots}
        columns={columnsRobotList}
        showColumnVerticalBorder={false}
        showCellVerticalBorder={false}
        disableRowSelectionOnClick={true}
        autoHeight={true}
        hideFooter={true}
        columnHeaderHeight={35}
        slots={{
          columnSortedAscendingIcon: SortIcon,
          columnSortedDescendingIcon: SortIcon,
          noRowsOverlay: () => <OverlayNoRowsTable />,
        }}
        sx={{
          cursor: 'pointer',
          boxShadow: 0,
          border: 0,
          fontStyle: 'normal',
        }}
        onCellClick={params => {
          handleClick(params)
        }}
      />
    </>
  )
}

export default RobotList
