import { Box, Stack, Tab, Tabs, Typography } from '@mui/material'
import { UnitRead } from 'api/schema'
import ReturnButton from 'components/Button/ReturnButton'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import UnitDetails from './UnitDetails'

interface Props {
  unit?: UnitRead
}

const UnitView = ({ unit }: Props) => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<'unitDetails' | 'missionDetails' | 'scheduledWork' | 'routineWork'>(
    'unitDetails'
  )

  const displayTab = {
    unitDetails: <UnitDetails />,
    missionDetails: <div> mission details </div>,
    scheduledWork: <div> scheduled work</div>,
    routineWork: <div> routine work </div>,
  }

  return (
    <Box
      height="100%"
      maxHeight="100%"
      paddingRight={{ xs: 0, md: 2 }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems=""
    >
      <Stack direction="row" alignItems="center" width="100%">
        <ReturnButton onClick={() => navigate(-1)} disabled={window.history.length === 1} />
        <Stack direction="column" height="48px" maxHeight="48px" justifyContent="space-between" mx={4}>
          <Typography sx={{ fontSize: { xs: 18, md: 23 } }}>{unit?.name}</Typography>
          <Typography variant="caption">ID: {unit?.id}</Typography>
        </Stack>
        {/* <Chip
          label={}
          variant="filled"
          color={status === 'online' ? 'primary' : 'warning'}
          size="small"
        /> */}
      </Stack>
      <Box paddingY={2}>
        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
          <Tab value="unitDetails" label="Unit details" />
          <Tab value="missionDetails" label="Mission details" />
          <Tab value="scheduledWork" label="Scheduled work" />
          <Tab value="routineWork" label="Routine work" />
        </Tabs>
      </Box>
      <Box width="100%" height="100%" overflow="auto">
        {displayTab[activeTab]}
      </Box>
    </Box>
  )
}

export default UnitView
