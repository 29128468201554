import { Box, Grid, Stack } from '@mui/material'
import ReturnButton from 'components/Button/ReturnButton'
import Title from 'components/Title/Title'
import { Polygon } from 'geojson'
import useCreateCleaningZone from 'hooks/mission/useCreateCleaningZone'
import useCreateRestrictZone from 'hooks/mission/useCreateRestrictZone'
import useEditCleaningZone from 'hooks/mission/useEditCleaningZone'
import useEditRestrictZone from 'hooks/mission/useEditRestrictZone'
import useMission from 'hooks/useMission'
import { ReactComponent as LoaderSvg } from 'images/icons/loader.svg'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useMissionStore from 'store/mission'
import {
  MISSION_CALCULATING_STATUSES,
  MISSION_PAGE_MODES,
  MISSION_ZONE_TYPES,
  PATH,
  PROGRESS_TYPES,
  TIMEOUT_FETCHING_STATUS,
} from 'utils/constants'
import { useShallow } from 'zustand/react/shallow'

import MissionButtons from './MissionButtons/MissionButtons'
import MissionSettingsModal from './MissionButtons/MissionSettingsModal/MissionSettingsModal'
import MissionMap from './MissionMap/MissionMap'
import DrawZoneMode from './Modes/DrawZone/DrawZoneMode'
import GeoTiffView from './Modes/GeoTiff/GeoTiffView'
import ViewCreateZones from './Modes/ViewZone/ViewCreateZones'

import style from './MissionView.module.css'

const MissionView = () => {
  const navigate = useNavigate()
  const {
    mode,
    calculatingStatus,
    editingZone,
    tempZone,
    tempLaunchPoint,
    missionInfo,
    setEditingZone,
    setTempZone,
    setSavingZoneProgress,
    createCleaningZone,
    createRestrictZone,
    editChangeCleaningZone,
    setChangeRestrictZone,
    fetchCalculationStatus,
  } = useMissionStore(
    useShallow(state => ({
      mode: state.mode,
      calculatingStatus: state.calculatingStatus,
      editingZone: state.editingZone,
      tempZone: state.tempZone,
      tempLaunchPoint: state.tempLaunchPoint,
      missionInfo: state.missionInfo,
      setEditingZone: state.setEditingZone,
      setTempZone: state.setTempZone,
      setSavingZoneProgress: state.setSavingZoneProgress,
      createCleaningZone: state.createCleaningZone,
      createRestrictZone: state.createRestrictZone,
      editChangeCleaningZone: state.editChangeCleaningZone,
      setChangeRestrictZone: state.setChangeRestrictZone,
      fetchCalculationStatus: state.fetchCalculationStatus,
    }))
  )

  const { data: missionInfoData } = useMission()
  const { mutateAsync: setCleaningZone } = useCreateCleaningZone()
  const { mutateAsync: setRestrictZone } = useCreateRestrictZone()
  const { mutateAsync: setEditCleaningZone } = useEditCleaningZone()
  const { mutateAsync: setEditRestrictZone } = useEditRestrictZone()

  useEffect(() => {
    const interval = setInterval(async () => {
      if (missionInfoData) {
        try {
          fetchCalculationStatus(missionInfoData.mission?.id)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Unable to get mission status')
        }
      }
    }, TIMEOUT_FETCHING_STATUS)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const onSaveZoneClicked = async (zoneName: string, zoneDescription: string, zoneType: MISSION_ZONE_TYPES) => {
    if (!tempZone) {
      return
    }
    if (zoneType === MISSION_ZONE_TYPES.CLEANING) {
      setCleaningZone({
        missionId: missionInfoData?.mission.id || -1,
        name: zoneName,
        description: zoneDescription,
        geometry: tempZone.geometry as Polygon,
        launchPointGeometry: tempLaunchPoint?.geometry,
      })
        .then(result => {
          createCleaningZone(result.zone, result.launchPoint)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('Error creating cleaning zone', error)
          setTempZone(undefined)
          setSavingZoneProgress(PROGRESS_TYPES.ERROR)
        })
    } else if (zoneType === MISSION_ZONE_TYPES.RESTRICT) {
      setRestrictZone({
        missionId: missionInfoData?.mission.id || -1,
        name: zoneName,
        description: zoneDescription,
        geometry: tempZone.geometry as Polygon,
      })
        .then(result => {
          createRestrictZone(result)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('Error creating restrict zone', error)
          setTempZone(undefined)
          setSavingZoneProgress(PROGRESS_TYPES.ERROR)
        })
    }
  }

  const onSaveChangedZoneClicked = async (zoneName: string, zoneDescription: string, zoneType: MISSION_ZONE_TYPES) => {
    if (!tempZone) {
      return
    }
    if (zoneType === MISSION_ZONE_TYPES.CLEANING) {
      setEditCleaningZone({
        missionId: missionInfoData?.mission.id || -1,
        name: zoneName,
        zoneId: tempZone.properties?.zone_id,
        description: zoneDescription,
        geometry: tempZone.geometry as Polygon,
        launchPoint: tempLaunchPoint?.geometry,
      })
        .then(result => {
          editChangeCleaningZone(tempZone?.properties?.zone_id || -1, result.zone, result.launchPoint)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('Error editing cleaning zone', error)
          setEditingZone(undefined)
          setSavingZoneProgress(PROGRESS_TYPES.ERROR)
        })
    } else {
      setEditRestrictZone({
        missionId: missionInfoData?.mission.id || -1,
        name: zoneName,
        zoneId: tempZone.properties?.zone_id,
        description: zoneDescription,
        geometry: tempZone.geometry as Polygon,
        launchPoint: tempLaunchPoint?.geometry,
      })
        .then(result => {
          setChangeRestrictZone(result)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('Error editing restrict zone', error)
          setEditingZone(undefined)
        })
    }
  }

  const modeList = {
    [`${MISSION_PAGE_MODES.DRAW_ZONE}`]: <DrawZoneMode onSaveClicked={onSaveZoneClicked} />,
    [`${MISSION_PAGE_MODES.VIEW_ZONES}`]: <ViewCreateZones />,
    [`${MISSION_PAGE_MODES.EDIT_ZONE}`]: <DrawZoneMode onSaveClicked={onSaveChangedZoneClicked} zone={editingZone} />,
    [`${MISSION_PAGE_MODES.VIEW_GEOTIFF}`]: <GeoTiffView />,
    [`${MISSION_PAGE_MODES.VIEW_SETTINGS}`]: <MissionSettingsModal missionId={missionInfo!.id} />,
  }

  return (
    <>
      <Grid container height="100%" width="100%">
        <Grid xs={12} md={6} item paddingY={2} height="100%">
          <Box height="100%" maxHeight="100%" paddingRight={{ xs: 0, md: 2 }} display="flex" flexDirection="column">
            <Box width="100%" height="100%">
              <Stack direction="row" alignItems="center" gap={2} height="10%" minHeight="60px">
                <ReturnButton
                  onClick={() => {
                    navigate(PATH.MISSION_LIST)
                  }}
                />
                <>
                  <Title text={missionInfo?.name} />
                  {(calculatingStatus === MISSION_CALCULATING_STATUSES.CALCULATING ||
                    calculatingStatus === MISSION_CALCULATING_STATUSES.UNKNOWN) && (
                    <LoaderSvg className={style.loader} />
                  )}
                </>
              </Stack>
              <Stack spacing={2} height="90%">
                <Box>
                  <MissionButtons />
                </Box>

                <Box bgcolor="info.main" borderRadius={2} height="100%" overflow="auto">
                  {modeList[mode]}
                </Box>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={6} item height="100%" paddingY={2} paddingTop={{ xs: 3, sm: 3, lg: 2 }}>
          <Box width="100%" height="100%" borderRadius={2} overflow="hidden">
            <MissionMap />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default MissionView
