import {
  CleaningZone,
  CleaningZoneRead,
  GeoTiffLayer,
  MainService,
  Mission,
  MissionGeoTiffLayers,
  MissionGeoTiffLayersRequest,
  MissionPointTransferRouteRead,
  MissionRead,
  MissionReadList,
  MissionStatus,
  ObjectOfInterest,
  RestrictZone,
  TransferRouteRead,
} from 'api/schema/index'
import { LineString, Polygon } from 'geojson'
import chunk from 'lodash.chunk'
import {
  DEFAULT_VIZUALIZATION_SETTINGS,
  GEOTIFF_LAYER_STATUSES,
  MISSION_CALCULATING_STATUSES,
  MISSION_ZONE_TYPES,
  TRANSFER_CALCULATING_STATUSES,
  TRANSFER_TYPE,
} from 'utils/constants'
import {
  ICleaningZone,
  IGeoTiffLayer,
  ILaunchPoint,
  IMission,
  IMissionInfo,
  IPoint,
  IRestrictZone,
  IRoute,
  ITransferRoute,
  IVisualizationSettings,
  IZone,
  IZoneTransferRoute,
  PropsPoints,
  ZoneCreateData,
  ZoneUpdateData,
} from 'utils/interfaces'

const normalizeCleaningZone = (zoneRaw: CleaningZoneRead | CleaningZone): ICleaningZone => {
  return {
    id: 'cleaning_' + zoneRaw.id,
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: (zoneRaw.geometry as Polygon).coordinates,
    },
    properties: {
      zone_type: MISSION_ZONE_TYPES.CLEANING,
      name: zoneRaw.name || '',
      description: zoneRaw.description || '',
      zone_id: zoneRaw.id,
      id_on_map: 'cleaning_' + zoneRaw.id,
      status: (zoneRaw as CleaningZoneRead).status
        ? ((zoneRaw as CleaningZoneRead).status as MISSION_CALCULATING_STATUSES)
        : MISSION_CALCULATING_STATUSES.NEW,
      readyForCalculation: (zoneRaw as CleaningZoneRead).is_ready_to_start_calculation
        ? (zoneRaw as CleaningZoneRead).is_ready_to_start_calculation
        : true,
      length: (zoneRaw as CleaningZoneRead).cleaning_route_geometry_length || undefined,
      area: (zoneRaw as CleaningZoneRead).geometry_area || undefined,
      executionTime: (zoneRaw as CleaningZoneRead).cleaning_route_execution_time || undefined,
    },
  }
}

const normalizeRestrictZone = (zoneRaw: RestrictZone): IRestrictZone => {
  return {
    id: 'restrict_' + zoneRaw.id,
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: (zoneRaw.geometry as Polygon).coordinates,
    },
    properties: {
      zone_type: MISSION_ZONE_TYPES.RESTRICT,
      name: zoneRaw.name || '',
      description: zoneRaw.description || '',
      zone_id: zoneRaw.id,
      id_on_map: 'restrict_' + zoneRaw.id,
    },
  }
}

const normalizeCleaningRoute = (cleaningZoneRaw: CleaningZoneRead): IRoute => {
  return {
    id: 'cleaning_route_' + cleaningZoneRaw.id,
    type: 'Feature',
    geometry: {
      type: 'MultiLineString',
      coordinates: cleaningZoneRaw.cleaning_route_geometry?.coordinates
        ? chunk(cleaningZoneRaw.cleaning_route_geometry.coordinates[0], 30000)
        : [],
    },
    properties: {
      zone_id: cleaningZoneRaw.id,
      id_on_map: 'cleaning_' + cleaningZoneRaw.id,
    },
  }
}

const normalizeTransferRoute = (transferRouteRaw: TransferRouteRead): IZoneTransferRoute => {
  return {
    id: 'transfer_route_' + transferRouteRaw.id,
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: transferRouteRaw.geometry?.coordinates || [],
    },
    userGeometry: {
      type: 'LineString',
      coordinates: transferRouteRaw.user_geometry?.coordinates || transferRouteRaw.geometry?.coordinates || [],
    },
    properties: {
      id: transferRouteRaw.id,
      zone_id_from: transferRouteRaw.cleaning_zone_from_id,
      zone_id_to: transferRouteRaw.cleaning_zone_to_id,
      id_on_map: 'transfer_route_' + transferRouteRaw.id,
      type: TRANSFER_TYPE.MIDDLE,
      status: transferRouteRaw.status
        ? (transferRouteRaw.status as TRANSFER_CALCULATING_STATUSES)
        : TRANSFER_CALCULATING_STATUSES.NEW,
      length: transferRouteRaw.geometry_length || transferRouteRaw.user_geometry_length || undefined,
    },
  }
}

const normalizeRoute = (transferRouteRaw: MissionPointTransferRouteRead): ITransferRoute => {
  return {
    id: 'mission_route_' + transferRouteRaw.id,
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: transferRouteRaw.geometry?.coordinates || transferRouteRaw.user_geometry?.coordinates || [],
    },
    userGeometry: {
      type: 'LineString',
      coordinates: transferRouteRaw.user_geometry?.coordinates || transferRouteRaw.geometry?.coordinates || [],
    },
    properties: {
      id: transferRouteRaw.id,
      type: transferRouteRaw.point_type === 'start' ? TRANSFER_TYPE.START : TRANSFER_TYPE.FINISH,
      status: transferRouteRaw.status
        ? (transferRouteRaw.status as TRANSFER_CALCULATING_STATUSES)
        : TRANSFER_CALCULATING_STATUSES.NEW,
      length: transferRouteRaw.geometry_length || transferRouteRaw.user_geometry_length || undefined,
    },
  }
}

const normalizeLaunchPoints = (cleaningZonesRaw: CleaningZoneRead[]): ILaunchPoint[] => {
  const result = cleaningZonesRaw.filter(zone => zone.starting_point).map(zone => normalizeLaunchPoint(zone))
  return result as ILaunchPoint[]
}

const normalizeLaunchPoint = (cleaningZoneRaw: CleaningZoneRead | CleaningZone): ILaunchPoint | undefined => {
  if (cleaningZoneRaw.starting_point?.coordinates) {
    return {
      id: 'launch_point_' + cleaningZoneRaw.id,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: cleaningZoneRaw.starting_point?.coordinates,
      },
      properties: {
        zone_id: cleaningZoneRaw.id,
        id_on_map: 'cleaning_' + cleaningZoneRaw.id,
      },
    }
  }
}

const normalizeMissionInfo = (missionRaw: MissionRead | MissionReadList): IMissionInfo => {
  return {
    id: missionRaw.id,
    name: missionRaw.name || '',
    description: missionRaw.description || '',
    createdAt: missionRaw.created_at,
    updatedAt: missionRaw.updated_at,
    status: missionRaw.status as MISSION_CALCULATING_STATUSES,
    actualCoverage: missionRaw.coverage_fact,
    plannedCoverage: missionRaw.coverage_plan,
    timeLeft: missionRaw.execution_time,
    missionType: missionRaw.mission_type,
    cleaningArea: (missionRaw as MissionRead).cleaning_area || null,
  }
}

// const normalizeGeoTiffLayer = (layerRaw: MissionXyzLayerRead | undefined): IGeoTiffLayer | undefined => {
//   if (!layerRaw) {
//     return
//   }
//   const bbox: Polygon | undefined =
//     layerRaw.bbox && layerRaw.bbox.coordinates
//       ? {
//           type: 'Polygon',
//           coordinates: layerRaw.bbox.coordinates,
//         }
//       : undefined
//   const geoTiffLayer = layerRaw
//     ? {
//         id: layerRaw.id,
//         bbox,
//         urlTiles: layerRaw.file_tiles || undefined,
//         name: layerRaw.name || '',
//         status: (layerRaw.status as GEOTIFF_LAYER_STATUSES) || GEOTIFF_LAYER_STATUSES.NEW,
//       }
//     : undefined
//   return geoTiffLayer
// }

const normalizePoint = (
  pointRaw?: { type?: 'Point'; coordinates?: Array<number> } | null,
  start: boolean = true
): IPoint | undefined => {
  if (pointRaw && pointRaw.coordinates) {
    const id = start ? 'mission_start_point' : 'mission_finish_point'
    return {
      id,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: pointRaw?.coordinates,
      },
      properties: {
        id_on_map: id,
      },
    }
  }
}

let statusIsPending = false

export const MainApi = {
  fetchMissions: async (pageSize?: number): Promise<IMissionInfo[]> => {
    const missions = await MainService.mainMissionList(undefined, undefined, pageSize)
    if (!missions.results) {
      return []
    }
    const normalizedMissions = missions.results.map(missionRaw => normalizeMissionInfo(missionRaw))
    return normalizedMissions
  },
  createMission: async (): Promise<Mission> => {
    const mission = await MainService.mainMissionCreate({
      name: 'New mission',
      description: '',
    })
    return mission
  },
  fetchMission: async (id: number): Promise<IMission> => {
    const missionRaw = await MainService.mainMissionRetrieve(id)
    const mission = normalizeMissionInfo(missionRaw)
    const status = missionRaw.status
      ? (missionRaw.status as MISSION_CALCULATING_STATUSES)
      : MISSION_CALCULATING_STATUSES.NEW
    const isReadyForCalculation = missionRaw.is_ready_to_start_calculation

    const cleaningZones = missionRaw.cleaning_zones.map(zoneRaw => normalizeCleaningZone(zoneRaw))
    const restrictZones = missionRaw.restrict_zones.map(zoneRaw => normalizeRestrictZone(zoneRaw))
    const cleaningRoutes = missionRaw.cleaning_zones
      .filter(zoneRaw => zoneRaw.cleaning_route_geometry)
      .map(zoneRaw => normalizeCleaningRoute(zoneRaw))
    const transferRoutes = missionRaw.transfer_routes.map(transferRouteRaw => normalizeTransferRoute(transferRouteRaw))
    const launchPoints = normalizeLaunchPoints(missionRaw.cleaning_zones)
    const geoTiffLayers = missionRaw.geotiff_layers
    // const geoTiffLayer = normalizeGeoTiffLayer(missionRaw.xyz_layers[0])
    const startingPoint = normalizePoint(missionRaw.starting_point, true)
    const finishingPoint = normalizePoint(missionRaw.finishing_point, false)
    const pointRoutes = missionRaw.points_transfer_routes.map(routeRaw => normalizeRoute(routeRaw))
    const visualizationSettings = { ...DEFAULT_VIZUALIZATION_SETTINGS, ...missionRaw.visualization_settings }
    return {
      status,
      mission,
      cleaningZones,
      restrictZones,
      cleaningRoutes,
      launchPoints,
      isReadyForCalculation,
      transferRoutes,
      geoTiffLayers,
      // geoTiffLayer,
      startingPoint,
      finishingPoint,
      pointRoutes,
      visualizationSettings,
    }
  },
  changeMissionName: async (id: number, name: string): Promise<Mission> => {
    const missionRaw = await MainService.mainMissionPartialUpdate(id, {
      name,
    })
    return missionRaw
  },
  changeMissionVisualSettings: async (id: number, visualSettings: IVisualizationSettings): Promise<Mission> => {
    const missionRaw = await MainService.mainMissionPartialUpdate(id, {
      visualization_settings: visualSettings,
    })
    return missionRaw
  },
  changeMissionStartPoint: async (data: PropsPoints): Promise<IPoint | undefined> => {
    const missionRaw = await MainService.mainMissionPartialUpdate(data.id, {
      starting_point: data.startingPoint,
    })
    return normalizePoint(missionRaw.starting_point)
  },
  changeMissionFinishPoint: async (data: PropsPoints): Promise<IPoint | undefined> => {
    const missionRaw = await MainService.mainMissionPartialUpdate(data.id, {
      finishing_point: data.finishingPoint,
    })
    return normalizePoint(missionRaw.finishing_point)
  },
  deleteMission: async (id: number): Promise<void> => {
    await MainService.mainMissionDestroy(id)
  },
  createRestrictZone: async (data: ZoneCreateData): Promise<IZone> => {
    const zone = await MainService.mainRestrictZoneCreate({
      name: data.name,
      description: data.description,
      mission_id: data.missionId,
      geometry: data.geometry,
    })
    return normalizeRestrictZone(zone)
  },
  deleteRestrictZone: async (id: number): Promise<void> => {
    await MainService.mainRestrictZoneDestroy(id)
  },
  patchRestrictZone: async (data: ZoneUpdateData): Promise<IZone> => {
    const { zoneId } = data
    const zone = await MainService.mainRestrictZonePartialUpdate(zoneId, {
      name: data.name,
      description: data.description,
      mission_id: data.missionId,
      geometry: data.geometry,
    })
    return normalizeRestrictZone(zone)
  },
  createCleaningZone: async (
    data: ZoneCreateData
  ): Promise<{ zone: ICleaningZone; launchPoint: ILaunchPoint | undefined }> => {
    const zone = await MainService.mainCleaningZoneCreate({
      name: data.name,
      description: data.description,
      mission_id: data.missionId,
      geometry: data.geometry,
      starting_point: data.launchPoint,
    })
    return { zone: normalizeCleaningZone(zone), launchPoint: normalizeLaunchPoint(zone) }
  },
  patchCleaningZone: async (data: ZoneUpdateData): Promise<{ zone: IZone; launchPoint: ILaunchPoint | undefined }> => {
    const { zoneId } = data
    const zone = await MainService.mainCleaningZonePartialUpdate(zoneId, {
      name: data.name,
      description: data.description,
      mission_id: data.missionId,
      geometry: data.geometry,
      starting_point: data.launchPoint,
    })
    return { zone: normalizeCleaningZone(zone), launchPoint: normalizeLaunchPoint(zone) }
  },
  fetchCleaningZone: async (zoneId: number) => {
    const zone = await MainService.mainCleaningZoneRetrieve(zoneId)
    return { zone: normalizeCleaningZone(zone), route: normalizeCleaningRoute(zone) }
  },
  deleteCleaningZone: async (id: number): Promise<void> => {
    await MainService.mainCleaningZoneDestroy(id)
  },
  startCalculatingRoutes: async (missionId: number): Promise<void> => {
    if (statusIsPending) {
      statusIsPending = false
    }
    await MainService.mainMissionStartCalculationCreate(missionId)
  },
  fetchCalculationStatus: async (missionId: number): Promise<MissionStatus | undefined> => {
    if (statusIsPending) {
      return
    }
    statusIsPending = true
    const status = await MainService.mainMissionGetStatusRetrieve(missionId)
    if (!statusIsPending) {
      return
    }
    statusIsPending = false
    return status
  },
  startCalculatingRoute: async (zoneId: number): Promise<void> => {
    if (statusIsPending) {
      statusIsPending = false
    }
    await MainService.mainCleaningZoneStartCalculationCreate(zoneId)
  },
  startCalculatingTransferRoute: async (transferRouteId: number): Promise<void> => {
    if (statusIsPending) {
      statusIsPending = false
    }
    await MainService.mainTransferRouteStartCalculationCreate(transferRouteId)
  },
  startCalculatingMissionPointRoute: async (transferRouteId: number): Promise<void> => {
    if (statusIsPending) {
      statusIsPending = false
    }
    await MainService.mainMissionPointTransferRouteStartCalculationCreate(transferRouteId)
  },
  patchTransferRoute: async (id: number, geometry?: LineString): Promise<void> => {
    await MainService.mainTransferRoutePartialUpdate(id, {
      user_geometry: geometry,
    })
  },
  deleteTransferRoute: async (id: number): Promise<void> => {
    await MainService.mainTransferRouteDestroy(id)
  },
  patchMissionPointTransferRoute: async (id: number, geometry?: LineString): Promise<void> => {
    await MainService.mainMissionPointTransferRoutePartialUpdate(id, {
      user_geometry: geometry,
    })
  },
  deleteMissionPointTransferRoute: async (id: number): Promise<void> => {
    await MainService.mainMissionPointTransferRouteDestroy(id)
  },
  createLayer: async (missionId: number, name: string): Promise<IGeoTiffLayer> => {
    const layer = await MainService.mainMissionXyzLayerCreate({ name, mission_id: missionId })
    return { id: layer.id, urlTiles: undefined, name, status: GEOTIFF_LAYER_STATUSES.NEW }
  },
  uploadLayerFile: async (layerId: number, file: File): Promise<void> => {
    await MainService.mainMissionXyzLayerUploadFileCreate(layerId, { file })
  },
  deleteLayer: async (layerId: number): Promise<void> => {
    await MainService.mainMissionXyzLayerDestroy(layerId)
  },
  fetchGeoTiffsInExtent: async (bbox: number[]): Promise<GeoTiffLayer[]> => {
    const layers = await MainService.mainGeotiffLayerList(undefined, bbox.join(','))
    return layers.results || []
  },
  saveGeoTiffsForMission: async (
    missionId: number,
    layers: MissionGeoTiffLayersRequest
  ): Promise<MissionGeoTiffLayers> => {
    return await MainService.mainMissionGeotiffLayersCreate(missionId, layers)
  },
  uploadNewGeotiff: async (missionId: number, file: File): Promise<GeoTiffLayer> => {
    return await MainService.mainMissionUploadGeotiffCreate(missionId, { file: file })
  },
  checkGeotiffStatus: async (id: number): Promise<GeoTiffLayer> => {
    return await MainService.mainGeotiffLayerRetrieve(id)
  },
  fetchObjectsOfInterest: async (robotId?: number): Promise<ObjectOfInterest[]> => {
    const res = await MainService.mainObjectsOfInterestList(undefined, undefined, undefined, undefined, robotId)
    return res.results || []
  },
  importMission: async (formData: BodyInit): Promise<MissionRead> => {
    const res = await fetch(`/api/main/mission/upload-shp/`, {
      method: 'POST',
      body: formData,
    })
    return res.json()
  },
}
