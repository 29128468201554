import { Box, Grid } from '@mui/material'
import RobotMissionMap from 'components/Robot/RobotMissionMap'
import RobotView from 'components/Robot/RobotView'

import style from './Robot.module.css'

const RobotPage = () => {
  return (
    <div className={style.container}>
      <Grid container height="100%" width="100%">
        <Grid xs={12} lg={6} item paddingY={2} height="100%">
          <RobotView />
        </Grid>
        <Grid xs={12} lg={6} item height="100%" paddingY={2}>
          <Box width="100%" height="100%" borderRadius={2} overflow="hidden">
            <RobotMissionMap />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default RobotPage
