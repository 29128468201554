import styled from '@emotion/styled'
import { DataGrid } from '@mui/x-data-grid'

export const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-footerContainer': {
    maxHeight: '52px',
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: 'none',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
    color: '#9E9E9E',
    fontVariantNumeric: 'lining-nums proportional-nums',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    border: 'none ',
    outline: 'none ',
    outlineOffset: 'none ',
    outlineWidth: 'none ',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none ',
    outlineOffset: 'none ',
    outlineWidth: 'none ',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    border: 'none ',
    outline: 'none ',
    outlineOffset: 'none',
    outlineWidth: 'none ',
  },
  '& .MuiDataGrid-columnSeparator--sideRight': {
    opacity: '0 !important',
  },
  '& .MuiDataGrid-columnHeader': {
    padding: 0,
    height: '58px',
  },
  '& .MuiDataGrid-cell': {
    padding: 0,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
    outline: 'none',
    fontVariantNumeric: 'lining-nums proportional-nums',
  },
  '& .MuiDataGrid-cell:focus ': {
    outline: 'none',
  },
  '--DataGrid-overlayHeight': '300px',
}))
