import { Box, Divider, Stack } from '@mui/material'
import { intervalToDuration } from 'date-fns'
import { useTranslation } from 'react-i18next'
import useTelemetryStore from 'store/robotTelemetry'

import RobotControlBoard from './RobotControlBoard'
import RobotDataBlock from './RobotDataBlock'
import RobotImageBlock from './RobotImageBlock'
import RobotLocks from './RobotLocks'
import RobotRouteBlock from './RobotRouteBlock'

const RobotDetails = () => {
  const { t } = useTranslation()
  const telemetry = useTelemetryStore(s => s.telemetry)

  const getTextStatus = (percents: number | undefined) => {
    if (!percents) {
      return '-'
    }
    if (percents > 60) {
      return t('robot.good')
    } else if (percents > 30) {
      return t('robot.normal')
    } else {
      return t('robot.low')
    }
  }

  const getDuration = (seconds?: number) => {
    if (!seconds) return '-'
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
    return duration.days
      ? `${duration.days}d:${duration.hours?.toString().padStart(2, '0')}:${duration.minutes
          ?.toString()
          .padStart(2, '0')}`
      : `${duration.hours?.toString().padStart(2, '0')}:${duration.minutes?.toString().padStart(2, '0')}`
  }

  const missionCompletion = telemetry?.missionProgress?.overall_mission_feedback
    ? Math.round(
        (telemetry?.missionProgress?.overall_mission_feedback?.progress /
          telemetry?.missionProgress?.overall_mission_feedback?.overall) *
          100
      )
    : '-'

  const speed = telemetry?.velocity ? Number(telemetry?.velocity?.toFixed(1)) : telemetry?.velocity === 0 ? 0 : '-'

  const timeLeft = getDuration(telemetry?.missionProgress?.overall_mission_feedback?.forecast)

  return (
    <Stack direction="column" justifyContent="space-between" spacing={1} width="100%" height="100%" overflow={'auto'}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        width="100%"
        height={{ sm: '40%' }}
        minHeight={{ sm: '220px' }}
      >
        <Box width={{ xs: '100%', sm: '40%' }}>
          <RobotImageBlock activeMode={telemetry.activeMode} />
        </Box>
        <Box width={{ xs: '100%', sm: '60%' }}>
          <RobotControlBoard />
        </Box>
      </Stack>
      <Divider />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} height={{ sm: '25%' }} minHeight={{ sm: '110px' }}>
        <Box width={{ xs: '100%', sm: '25%' }}>
          <RobotDataBlock header="Mission completion, %" value={missionCompletion} tooltip="" />
        </Box>
        <Box width={{ xs: '100%', sm: '25%' }}>
          <RobotDataBlock header="Time left" value={timeLeft} tooltip="" />
        </Box>
        <Box width={{ xs: '100%', sm: '25%' }}>
          <RobotDataBlock header="Speed, km/h" value={speed} tooltip="" />
        </Box>
        <Box width={{ xs: '100%', sm: '25%' }}>
          <RobotDataBlock
            header="Battery level, %"
            value={Math.round(telemetry?.batteryPower || 0)}
            tooltip=""
            subtitle="time left: 15m 29s"
            onSubtitleClick
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} height={{ sm: '25%' }} minHeight={{ sm: '110px' }}>
        <Box width={{ xs: '100%', sm: 'calc(50% + 8px)' }}>
          <RobotRouteBlock
            distance={telemetry?.missionProgress?.moving_task_feedback?.overall}
            passed={telemetry?.missionProgress?.moving_task_feedback?.progress}
            timeLeft={getDuration(telemetry?.missionProgress?.moving_task_feedback?.forecast)}
            possibleCov={telemetry?.missionProgress?.coverage_task_feedback?.forecast}
            cleanedPercent={telemetry?.missionProgress?.coverage_task_feedback?.progress}
          />
        </Box>
        <Box width={{ xs: '100%', sm: '25%' }}>
          <RobotDataBlock
            header="Water tank, %"
            value={Math.round(telemetry?.waterTankFilling || 0)}
            tooltip=""
            subtitle={getTextStatus(telemetry?.waterTankFilling)}
          />
        </Box>
        <Box width={{ xs: '100%', sm: '25%' }}>
          <RobotDataBlock
            header="Trash tank, %"
            value={Math.round(telemetry?.trashFullness || 0)}
            tooltip=""
            subtitle={getTextStatus(telemetry?.trashFullness)}
          />
        </Box>
      </Stack>
      <Divider />
      <Stack direction="row" spacing={1}>
        <Box width="100%">
          <RobotLocks />
        </Box>
      </Stack>
    </Stack>
  )
}

export default RobotDetails
