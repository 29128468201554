import { useQuery } from '@tanstack/react-query'
import { RobotApi } from 'api/RobotApi'
import { IUnit } from 'utils/interfaces'

const useAllUnits = () =>
  useQuery<IUnit[], Error>({
    queryKey: ['units'],
    queryFn: () => RobotApi.fetchUnits(),
    initialData: [],
    refetchOnWindowFocus: true,
  })

export default useAllUnits
