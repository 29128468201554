import { SlotTelemetry, UnitRobotTelemetry, UnitTelemetry } from 'utils/interfaces'
import { create } from 'zustand'

interface UnitTelemetryStore {
  telemetry: UnitTelemetry
  slots: SlotTelemetry[]
  robotsTelemetry: UnitRobotTelemetry[]
  setTelemetry: (data: UnitTelemetry) => void
  clearTelemetry: () => void
}

const useUnitTelemetryStore = create<UnitTelemetryStore>(set => ({
  telemetry: {},
  slots: [],
  robotsTelemetry: [],
  setTelemetry: data => {
    set({ telemetry: data, slots: data.telemetry?.slots || [], robotsTelemetry: data.robots || [] })
  },
  clearTelemetry: () => set({ telemetry: {}, slots: [], robotsTelemetry: [] }),
}))

export default useUnitTelemetryStore
