import { useState } from 'react'

import useAllUnits from './useAllUnits'

export const useFilteredUnits = () => {
  const { data: units } = useAllUnits()
  const [searchInput, setSearchInput] = useState('')

  const filteredUnits =
    searchInput === '' ? units : units.filter(unit => unit.name.toLowerCase().includes(searchInput.toLowerCase()))

  return { filteredUnits, setSearchInput, searchInput }
}
