import { useQuery } from '@tanstack/react-query'
import { RobotApi } from 'api/RobotApi'
import { useParams } from 'react-router-dom'
import { IRobotInfo } from 'utils/interfaces'

const useRobot = (id?: number) => {
  const params = useParams()
  const robotId = id || Number(params.assetId)

  return useQuery<IRobotInfo, Error>({
    queryKey: ['robot', robotId],
    queryFn: () => RobotApi.fetchRobot(robotId),
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: true,
    enabled: !!robotId,
  })
}
export default useRobot
