import { Box, Chip, Stack, Tab, Tabs, Typography } from '@mui/material'
import ReturnButton from 'components/Button/ReturnButton'
import useRobot from 'hooks/useRobot'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'

import RobotControlLocks from './RobotControlLocks'
import RobotDetails from './RobotDetails'
import RobotMissionDetails from './RobotMissionDetails'
import RobotService from './RobotService'

const RobotView = () => {
  const navigate = useNavigate()
  const { data: robot } = useRobot()
  const [activeTab, setActiveTab] = useState<'robot' | 'control' | 'mission'>('robot')

  const displayTab = {
    robot: <RobotDetails />,
    control: <RobotControlLocks />,
    mission: <RobotMissionDetails />,
    service: <RobotService />,
  }

  return (
    <Box
      height="100%"
      maxHeight="100%"
      paddingRight={{ xs: 0, md: 2 }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems=""
    >
      <Stack direction="row" alignItems="center" width="100%">
        <ReturnButton onClick={() => navigate(PATH.ROBOT_LIST)} />
        <Stack direction="column" height="48px" maxHeight="48px" justifyContent="space-between" mx={4}>
          <Typography variant="h5">{robot?.name}</Typography>
          <Typography variant="caption">ID: {robot?.unitId}</Typography>
        </Stack>
        <Chip
          label={robot?.networkStatus}
          variant="filled"
          color={robot?.networkStatus === 'online' ? 'primary' : 'warning'}
          size="small"
        />
      </Stack>
      <Box paddingY={2}>
        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
          <Tab value="robot" label="Robot details" />
          <Tab value="control" label="Control" />
          <Tab value="mission" label="Mission details" />
          <Tab value="service" label="Service" />
        </Tabs>
      </Box>
      <Box width="100%" height="100%" sx={{ overflowX: 'hidden', overflowY: { xs: 'auto', md: 'hidden' } }}>
        {displayTab[activeTab]}
      </Box>
    </Box>
  )
}

export default RobotView
