import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useMissionStore from 'store/mission'
import { TRANSFER_TYPE } from 'utils/constants'
import { ICleaningZone, IRestrictZone } from 'utils/interfaces'

import MissionPointTransferItem from './TransferItems/MissionPointTransferItem'
import StartFinishPointItem from './TransferItems/StartFinishPointItem'
import TransferRouteItem from './TransferItems/TransferRouteItem'
import CleaningZoneItem from './ZoneItem/CleaningZoneItem'
import RestrictedZoneItem from './ZoneItem/RestrictedZoneItem'

import style from './ViewZonesMode.module.css'

type ViewZonesModeProps = {
  cleaningZones: Array<ICleaningZone>
  restrictZones: Array<IRestrictZone>
  hideActions?: boolean
}

const ViewZonesMode = (props: ViewZonesModeProps) => {
  const { t } = useTranslation()
  const missionPoints = useMissionStore(s => s.missionPoints)

  const cleaningZonesList = useMemo(() => {
    return (
      <>
        {props.cleaningZones.map((zone, index) => (
          <div key={zone.properties.zone_id}>
            {index !== 0 && (
              <TransferRouteItem
                type={TRANSFER_TYPE.MIDDLE}
                zoneIdToTransfer={zone.properties.zone_id}
                hideActions={props.hideActions}
              />
            )}
            <CleaningZoneItem zone={zone} number={index + 1} hideActions={props.hideActions} />
          </div>
        ))}
      </>
    )
  }, [props.cleaningZones, props.hideActions])

  return (
    <div className={style.tableWrapper}>
      <div className={style.tableHeader}>
        <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('view_zones.zone_number')}</div>
        <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('view_zones.type')}</div>
        <div className={clsx(style.tableHeaderItem, 'text-16-400')}>{t('view_zones.name')}</div>
        <div className={clsx(style.tableHeaderItem, 'text-16-400')}>{t('view_zones.route_status')}</div>
      </div>

      {props.cleaningZones.length ||
      props.restrictZones.length ||
      missionPoints.startPoint ||
      missionPoints.finishPoint ? (
        <div className={style.table}>
          {missionPoints.startPoint && (
            <StartFinishPointItem
              type={TRANSFER_TYPE.START}
              missionPoint={missionPoints.startPoint}
              hideActions={props.hideActions}
            />
          )}
          <MissionPointTransferItem type={TRANSFER_TYPE.START} hideActions={props.hideActions} />
          {cleaningZonesList}
          <MissionPointTransferItem type={TRANSFER_TYPE.FINISH} hideActions={props.hideActions} />
          {missionPoints.finishPoint && (
            <StartFinishPointItem
              type={TRANSFER_TYPE.FINISH}
              missionPoint={missionPoints.finishPoint}
              hideActions={props.hideActions}
            />
          )}

          {props.restrictZones.map((zone, index) => (
            <RestrictedZoneItem
              key={index}
              zone={zone as IRestrictZone}
              number={index + props.cleaningZones.length + 1}
              hideActions={props.hideActions}
            />
          ))}
        </div>
      ) : (
        <div className={style.emptyWrapper}>
          <div className={style.emptyTitle}>{t('view_zones.no_zones_title')}</div>
          <div className={style.emptyDescription}>{t('view_zones.no_zones_description')}</div>
        </div>
      )}
    </div>
  )
}

export default ViewZonesMode
