import TileLayer from 'ol/layer/WebGLTile'
import GeoTIFF from 'ol/source/GeoTIFF.js'
import { useEffect } from 'react'
import useMapStore from 'store/map'

function GeoTiffsLayer() {
  const map = useMapStore(s => s.map)
  const displayGeotiffs = useMapStore(s => s.displayGeotiffs)

  useEffect(() => {
    const geoTiffLayers = map
      ?.getLayers()
      .getArray()
      .filter(layer => layer instanceof TileLayer) as TileLayer[]

    if (!geoTiffLayers) return

    const keys = geoTiffLayers.map(layer => layer.getSource()?.getKey())

    // compare keys and urls
    const urls = displayGeotiffs.map(geotiff => (geotiff.file as string).replace('https://testfms.168robotics.com', ''))

    // remove layers that are not in displayGeotiffs
    keys.forEach(key => {
      if (!urls.includes(key as string)) {
        const layer = geoTiffLayers.find(layer => layer.getSource()?.getKey() === key)
        layer && map?.removeLayer(layer)
      }
    })
    // add layers that are in displayGeotiffs but not in keys
    urls.forEach((url, i) => {
      if (!keys.includes(url)) {
        const source = new GeoTIFF({
          sources: [
            {
              url: url,
              overviews: [`${url}.ovr`],
            },
          ],
        })
        const layer = new TileLayer({
          source: source,
          opacity: displayGeotiffs[i].opacity,
        })
        map?.addLayer(layer)
      }
    })
    return () => {
      const geoTiffLayers = map
        ?.getLayers()
        .getArray()
        .filter(layer => layer instanceof TileLayer) as TileLayer[]
      geoTiffLayers.forEach(layer => map?.removeLayer(layer))
    }
  }, [displayGeotiffs, map])

  return null
}

export default GeoTiffsLayer
