// src/components/CustomControl.js
import Control from 'ol/control/Control'
import Geometry from 'ol/geom/Geometry'
import { Draw } from 'ol/interaction'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { getLength } from 'ol/sphere.js'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import Text from 'ol/style/Text'
import { useEffect, useRef, useState } from 'react'
import useMapStore from 'store/map'

import styles from './Measurment.module.css'

const Measurment = () => {
  const map = useMapStore(s => s.map)

  const controlRef = useRef<HTMLDivElement | null>(null)

  const [draw, setDraw] = useState<Draw | null>(null)
  const [isDrawing, setIsDrawing] = useState(false)
  const [layer, setLayer] = useState<VectorLayer<VectorSource> | null>(null)

  useEffect(() => {
    if (!map || !controlRef) return

    const source = new VectorSource()
    const layer = new VectorLayer({
      source,
      style: feature => {
        const geom = feature.getGeometry()
        const length = geom instanceof Geometry ? getLength(geom).toFixed(2) : ''
        return new Style({
          stroke: new Stroke({
            color: 'black',
            width: 2,
          }),
          text: new Text({
            text: length,
            font: 'bold 18px sans-serif',
            stroke: new Stroke({ color: 'white', width: 2 }),
            overflow: true,
            offsetY: -12,
          }),
        })
      },
      zIndex: 10,
    })
    map.addLayer(layer)

    const draw = new Draw({
      source,
      type: 'LineString',
    })

    setDraw(draw)

    setLayer(layer)

    // add on map
    const MeasureControl = new Control({
      element: controlRef.current as HTMLInputElement,
    })

    map.addControl(MeasureControl)

    return () => {
      map?.removeControl(MeasureControl)
      map?.removeLayer(layer)
    }
  }, [map, controlRef])

  const handleClick = () => {
    if (!map || !draw) return

    if (isDrawing) {
      map.removeInteraction(draw)
      setIsDrawing(false)
      layer?.getSource()?.clear()
    } else {
      map.addInteraction(draw)
      setIsDrawing(true)
    }
  }

  return (
    <div
      ref={controlRef}
      className="ol-unselectable ol-control ol-control-custom"
      style={{ bottom: '224px', right: '.5em' }}
    >
      <button onClick={() => handleClick()} className={styles.icon}></button>
    </div>
  )
}

export default Measurment
