import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, FormHelperText, TextField } from '@mui/material'
import { Button } from 'components/Button/Button'
import { useUser } from 'hooks/user'
import { useCallback, useMemo } from 'react'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PATH, PROGRESS_TYPES } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { emailSchema, passwordSchema } from 'utils/validationSchema'
import * as yup from 'yup'

import style from './Signin.module.css'

interface IFormInputs {
  email: string
  password: string
}

const Signin: React.FC = () => {
  const { t } = useTranslation()

  const user = useUser()

  const loginProgress = useMemo(() => user?.state.loginProgress, [user?.state.loginProgress])
  const loginProgressError = useMemo(() => user?.state.loginProgressError, [user?.state.loginProgressError])
  // const userDataProgress = useSelector(selectors.userDataProgress)
  const methods = useForm<IFormInputs>({
    resolver: yupResolver(yup.object().shape({ email: emailSchema(), password: passwordSchema() })),
  })

  // const disabledButton = useMemo(() => {
  //   return loginProgress === PROGRESS_TYPES.WORK || userDataProgress === PROGRESS_TYPES.WORK
  // }, [loginProgress, userDataProgress])

  // const isShowErrorForm = useMemo(() => {
  //   return loginProgress === PROGRESS_TYPES.ERROR || userDataProgress === PROGRESS_TYPES.ERROR
  // }, [loginProgress, userDataProgress])

  const onSubmit: SubmitHandler<IFormInputs> = useCallback(
    data => {
      user?.actions.signin(data.email, data.password)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className={style.wrapper}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <div className={style.fld}>
          <TextField
            {...methods.register('email')}
            id="email"
            name="email"
            variant="outlined"
            autoComplete="username"
            placeholder={t('signin.emailPlaceholder')}
            inputProps={{
              'data-test-id': 'email',
            }}
            aria-describedby="email-error-text"
            error={!!methods.formState.errors.email}
            fullWidth
          />
          <FormHelperText id="email-error-text">{t(methods.formState.errors.email?.message || '')}</FormHelperText>
        </div>
        <div className={style.fld}>
          <TextField
            {...methods.register('password')}
            id="password"
            name="password"
            type="password"
            variant="outlined"
            autoComplete="password"
            placeholder={t('signin.passwordPlaceholder')}
            inputProps={{
              'data-test-id': 'password',
            }}
            aria-describedby="password-error-text"
            error={!!methods.formState.errors.password}
            fullWidth
          />
          <FormHelperText id="password-error-text">
            {t(methods.formState.errors.password?.message || '')}
          </FormHelperText>
        </div>
        <Button.Standart
          dataTestId={'submit-btn'}
          disabled={false}
          loading={loginProgress === PROGRESS_TYPES.WORK}
          className={style.signinButton}
        >
          {t('signin.submitBtnText')}
        </Button.Standart>
      </form>
      <div data-test-id={'signup'} className={style.forgotPasswordLink}>
        <Link className={style.href} to={PATH.RESTORE_PASSWORD} data-test-id={getTestId('link-forgot-password')}>
          {t('signin.forgot_password')}
        </Link>
      </div>
      {loginProgressError != 0 && (
        <div className={style.forgotPasswordLink}>
          <Alert variant="outlined" severity="error">
            Ошибка: Введен неверный логин или пароль
          </Alert>
        </div>
      )}
    </div>
  )
}

export default Signin
