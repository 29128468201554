import { useQuery } from '@tanstack/react-query'
import { RobotApi } from 'api/RobotApi'
import { IRobotInfo } from 'utils/interfaces'

const useRobotsWithoutUnit = () =>
  useQuery<IRobotInfo[], Error>({
    queryKey: ['robots_without_unit'],
    queryFn: () => RobotApi.fetchRobots(undefined, true),
    refetchOnWindowFocus: true,
  })

export default useRobotsWithoutUnit
