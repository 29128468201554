import { MenuItem, SelectChangeEvent } from '@mui/material'
import SelectForm from 'components/SelectForm/SelectForm'
import { useState } from 'react'

const SelectGroupFeature = () => {
  const [stateSelect, setStateSelects] = useState({
    quantity: '',
    movementSpeed: '',
    cleaningSpeed: '',
  })

  const onChange = ({ target }: SelectChangeEvent) => {
    const { name, value } = target
    setStateSelects(state => ({ ...state, [name]: value }))
  }
  return (
    <>
      <SelectForm label={'Quantity of robots'} value={stateSelect.quantity} onChange={onChange} name="quantity">
        <MenuItem value={'10'}>Ten</MenuItem>
        <MenuItem value={'20'}>Twenty</MenuItem>
        <MenuItem value={'30'}>Thirty</MenuItem>
      </SelectForm>
      <SelectForm label={'Movement speed'} value={stateSelect.movementSpeed} onChange={onChange} name="movementSpeed">
        <MenuItem value={'10'}>Ten</MenuItem>
        <MenuItem value={'20'}>Twenty</MenuItem>
        <MenuItem value={'30'}>Thirty</MenuItem>
      </SelectForm>
      <SelectForm label={'Cleaning speed'} value={stateSelect.cleaningSpeed} onChange={onChange} name="cleaningSpeed">
        <MenuItem value={'10'}>Ten</MenuItem>
        <MenuItem value={'20'}>Twenty</MenuItem>
        <MenuItem value={'30'}>Thirty</MenuItem>
      </SelectForm>
    </>
  )
}
export default SelectGroupFeature
