import { Box, InputAdornment, TextField } from '@mui/material'
import { ReactComponent as SearchIcon } from 'images/icons/search.svg'

import style from './Search.module.css'

interface SearchProps {
  onSearch: (event: string) => void
  searchInput: string
}

const Search: React.FC<SearchProps> = ({ onSearch, searchInput }) => {
  return (
    <Box className={style.searchInput}>
      <TextField
        value={searchInput}
        onChange={e => onSearch(e.target.value)}
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          marginLeft: '0px',
          width: '85%',
          height: '100%',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            width: '100%',
            display: 'flex',
            height: '48px',
          },
          '& .MuiInputBase-root': {
            height: '48px',
            fontSize: '16px',
          },
        }}
      />
    </Box>
  )
}

export default Search
