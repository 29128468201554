import { Box, Grid, Stack } from '@mui/material'
import Filter from 'components/Filter/Filter'
import ListHeader from 'components/ListHeader/ListHeader'
import Loader from 'components/Loader/Loader'
import AllCleaningZonesLayer from 'components/Map/AllCleaningZonesLayer'
import BaseMap from 'components/Map/BaseMap'
import Layers from 'components/Map/Layers'
import MapContainer from 'components/Map/MapContainer'
import ObjectsOfInterestLayer from 'components/Map/ObjectsOfInterestLayer'
import RobotsLayer from 'components/Map/RobotsLayer'
import UnitsLayer from 'components/Map/UnitsLayer'
import Widgets from 'components/Map/Widgets'
import LayerList from 'components/Map/widgets/LayerList'
import RobotList from 'components/RobotList/RobotList/RobotList'
import RobotListHeader from 'components/RobotList/RobotListHeader/RobotListHeader'
import Search from 'components/Serch/Search'
import useAllUnits from 'hooks/useAllUnits'
import { useFilteredRobots } from 'hooks/useFilteredRobots'
import useObjectsOfInterest from 'hooks/useObjectsOfInterest'
import { t } from 'i18next'

import style from './RobotListPage.module.css'

const RobotListPage = () => {
  const { data: units, refetch: refetchUnits } = useAllUnits()
  const { data: objectsOfInterest = [] } = useObjectsOfInterest()
  const { filteredRobots, setSearchInput, searchInput, error, refetch, isFetching } = useFilteredRobots()

  return (
    <div className={style.container}>
      <Stack direction={{ xs: 'column', sm: 'row' }} width="100%" spacing={1} marginTop={{ xs: 1, sm: 2, lg: 3 }}>
        <Search onSearch={value => setSearchInput(value)} searchInput={searchInput} />
        <Filter />
      </Stack>
      <Grid container height="100%" width="100%">
        <Grid xs={12} md={6} item paddingY={{ xs: 0, sm: 0, lg: 2 }} paddingRight={2} height="100%">
          <ListHeader titleHeader={t('robots.title')} />
          <RobotListHeader
            robots={filteredRobots}
            units={units}
            onRobotsUpdated={refetch}
            onUnitsChanged={refetchUnits}
          />
          {isFetching ? <Loader /> : <RobotList robots={filteredRobots} error={error} />}
        </Grid>
        <Grid
          xs={12}
          md={6}
          item
          height="100%"
          paddingY={{ xs: 0, sm: 0, lg: 2 }}
          paddingLeft={{ xs: 0, sm: 0, lg: 1 }}
          paddingBottom={{ xs: 2, sm: 2, lg: 2 }}
        >
          <Box width="100%" height="100%" borderRadius={2} overflow="hidden">
            <MapContainer>
              <Layers>
                <BaseMap />
                <RobotsLayer robots={filteredRobots} />
                <UnitsLayer units={units} />
                <ObjectsOfInterestLayer objectsOfInterest={objectsOfInterest} />
                <AllCleaningZonesLayer />
              </Layers>
              <Widgets>
                <LayerList />
              </Widgets>
            </MapContainer>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default RobotListPage
