import { useQuery } from '@tanstack/react-query'
import { RobotApi } from 'api/RobotApi'
import { IRobotInfo } from 'utils/interfaces'

const useAllRobots = () =>
  useQuery<IRobotInfo[], Error>({
    queryKey: ['robots'],
    queryFn: () => RobotApi.fetchRobots(),
    // initialData: [],
    refetchOnWindowFocus: true,
  })

export default useAllRobots
