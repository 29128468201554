import clsx from 'clsx'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import { intervalToDuration } from 'date-fns'
import useDeleteCleaningZone from 'hooks/mission/useDeleteCleaningZone'
import useZoomToPolygon from 'hooks/useZoomToPolygon'
import { ReactComponent as CalculateRouteSvg } from 'images/icons/calculate_route.svg'
import { ReactComponent as ZoneSvg } from 'images/icons/cleaning_zone_small.svg'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'
import { ReactComponent as ZoneRouteSvg } from 'images/icons/zone_route.svg'
import Feature from 'ol/Feature'
import { Polygon } from 'ol/geom'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useMissionStore from 'store/mission'
import { MISSION_CALCULATING_STATUSES, MISSION_ZONE_TYPES } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { ICleaningZone } from 'utils/interfaces'
import { useShallow } from 'zustand/react/shallow'

import style from './ZoneItem.module.css'

const CleaningZoneItem = ({
  zone,
  number,
  hideActions,
}: {
  zone: ICleaningZone
  number: number
  hideActions?: boolean
}) => {
  const { t } = useTranslation()
  const { zoomToPolygon } = useZoomToPolygon()

  const { highlightedFeatureInList, deleteCleaningZone, startCalculatingRoute, setChangeZone } = useMissionStore(
    useShallow(state => ({
      highlightedFeatureInList: state.highlightedFeatureInList,
      deleteCleaningZone: state.deleteCleaningZone,
      startCalculatingRoute: state.startCalculatingRoute,
      setChangeZone: state.setChangeZone,
    }))
  )

  const { mutateAsync: setDeleteCleaningZone } = useDeleteCleaningZone()

  const highlighted = useMemo(() => {
    return highlightedFeatureInList === zone.id
  }, [highlightedFeatureInList, zone.id])

  const onZoneClicked = () => {
    const feature = new Feature({
      // @ts-ignore
      geometry: new Polygon(zone.geometry.coordinates!).transform('EPSG:4326', 'EPSG:3857'),
    })

    zoomToPolygon(feature.getGeometry() as Polygon)
  }

  const onEditZoneClicked = () => {
    setChangeZone(zone)
  }

  const onRemoveZoneClicked = () => {
    if (zone.properties?.zone_id && zone.properties?.zone_type) {
      setDeleteCleaningZone(zone.properties?.zone_id as number)
        .then(() => {
          deleteCleaningZone(zone)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('Error deleting zone', error)
        })
    }
  }

  const onStartCalculatingClicked = () => {
    startCalculatingRoute(zone.properties?.zone_id as number)
  }

  const formatDuration = (seconds: number) => {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
    return duration.days
      ? `${duration.days}d:${duration.hours}h:${duration.minutes}m`
      : `${duration.hours}h:${duration.minutes}m`
  }

  return (
    <div className={highlighted ? clsx(style.zoneItem, style.highlighted) : style.zoneItem} onClick={onZoneClicked}>
      <div className={style.zoneItemColSmall}>#{number}</div>
      <div className={style.zoneItemColSmall}>
        <ZoneSvg className={style.icon} />
      </div>
      <div className={style.zoneItemCol}>{zone.properties?.name}</div>
      <div className={style.zoneItemCol}>
        <ZoneRouteSvg />
        <div className={style.marginLeft}>
          {zone.properties?.status === MISSION_CALCULATING_STATUSES.OK && (
            <>
              <div>Auto cleaning route</div>
              <div className={style.zoneAdditionalInfo}>
                {zone?.properties?.area && (
                  <>
                    <span>Area: </span>
                    <span className={style.bold}>
                      {Math.round(zone?.properties?.area || 0)}m<sup>2</sup>
                    </span>
                  </>
                )}
                {zone.properties?.length && (
                  <span className={style.marginLeftSmall}>
                    <span>Length: </span>
                    <span className={style.bold}>{Math.round(zone.properties?.length)}m</span>
                  </span>
                )}
                {zone.properties?.executionTime && (
                  <span className={style.marginLeftSmall}>
                    <span>Exp: </span>
                    <span className={style.bold}>{formatDuration(zone.properties?.executionTime)}</span>
                  </span>
                )}
              </div>
            </>
          )}
          {zone.properties?.status === MISSION_CALCULATING_STATUSES.CALCULATING &&
            t('zone_details.cleaning_zone_calculating')}
          {zone.properties?.status === MISSION_CALCULATING_STATUSES.NEW && t('mission_status.new')}
          {zone.properties?.status === MISSION_CALCULATING_STATUSES.ERROR && t('mission_status.error')}
          {zone.properties?.status === MISSION_CALCULATING_STATUSES.PARTIAL_CALCULATED &&
            t('mission_status.partial_calculated')}
        </div>
      </div>
      <div className={style.zoneItemColSmall}>
        {!hideActions && (
          <ActionsMenu>
            {zone.properties?.zone_type === MISSION_ZONE_TYPES.CLEANING && (
              <ActionsMenuItem
                onClick={onStartCalculatingClicked}
                data-test-id={getTestId('calc-zone-list')}
                disabled={!(zone as ICleaningZone).properties?.readyForCalculation}
              >
                <CalculateRouteSvg className={style.menuItemIcon} />
                <span data-test-id={getTestId('calc-title')}>{t('mission.calculate_route')}</span>
              </ActionsMenuItem>
            )}
            <ActionsMenuItem onClick={onEditZoneClicked} data-test-id={getTestId('edit-zone-list')}>
              <span className={style.menuItemIcon} />
              <span data-test-id={getTestId('edit-title')}>{t('common.edit')}</span>
            </ActionsMenuItem>
            <ActionsMenuItem onClick={onRemoveZoneClicked} data-test-id={getTestId('remove-zone-list')}>
              <RemoveSvg className={style.menuItemIcon} />
              <span data-test-id={getTestId('delete-title')}>{t('common.delete')}</span>
            </ActionsMenuItem>
          </ActionsMenu>
        )}
      </div>
    </div>
  )
}

export default CleaningZoneItem
