import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Loader from 'components/Loader/Loader'
import Search from 'components/Serch/Search'
import useRobotsWithoutUnit from 'hooks/useRobotsWithoutUnit'
import useUpdateUnitRobots from 'hooks/useUpdateUnitRobots'
import { ReactComponent as CancelSvg } from 'images/icons/cancel.svg'
import { ReactComponent as SaveSvg } from 'images/icons/save.svg'
import { useState } from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { MdOutlineClose } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { ROBOT_MODES, robotModes } from 'utils/constants'
import { IRobotInfo, UnitRobotTelemetry } from 'utils/interfaces'

interface Props {
  show: boolean
  unitRobots: UnitRobotTelemetry[]
  onClose: () => void
}

const UnitRobotModal = ({ show, onClose, unitRobots }: Props) => {
  const params = useParams()

  const { data: availableRobots = [], isLoading, isRefetching } = useRobotsWithoutUnit()
  const { mutateAsync: updateRobots, isPending, error } = useUpdateUnitRobots()

  const [unitRobotsIds, setUnitRobotsIds] = useState<number[]>(unitRobots.map(r => r.id))
  const [search, setSearch] = useState<string>('')

  const isRobotInUnit = (id: number) => unitRobotsIds.includes(id)

  const handleRobotClick = (id: number) => {
    isRobotInUnit(id) ? setUnitRobotsIds(unitRobotsIds.filter(r => r !== id)) : setUnitRobotsIds([...unitRobotsIds, id])
  }

  const handleSaveClick = () => {
    updateRobots({ id: Number(params.unitId), robots: unitRobotsIds }).then(() => onClose())
  }

  return (
    <Modal open={show} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '60%',
          maxHeight: '80%',
          minHeight: '50%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '24px',
          p: 2,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update robot list
          </Typography>
          <IconButton onClick={onClose} aria-label="close">
            <MdOutlineClose />
          </IconButton>
        </Stack>
        <Box sx={{ flex: 1, overflow: 'scroll' }}>
          <Search onSearch={v => setSearch(v)} searchInput={search} />
          {isLoading || isRefetching ? (
            <Loader />
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="30px" align="center">
                    Add
                  </TableCell>
                  <TableCell width="30px">ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Control mode</TableCell>
                  <TableCell>Battery level, %</TableCell>
                  <TableCell>Mission</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...unitRobots, ...availableRobots]
                  .filter(r => r.name.toLowerCase().includes(search.toLowerCase()))
                  .map(robot => (
                    <TableRow key={robot.id}>
                      <TableCell>
                        <IconButton onClick={() => handleRobotClick(robot.id)}>
                          <AiOutlineCheckCircle size={25} color={isRobotInUnit(robot.id) ? '#41C04E' : '#000000'} />
                        </IconButton>
                      </TableCell>
                      <TableCell>{robot.id}</TableCell>
                      <TableCell>{robot.name}</TableCell>
                      <TableCell>{robotModes[robot.activeMode as ROBOT_MODES]}</TableCell>
                      <TableCell>{robot.batteryPower}</TableCell>
                      <TableCell>
                        {(robot as IRobotInfo).job?.missionName || (robot as UnitRobotTelemetry).missionName}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          {error && <Alert severity="error">{error.message}</Alert>}
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          mt={2}
          gap={2}
          sx={{ position: 'sticky', bottom: 0, zIndex: 1, bgcolor: 'background.paper' }}
        >
          <Button onClick={() => onClose()} color="warning" variant="contained" fullWidth endIcon={<CancelSvg />}>
            Cancel
          </Button>
          <Button
            onClick={() => handleSaveClick()}
            disabled={isPending}
            color="primary"
            variant="contained"
            fullWidth
            endIcon={isPending ? <CircularProgress size={16} color="secondary" /> : <SaveSvg />}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default UnitRobotModal
