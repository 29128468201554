import { useMutation } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { MissionRead } from 'api/schema'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'

const useImportMission = () => {
  const navigate = useNavigate()
  return useMutation<MissionRead, Error, BodyInit>({
    // mutationFn: MainService.mainMissionUploadShpCreate,
    mutationFn: formData => MainApi.importMission(formData),
    onSuccess: newMission => navigate(`${PATH.MISSION}${newMission.id}`),
  })
}

export default useImportMission
