import { useState } from 'react'

const useDownloadMission = (missionId: number, missionName: string) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const getMission = async () => {
    setIsLoading(true)
    try {
      const response = await fetch(`/api/main/mission/${missionId}/download-shp/`)
      const blob = await response.blob()
      const newBlob = new Blob([blob], { type: 'application/zip' })
      const url = window.URL.createObjectURL(newBlob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${missionName}.zip`)
      link.click()
      window.URL.revokeObjectURL(url)
      setError(null)
    } catch (error) {
      setError((error as Error).message)
    } finally {
      setIsLoading(false)
    }
  }

  return { getMission, isLoading, error }
}
export default useDownloadMission
