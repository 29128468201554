import { Button, Stack, Typography } from '@mui/material'
import { AiFillLock, AiOutlineLock } from 'react-icons/ai'
import useTelemetryStore from 'store/robotTelemetry'

const RobotLocks = () => {
  const telemetry = useTelemetryStore(s => s.telemetry)

  return (
    <Stack
      direction="row"
      bgcolor="info.main"
      borderRadius={2}
      gap={2}
      flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      marginBottom={0}
      justifyContent="space-between"
      padding={2}
    >
      <Typography variant="caption" color="secondary.main" display="flex" alignItems="center" marginRight={1}>
        Locks
      </Typography>
      <Button
        variant={telemetry?.locks?.front ? 'contained' : 'outlined'}
        color="secondary"
        startIcon={telemetry?.locks?.front ? <AiFillLock /> : <AiOutlineLock />}
        fullWidth
      >
        Front
      </Button>
      <Button
        variant={telemetry?.locks?.charge ? 'contained' : 'outlined'}
        color="secondary"
        startIcon={telemetry?.locks?.charge ? <AiFillLock /> : <AiOutlineLock />}
        fullWidth
      >
        Charge
      </Button>
      <Button
        variant={telemetry?.locks?.body ? 'contained' : 'outlined'}
        color="secondary"
        startIcon={telemetry?.locks?.body ? <AiFillLock /> : <AiOutlineLock />}
        fullWidth
      >
        Body
      </Button>
      <Button
        variant={telemetry?.locks?.trash ? 'contained' : 'outlined'}
        color="secondary"
        startIcon={telemetry?.locks?.trash ? <AiFillLock /> : <AiOutlineLock />}
        fullWidth
      >
        Trash
      </Button>
    </Stack>
  )
}

export default RobotLocks
