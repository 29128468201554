import { robotModes } from '../utils/constants'
import { Point } from 'geojson'
import {
  MISSION_JOB_STATUSES,
  ROBOT_COMMANDS,
  ROBOT_EQUIPMENT_COMMAND,
  ROBOT_MODES,
  ROBOT_NETWORK_STATUSES,
  ROBOT_STATUSES,
} from 'utils/constants'
import { IRobotInfo, ITelemetryRoute, IUnit } from 'utils/interfaces'

import { MainService, MissionHistory, MissionJob, PaginatedMaintenanceList, RobotList, RobotRead } from './schema'

const normalizeRobot = (robotRaw: RobotRead | RobotList): IRobotInfo => {
  return {
    id: robotRaw.id,
    serialNumber: robotRaw.serial_number,
    name: robotRaw.name || '',
    description: robotRaw.description || '',
    createdAt: robotRaw.created_at,
    updatedAt: robotRaw.updated_at,
    status: ROBOT_STATUSES.AVAILABLE,
    robotRaw,
    activeMode: robotRaw.active_mode as ROBOT_MODES,
    activeModeText: robotModes[robotRaw.active_mode as ROBOT_MODES],
    job: robotRaw.missions_jobs[0] && {
      id: robotRaw.missions_jobs[0].id,
      missionName: robotRaw.missions_jobs[0].mission_name,
      missionId: robotRaw.missions_jobs[0].mission_id,
      status: robotRaw.missions_jobs[0].status as MISSION_JOB_STATUSES,
      progress: robotRaw.missions_jobs[0].mission_status_last?.mission_progress?.overall_mission_feedback?.progress,
      overall: robotRaw.missions_jobs[0].mission_status_last?.mission_progress?.overall_mission_feedback?.overall,
    },
    telemetryRoute: ((robotRaw as RobotRead).telemetry_route as ITelemetryRoute[]) || undefined,
    batteryPower: robotRaw.telemetry_battery_power,
    networkStatus: (robotRaw.network_status as ROBOT_NETWORK_STATUSES) || ROBOT_NETWORK_STATUSES.OFFLINE,
    unitId: robotRaw.unit_id || undefined,
    unitName: robotRaw.unit?.name || undefined,
    speed: robotRaw.telemetry_speed,
    lastPosition: robotRaw.telemetry_last_point,
    lastOrientation: robotRaw.telemetry_last_orientation as { x: number; y: number; z: number; w: number },
    lastOrientationDegrees: robotRaw.telemetry_last_orientation_degrees || undefined,
    maxCoverageArea: robotRaw.max_coverage_area || undefined,
  }
}

export const RobotApi = {
  fetchRobots: async (idUnit?: number, noUnit?: boolean): Promise<IRobotInfo[]> => {
    const robots = await MainService.mainRobotList(noUnit, undefined, undefined, undefined, undefined, idUnit)
    if (!robots.results) {
      return []
    }
    const normalizedRobots = robots.results.map(robotRaw => normalizeRobot(robotRaw))
    return normalizedRobots
  },
  createRobot: async (
    name: string,
    description: string,
    serialNumber: number,
    unitId?: number | null
  ): Promise<boolean> => {
    const robot = await MainService.mainRobotCreate({
      name,
      description,
      users_ids: [],
      serial_number: serialNumber,
      unit_id: unitId,
    })
    if (robot) {
      return true
    }
    return false
  },
  patchRobot: async (id: number, name: string, description: string, unitId?: number | null): Promise<boolean> => {
    const robot = await MainService.mainRobotPartialUpdate(id, {
      name,
      description,
      unit_id: unitId,
    })
    if (robot) {
      return true
    }
    return false
  },
  fetchRobot: async (id: number): Promise<IRobotInfo> => {
    const robotRaw = await MainService.mainRobotRetrieve(id)
    const robot = normalizeRobot(robotRaw)
    return robot
  },
  deleteRobot: async (id: number): Promise<void> => {
    await MainService.mainRobotDestroy(id)
  },
  createJob: async (param: { missionId: number; robotId: number }): Promise<MissionJob> => {
    const mission = await MainService.mainMissionJobCreate({
      mission_id: param.missionId,
      robot_id: param.robotId,
    })
    return mission
  },
  uploadJobToRobot: async (jobId: number) => {
    await MainService.mainMissionJobStartCalculationCreate(jobId)
  },
  switchMode: async (params: { id: number; mode: ROBOT_MODES }) => {
    await MainService.mainRobotSwitchModeCreate(params.id, String(params.mode))
  },
  execCommand: async (params: { id: number; command: ROBOT_COMMANDS }) => {
    await MainService.mainRobotExecCommandCreate(String(params.command), params.id)
  },
  fetchUnits: async (): Promise<IUnit[]> => {
    const unitsRaw = await MainService.mainUnitList()
    if (!unitsRaw.results) {
      return []
    }
    const units = unitsRaw.results.map(unitRaw => {
      return {
        id: unitRaw.id,
        name: unitRaw.name || 'Unnamed unit',
        point: unitRaw.point,
        status: unitRaw.status,
        slotsStatuses: unitRaw.slots_statuses,
        missionName: unitRaw.mission_name,
        robotsCount: unitRaw.robots_count,
        missionCompletion: unitRaw.mission_complete,
      }
    })
    return units as IUnit[]
  },
  createUnit: async (name: string, point: Point): Promise<IUnit> => {
    const unitRaw = await MainService.mainUnitCreate({
      name,
      point,
    })
    return {
      id: unitRaw.id,
      name: unitRaw.name || 'Unnamed unit',
      point: unitRaw.point
        ? {
            type: 'Point',
            coordinates: unitRaw.point.coordinates || [],
          }
        : undefined,
    }
  },
  patchUnit: async (id: number, name: string, point: Point): Promise<IUnit> => {
    const unitRaw = await MainService.mainUnitPartialUpdate(id, {
      name,
      point,
    })
    return {
      id: unitRaw.id,
      name: unitRaw.name || 'Unnamed unit',
      point: unitRaw.point
        ? {
            type: 'Point',
            coordinates: unitRaw.point.coordinates || [],
          }
        : undefined,
    }
  },
  deleteUnit: async (id: number): Promise<void> => {
    await MainService.mainUnitDestroy(id)
  },
  execEquipmentCommand: async (data: {
    id: number
    command: ROBOT_EQUIPMENT_COMMAND
    value: string
  }): Promise<void> => {
    const { command, id, value } = data
    return await MainService.mainRobotExecEquipmentCommandCreate(command, id, value)
  },
  fetchRobotLastMissions: async (id: number): Promise<MissionHistory[]> => {
    const response = await MainService.mainMissionHistoryList(undefined, undefined, undefined, undefined, id)
    return response.results || []
  },
  fetchMaintenanceList: async (id: number | undefined): Promise<PaginatedMaintenanceList> => {
    const response = await MainService.mainMaintenanceList(undefined, undefined, undefined, id, undefined)
    return response || []
  },
}
