import { useMutation } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { ILaunchPoint, IZone, ZoneUpdateData } from 'utils/interfaces'

interface ReceiveData {
  zone: IZone
  launchPoint: ILaunchPoint | undefined
}

const useEditCleaningZone = () =>
  useMutation<ReceiveData, Error, ZoneUpdateData>({
    mutationFn: MainApi.patchCleaningZone,
  })

export default useEditCleaningZone
