import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ColorPicker from 'components/ColorPicker/ColorPicker'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import { useTranslation } from 'react-i18next'
import useMissionStore from 'store/mission'
import { useShallow } from 'zustand/react/shallow'

import style from './VisualSettings.module.css'

const VisualSettings = () => {
  const { t } = useTranslation()
  const { setTempVisualSettings, tempVisualSettings } = useMissionStore(
    useShallow(state => ({
      setTempVisualSettings: state.setTempVisualSettings,
      tempVisualSettings: state.tempVisualSettings,
    }))
  )

  const checkNumberValue = (value: any, divisor?: number) => {
    const number = value == '' ? (divisor != null ? 100 : 0) : value
    return divisor != null ? parseFloat(number) / divisor : parseFloat(number)
  }
  return (
    <Accordion>
      <AccordionSummary>{t('visual_settings.title')}</AccordionSummary>
      <AccordionDetails>
        <div className={style.visualSettingsRow}>
          <TextFieldDescribed
            className={style.textfieldColor}
            description="Clean fill"
            placeholder=""
            value={tempVisualSettings.clean_fill_color}
            disabled={false}
            onChange={e => setTempVisualSettings({ ...tempVisualSettings, clean_fill_color: e.target.value })}
          >
            <ColorPicker
              color={tempVisualSettings.clean_fill_color}
              onChange={colorHex => {
                setTempVisualSettings({ ...tempVisualSettings, clean_fill_color: colorHex })
              }}
            />
          </TextFieldDescribed>
          <TextFieldDescribed
            className={style.textfieldColor}
            description="Clean border"
            placeholder=""
            value={tempVisualSettings.clean_border_color}
            disabled={false}
            onChange={e => setTempVisualSettings({ ...tempVisualSettings, clean_border_color: e.target.value })}
          >
            <ColorPicker
              color={tempVisualSettings.clean_border_color}
              onChange={colorHex => {
                setTempVisualSettings({ ...tempVisualSettings, clean_border_color: colorHex })
              }}
            />
          </TextFieldDescribed>
          <TextFieldDescribed
            className={style.textfieldNumber}
            description="Clean width"
            placeholder=""
            value={tempVisualSettings.clean_width}
            disabled={false}
            onChange={e => {
              setTempVisualSettings({ ...tempVisualSettings, clean_width: checkNumberValue(e.target.value) })
            }}
          >
            px
          </TextFieldDescribed>
          <TextFieldDescribed
            className={style.textfieldNumber}
            description="Clean opacity"
            placeholder=""
            value={Math.trunc(tempVisualSettings.clean_opacity * 100)}
            disabled={false}
            onChange={e =>
              setTempVisualSettings({ ...tempVisualSettings, clean_opacity: checkNumberValue(e.target.value, 100) })
            }
          >
            %
          </TextFieldDescribed>
        </div>
        <div className={style.visualSettingsRow}>
          <TextFieldDescribed
            className={style.textfieldColor}
            description="Restrict fill"
            placeholder=""
            value={tempVisualSettings.restrict_fill_color}
            disabled={false}
            onChange={e => setTempVisualSettings({ ...tempVisualSettings, restrict_fill_color: e.target.value })}
          >
            <ColorPicker
              color={tempVisualSettings.restrict_fill_color}
              onChange={colorHex => {
                setTempVisualSettings({ ...tempVisualSettings, restrict_fill_color: colorHex })
              }}
            />
          </TextFieldDescribed>
          <TextFieldDescribed
            className={style.textfieldColor}
            description="Restrict border"
            placeholder=""
            value={tempVisualSettings.restrict_border_color}
            disabled={false}
            onChange={e => setTempVisualSettings({ ...tempVisualSettings, restrict_border_color: e.target.value })}
          >
            <ColorPicker
              color={tempVisualSettings.restrict_border_color}
              onChange={colorHex => {
                setTempVisualSettings({ ...tempVisualSettings, restrict_border_color: colorHex })
              }}
            />
          </TextFieldDescribed>
          <TextFieldDescribed
            className={style.textfieldNumber}
            description="Restrict width"
            placeholder=""
            value={tempVisualSettings.restrict_width}
            disabled={false}
            onChange={e =>
              setTempVisualSettings({ ...tempVisualSettings, restrict_width: checkNumberValue(e.target.value) })
            }
          >
            px
          </TextFieldDescribed>
          <TextFieldDescribed
            className={style.textfieldNumber}
            description="Restrict opacity"
            placeholder=""
            value={Math.trunc(tempVisualSettings.restrict_opacity * 100)}
            disabled={false}
            onChange={e =>
              setTempVisualSettings({ ...tempVisualSettings, restrict_opacity: checkNumberValue(e.target.value, 100) })
            }
          >
            %
          </TextFieldDescribed>
        </div>
        <div className={style.visualSettingsRow}>
          <TextFieldDescribed
            className={style.textfieldColor}
            description="Cleaning route color"
            placeholder=""
            value={tempVisualSettings.cleaning_route_color}
            disabled={false}
            onChange={e => setTempVisualSettings({ ...tempVisualSettings, cleaning_route_color: e.target.value })}
          >
            <ColorPicker
              color={tempVisualSettings.cleaning_route_color}
              onChange={colorHex => {
                setTempVisualSettings({ ...tempVisualSettings, cleaning_route_color: colorHex })
              }}
            />
          </TextFieldDescribed>
          <TextFieldDescribed
            className={style.textfieldNumber}
            description="Cleaning route width"
            placeholder=""
            value={tempVisualSettings.cleaning_route_width}
            disabled={false}
            onChange={e =>
              setTempVisualSettings({ ...tempVisualSettings, cleaning_route_width: checkNumberValue(e.target.value) })
            }
          >
            px
          </TextFieldDescribed>
          <TextFieldDescribed
            className={style.textfieldNumber}
            description="Cleaning route opacity"
            placeholder=""
            value={Math.trunc(tempVisualSettings.cleaning_route_opacity * 100)}
            disabled={false}
            onChange={e =>
              setTempVisualSettings({
                ...tempVisualSettings,
                cleaning_route_opacity: checkNumberValue(e.target.value, 100),
              })
            }
          >
            %
          </TextFieldDescribed>
        </div>
        <div className={style.visualSettingsRow}>
          <TextFieldDescribed
            className={style.textfieldColor}
            description="Transfer route color"
            placeholder=""
            value={tempVisualSettings.transfer_route_color}
            disabled={false}
            onChange={e => setTempVisualSettings({ ...tempVisualSettings, transfer_route_color: e.target.value })}
          >
            <ColorPicker
              color={tempVisualSettings.transfer_route_color}
              onChange={colorHex => {
                setTempVisualSettings({ ...tempVisualSettings, transfer_route_color: colorHex })
              }}
            />
          </TextFieldDescribed>
          <TextFieldDescribed
            className={style.textfieldNumber}
            description="Transfer route width"
            placeholder=""
            value={tempVisualSettings.transfer_route_width}
            disabled={false}
            onChange={e =>
              setTempVisualSettings({ ...tempVisualSettings, transfer_route_width: checkNumberValue(e.target.value) })
            }
          >
            px
          </TextFieldDescribed>
          <TextFieldDescribed
            className={style.textfieldNumber}
            description="Transfer route opacity"
            placeholder=""
            value={Math.trunc(tempVisualSettings.transfer_route_opacity * 100)}
            disabled={false}
            onChange={e =>
              setTempVisualSettings({
                ...tempVisualSettings,
                transfer_route_opacity: checkNumberValue(e.target.value, 100),
              })
            }
          >
            %
          </TextFieldDescribed>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default VisualSettings
