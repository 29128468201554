import { useQuery } from '@tanstack/react-query'
import { RobotApi } from 'api/RobotApi'
import { MissionHistory } from 'api/schema'
import { useParams } from 'react-router-dom'

const useRobotLastMissions = (id?: number) => {
  const params = useParams()
  const robotId = id || Number(params.assetId)

  return useQuery<MissionHistory[], Error>({
    queryKey: ['robotLastMissions', robotId],
    queryFn: () => RobotApi.fetchRobotLastMissions(robotId),
    staleTime: 2000 * 60, // 2 minute
    refetchOnWindowFocus: true,
    enabled: !!robotId,
  })
}
export default useRobotLastMissions
