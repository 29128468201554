import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material'
import Title from 'components/Title/Title'
import { useState } from 'react'
import { BootstrapInput } from 'utils/themeUnit'

import TableScenariosList from './TableScenarios/TableScenariosList'

import style from './ScenariosUnitList.module.css'

const ScenariosUnitList = () => {
  const [value, setValue] = useState('')

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string)
  }
  const showTable = false
  return (
    <>
      <Box bgcolor="info.main" sx={{ height: '100%', borderRadius: '16px' }}>
        <Box sx={{ display: 'flex' }}>
          {showTable ? (
            <Stack direction="row" justifyContent={'space-between'} paddingX={6} paddingY={2} width={'100%'}>
              <Title text={'Time control for robots'} className={style.titleFontSize} />
              <FormControl sx={{ width: '10%', minWidth: '100px' }}>
                <InputLabel sx={{ fontSize: '14px', fontWeight: '500', height: '40px', alignItems: 'top' }}>
                  Details
                </InputLabel>
                <Select
                  value={value}
                  label="Select"
                  onChange={handleChange}
                  input={<BootstrapInput />}
                  sx={{ borderRadius: '8px', height: '50px' }}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          ) : null}
        </Box>
        <Box sx={{ width: '100%', position: 'relative', textAlign: 'center', height: '70%' }}>
          <TableScenariosList showTable={showTable} />
        </Box>
      </Box>
    </>
  )
}

export default ScenariosUnitList
