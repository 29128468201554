import { MainApi } from 'api/MainApi'
import { CleaningZoneStatus, GeoTiffLayer, MissionXyzLayerStatus } from 'api/schema'
import { Feature, GeoJsonProperties, Geometry } from 'geojson'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import {
  DEFAULT_VIZUALIZATION_SETTINGS,
  GEOTIFF_LAYER_STATUSES,
  MISSION_CALCULATING_STATUSES,
  MISSION_MAP_DRAWING_MODES,
  MISSION_PAGE_MODES,
  MISSION_ZONE_TYPES,
  PROGRESS_TYPES,
  TRANSFER_CALCULATING_STATUSES,
  TRANSFER_TYPE,
} from 'utils/constants'
import {
  ICleaningZone,
  IFeature,
  IGeoTiffLayer,
  ILaunchPoint,
  IMission,
  IMissionInfo,
  IPoint,
  IRoute,
  ITransferRoute,
  IVisualizationSettings,
  IZone,
  IZoneTransferRoute,
} from 'utils/interfaces'
import create from 'zustand'

interface State {
  mode: MISSION_PAGE_MODES
  mapMode: MISSION_MAP_DRAWING_MODES
  missionInfo: IMissionInfo
  name: string
  calculatingStatus: MISSION_CALCULATING_STATUSES
  loadingProgress: PROGRESS_TYPES
  isReadyForCalculation: boolean
  cleaningZones: ICleaningZone[]
  restrictZones: IZone[]
  highlightedFeature: IFeature | undefined
  editingZone: IZone | undefined
  editingLaunchPoint: ILaunchPoint | undefined
  visualSettings: IVisualizationSettings
  tempVisualSettings: IVisualizationSettings
  cleaningRoutes: IRoute[]
  launchPoints: ILaunchPoint[]
  transferRoutes: IZoneTransferRoute[]
  status: MISSION_CALCULATING_STATUSES
  geoTiffLayers: GeoTiffLayer[]
  missionPoints: {
    tempPoint: IPoint | undefined
    startPoint: IPoint | undefined
    finishPoint: IPoint | undefined
    routes: ITransferRoute[]
    editingPoint: IPoint | undefined
  }
  geoTiffLayer: {
    show: boolean
    layer: IGeoTiffLayer | undefined
    uploadingProgress: PROGRESS_TYPES
    deletingProgress: PROGRESS_TYPES
  }
  tempZone: IZone | undefined
  tempLaunchPoint: ILaunchPoint | undefined
  tempTransfer: ITransferRoute | undefined
  editingTransfer: ITransferRoute | undefined
  highlightedFeatureInList: string | number | undefined
  savingProgress: PROGRESS_TYPES
  showCleaningZones: boolean
  setMode: (mode: MISSION_PAGE_MODES) => void
  setMapMode: (mapMode: MISSION_MAP_DRAWING_MODES) => void
  setMissionName: (name: string) => void
  setCalculatingStatus: (data: MISSION_CALCULATING_STATUSES) => void
  setLoadingProgress: (data: PROGRESS_TYPES) => void
  setIsReadyForCalculation: (data: boolean) => void
  setCleaningZones: (data: ICleaningZone[]) => void
  setRestrictZones: (data: IZone[]) => void
  setHighlightedFeature: (data: IFeature | undefined) => void
  setEditingZone: (data: IZone | undefined) => void
  setEditingLaunchPoint: (data: ILaunchPoint | undefined) => void
  setVisualSettings: (data: IVisualizationSettings) => void
  setTempVisualSettings: (data: IVisualizationSettings) => void
  deleteRoutes: () => void
  deleteRoute: (id: number) => void
  getCleaningZones: () => ICleaningZone[]
  addLaunchPoint: (data: ILaunchPoint) => void
  getEditingZone: () => IZone | undefined
  getEditingLaunchPoint: () => ILaunchPoint | undefined
  editCleaningZone: (data: ICleaningZone) => void
  setMission: (data: IMission) => void
  setTempZone: (data: IZone | undefined) => void
  setTempLaunchPoint: (data: ILaunchPoint | undefined) => void
  setMissionTempPoint: (data: IPoint | undefined) => void
  setMissionStartPoint: (data: IPoint | undefined) => void
  setMissionFinishPoint: (data: IPoint | undefined) => void
  setTempTransfer: (data: ITransferRoute | undefined) => void
  editTransferRoute: (data: IZoneTransferRoute) => void
  setEditingTransfer: (data: ITransferRoute | undefined) => void
  setHighlightedFeatureInList: (data: string | number | undefined) => void
  setShowGeoTiffLayer: (data: boolean) => void
  setGeoTiffLayer: (data: IGeoTiffLayer | undefined) => void
  setGeoTiffLayerUploadingProgress: (data: PROGRESS_TYPES) => void
  setMissionEditingPoint: (data: IPoint | undefined) => void
  setSavingZoneProgress: (data: PROGRESS_TYPES) => void
  setMissionTransferRoutes: (data: Array<ITransferRoute>) => void
  editMissionTransfer: (data: ITransferRoute) => void
  createCleaningZone: (zone: ICleaningZone, launchPoint: any) => void
  editRestrictZone: (data: IZone) => void
  setTransferRoutes: (data: IZoneTransferRoute[]) => void
  setShowCleaningZones: (data: boolean) => void
  deleteMissionTransfer: (transferId: number) => void
  deleteLaunchPoint: (id: number) => void
  setZonesStatus: (data: CleaningZoneStatus[]) => void
  setLayerStatus: (data: MissionXyzLayerStatus[]) => void
  createRestrictZone: (zone: IZone) => void
  editChangeCleaningZone: (zoneId: number, zone: IZone, launchPoint: ILaunchPoint | undefined) => void
  setChangeRestrictZone: (zone: IZone) => void
  fetchCalculationStatus: (missionId: number) => void
  deleteCleaningZone: (zone: IZone) => void
  deleteRestrictZone: (zone: IZone) => void
  startCalculatingRoute: (zoneId: number) => void
  cancelEditingZone: () => void
  createFeature: (evt: { features: Feature[] }) => void
  editFeature: (evt: { features: Feature[] }) => void
  setChangeZone: (zone: IZone) => void
  setChangeEditingTransfer: (route: ITransferRoute) => void
  cancelEditingTransfer: () => void
  editTransfer: () => void
  deleteTransfer: (transferId: number) => void
  deleteStartPoint: () => void
  deleteFinishPoint: () => void
  setEditingPoint: (point: IPoint) => void
  cancelEditingPoint: () => void
  editPoint: (type: TRANSFER_TYPE, point: IPoint | undefined, startPoint?: IPoint, finishPoint?: IPoint) => void
  fetchMission: (missionId: number) => void
  resetMission: () => void
  patchMissionTransfer: () => void
}
const useMissionStore = create<State>((set, get) => ({
  mode: MISSION_PAGE_MODES.VIEW_ZONES,
  mapMode: MISSION_MAP_DRAWING_MODES.VIEW_ZONES,
  missionInfo: {} as IMissionInfo,
  name: '',
  calculatingStatus: MISSION_CALCULATING_STATUSES.UNKNOWN,
  loadingProgress: PROGRESS_TYPES.IDLE,
  isReadyForCalculation: false,
  cleaningZones: [],
  restrictZones: [],
  highlightedFeature: undefined,
  editingZone: undefined,
  editingLaunchPoint: undefined,
  visualSettings: DEFAULT_VIZUALIZATION_SETTINGS,
  tempVisualSettings: DEFAULT_VIZUALIZATION_SETTINGS,
  cleaningRoutes: [],
  launchPoints: [],
  transferRoutes: [],
  geoTiffLayers: [],
  status: MISSION_CALCULATING_STATUSES.NEW,
  missionPoints: {
    tempPoint: undefined,
    startPoint: undefined,
    finishPoint: undefined,
    routes: [],
    editingPoint: undefined,
  },
  tempZone: undefined,
  tempLaunchPoint: undefined,
  tempTransfer: undefined,
  editingTransfer: undefined,
  highlightedFeatureInList: undefined,
  geoTiffLayer: {
    layer: undefined,
    uploadingProgress: PROGRESS_TYPES.IDLE,
    show: true,
    deletingProgress: PROGRESS_TYPES.IDLE,
  },
  savingProgress: PROGRESS_TYPES.IDLE,
  showCleaningZones: true,
  setMode: data => set({ mode: data }),
  setMapMode: data => {
    set({ mapMode: data })
  },
  setMissionInfo: (data: IMissionInfo) => set({ missionInfo: data }),
  setMissionName: (name: string) => {
    if (get().missionInfo) {
      set({ missionInfo: { ...get().missionInfo, name: name } })
    }
  },
  setCalculatingStatus: (data: MISSION_CALCULATING_STATUSES) => set({ calculatingStatus: data }),
  setCalculatingStatusZone: (data: MISSION_CALCULATING_STATUSES, id: number) =>
    set({
      cleaningZones: get().cleaningZones.map(zone =>
        zone.properties?.zone_id === id ? { ...zone, properties: { ...zone.properties, status: data } } : zone
      ),
    }),
  setLoadingProgress: (data: PROGRESS_TYPES) => {
    set({ loadingProgress: data })
  },
  setIsReadyForCalculation: (data: boolean) => set({ isReadyForCalculation: data }),
  setCleaningZones: (data: ICleaningZone[]) => set(() => ({ cleaningZones: data })),

  getCleaningZones: () => {
    return get().cleaningZones
  },
  getEditingZone: () => {
    return get().editingZone
  },
  setRestrictZones: (data: IZone[]) => set({ restrictZones: data }),
  setHighlightedFeature: (data: IFeature | undefined) => set({ highlightedFeature: data }),
  setEditingZone: (data: IZone | undefined) => set({ editingZone: data }),
  setEditingLaunchPoint: (data: ILaunchPoint | undefined) => set({ editingLaunchPoint: data }),
  getEditingLaunchPoint: () => get().editingLaunchPoint,
  setVisualSettings: (data: IVisualizationSettings) => set({ visualSettings: data }),
  setTempVisualSettings: (data: IVisualizationSettings) => set({ tempVisualSettings: data }),
  deleteRoutes: () => set({ cleaningRoutes: [] }),
  setTempZone: (data: IZone | undefined) => {
    return set({ tempZone: data })
  },
  deleteRoute: (id: number) => {
    return set({ cleaningRoutes: get().cleaningRoutes.filter(route => route.properties?.zone_id !== id) })
  },
  addLaunchPoint: (data: ILaunchPoint) => {
    return set({ launchPoints: [...get().launchPoints, data] })
  },
  editCleaningZone: (data: ICleaningZone) => {
    return set({
      cleaningZones: get().cleaningZones.map(zone =>
        zone.properties?.zone_id === data.properties?.zone_id ? data : zone
      ),
    })
  },
  setTempLaunchPoint: (data: ILaunchPoint | undefined) => {
    return set({ tempLaunchPoint: data })
  },
  setMissionTempPoint: (data: IPoint | undefined) => {
    set({ missionPoints: { ...get().missionPoints, tempPoint: data } })
  },
  setMissionStartPoint: (data: IPoint | undefined) => {
    return set({ missionPoints: { ...get().missionPoints, startPoint: data } })
  },
  setMissionFinishPoint: (data: IPoint | undefined) => {
    return set({ missionPoints: { ...get().missionPoints, finishPoint: data } })
  },
  setTempTransfer: (data: ITransferRoute | undefined) => {
    return set({ tempTransfer: data })
  },
  editTransferRoute: (data: IZoneTransferRoute) => {
    return set({
      transferRoutes: get().transferRoutes.map(transfer =>
        transfer.properties?.id === data.properties?.id ? data : transfer
      ),
    })
  },
  setEditingTransfer: (data: ITransferRoute | undefined) => {
    return set({ editingTransfer: data })
  },
  setHighlightedFeatureInList: (data: string | number | undefined) => {
    return set({ highlightedFeatureInList: data })
  },
  setShowGeoTiffLayer: (data: boolean) => {
    return set({ geoTiffLayer: { ...get().geoTiffLayer, show: data } })
  },
  setGeoTiffLayer: (data: IGeoTiffLayer | undefined) => {
    return set({ geoTiffLayer: { ...get().geoTiffLayer, layer: data } })
  },
  setGeoTiffLayerUploadingProgress: (data: PROGRESS_TYPES) => {
    return set({ geoTiffLayer: { ...get().geoTiffLayer, uploadingProgress: data } })
  },
  setGeoTiffLayerDeletingProgress: (data: PROGRESS_TYPES) => {
    return set({ geoTiffLayer: { ...get().geoTiffLayer, deletingProgress: data } })
  },
  setMissionEditingPoint: (data: IPoint | undefined) => {
    return set({ missionPoints: { ...get().missionPoints, editingPoint: data } })
  },
  setSavingZoneProgress: (data: PROGRESS_TYPES) => {
    return set({ savingProgress: data })
  },
  setMissionTransferRoutes: (data: Array<ITransferRoute>) => {
    return set({ missionPoints: { ...get().missionPoints, routes: data } })
  },
  editRestrictZone: (data: IZone) => {
    set({
      restrictZones: get().restrictZones.map(zone =>
        zone.properties?.zone_id === data.properties?.zone_id ? data : zone
      ),
    })
  },
  setTransferRoutes: (data: IZoneTransferRoute[]) => {
    set({ transferRoutes: data })
  },
  setShowCleaningZones: (data: boolean) => {
    set({ showCleaningZones: data })
  },
  editMissionTransfer: (data: ITransferRoute) => {
    set(state => ({
      missionPoints: {
        ...state.missionPoints,
        routes: state.missionPoints.routes.map((transfer: any) =>
          transfer.properties?.id === data.properties?.id ? data : transfer
        ),
      },
    }))
  },
  deleteMissionTransfer: (transferId: number) => {
    set(state => ({
      missionPoints: {
        ...get().missionPoints,
        routes: state.missionPoints.routes.filter((transfer: any) => transfer.properties?.id !== transferId),
      },
    }))
  },
  deleteLaunchPoint: (id: number) => {
    set(state => ({
      launchPoints: state.launchPoints.filter((point: any) => point.properties?.id !== id),
    }))
  },
  setMission: (data: IMission) => {
    set({
      missionInfo: data.mission,
      visualSettings: data.visualizationSettings,
      tempVisualSettings: data.visualizationSettings,
      cleaningRoutes: data.cleaningRoutes,
      cleaningZones: data.cleaningZones,
      restrictZones: data.restrictZones,
      launchPoints: data.launchPoints,
      transferRoutes: data.transferRoutes,
      isReadyForCalculation: data.isReadyForCalculation,
      status: data.status,
      geoTiffLayers: data.geoTiffLayers,
      missionPoints: {
        editingPoint: data.editingPoint,
        tempPoint: data.tempPoint,
        startPoint: data.startingPoint,
        finishPoint: data.finishingPoint,
        routes: data.pointRoutes || [],
      },
    })
  },
  setZonesStatus: (data: CleaningZoneStatus[]) => {
    data.forEach(cleaningZoneNewStatus => {
      const cleaningZone = get().cleaningZones.find(zone => zone.properties?.zone_id === cleaningZoneNewStatus.id)
      if (cleaningZone && cleaningZone.properties) {
        cleaningZone.properties.status = cleaningZoneNewStatus.status
          ? (cleaningZoneNewStatus.status as MISSION_CALCULATING_STATUSES)
          : MISSION_CALCULATING_STATUSES.CALCULATING
        cleaningZone.properties.readyForCalculation = cleaningZoneNewStatus.is_ready_to_start_calculation
      }
    })
  },
  setLayerStatus: (data: MissionXyzLayerStatus[]) => {
    const geoTiffLayer = get().geoTiffLayer
    const status = data.find(layerStatus => layerStatus.id === geoTiffLayer.layer?.id)

    if (status && geoTiffLayer.layer) {
      geoTiffLayer.layer.status = (status.status as GEOTIFF_LAYER_STATUSES) || GEOTIFF_LAYER_STATUSES.NEW
    }
  },
  resetMission: () => {
    set({
      mode: MISSION_PAGE_MODES.VIEW_ZONES,
      mapMode: MISSION_MAP_DRAWING_MODES.VIEW_ZONES,
      missionInfo: {} as IMissionInfo,
      name: '',
      calculatingStatus: MISSION_CALCULATING_STATUSES.UNKNOWN,
      loadingProgress: PROGRESS_TYPES.IDLE,
      isReadyForCalculation: false,
      cleaningZones: [],
      restrictZones: [],
      highlightedFeature: undefined,
      editingZone: undefined,
      editingLaunchPoint: undefined,
      visualSettings: DEFAULT_VIZUALIZATION_SETTINGS,
      tempVisualSettings: DEFAULT_VIZUALIZATION_SETTINGS,
      cleaningRoutes: [],
      launchPoints: [],
      transferRoutes: [],
      geoTiffLayers: [],
      status: MISSION_CALCULATING_STATUSES.NEW,
      missionPoints: {
        tempPoint: undefined,
        startPoint: undefined,
        finishPoint: undefined,
        routes: [],
        editingPoint: undefined,
      },
      tempZone: undefined,
      tempLaunchPoint: undefined,
      tempTransfer: undefined,
      editingTransfer: undefined,
      highlightedFeatureInList: undefined,
      geoTiffLayer: {
        layer: undefined,
        uploadingProgress: PROGRESS_TYPES.IDLE,
        show: true,
        deletingProgress: PROGRESS_TYPES.IDLE,
      },
      savingProgress: PROGRESS_TYPES.IDLE,
      showCleaningZones: true,
    })
  },
  getStatus: () => {
    return get().calculatingStatus
  },
  createCleaningZone: (zone: ICleaningZone, launchPoint: any) => {
    const state = get()

    state.setCleaningZones([...state.getCleaningZones(), zone as ICleaningZone])
    state.setCalculatingStatus(MISSION_CALCULATING_STATUSES.NEW)
    state.setTempZone(undefined)
    state.setTempLaunchPoint(undefined)
    state.setMode(MISSION_PAGE_MODES.VIEW_ZONES)
    state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)

    if (launchPoint) {
      state.addLaunchPoint(launchPoint)
    }

    state.setSavingZoneProgress(PROGRESS_TYPES.SUCCESS)
  },
  createRestrictZone: (zone: IZone) => {
    const state = get()
    state.setSavingZoneProgress(PROGRESS_TYPES.WORK)
    state.setRestrictZones([...state.restrictZones, zone])
    state.deleteRoutes()
    state.setCalculatingStatus(MISSION_CALCULATING_STATUSES.NEW)
    state.setTempZone(undefined)
    state.setMode(MISSION_PAGE_MODES.VIEW_ZONES)
    state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
    state.setSavingZoneProgress(PROGRESS_TYPES.SUCCESS)
  },
  editChangeCleaningZone: (zoneId: number, zone: IZone, launchPoint: ILaunchPoint | undefined) => {
    const state = get()
    const geometryChanged = !isEqual(zone, state.getEditingZone()?.geometry)
    const launchPointChanged = !isEqual(launchPoint, state.getEditingLaunchPoint()?.geometry)
    state.setSavingZoneProgress(PROGRESS_TYPES.WORK)
    if (geometryChanged || launchPointChanged) {
      state.deleteRoute(zone.properties?.zone_id as number)
    }
    state.editCleaningZone(zone as ICleaningZone)
    state.deleteLaunchPoint(zoneId)
    if (launchPoint) {
      state.addLaunchPoint(launchPoint)
    }
    state.setEditingZone(undefined)
    state.setTempZone(undefined)
    state.setTempLaunchPoint(undefined)
    state.setMode(MISSION_PAGE_MODES.VIEW_ZONES)
    state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
    state.setSavingZoneProgress(PROGRESS_TYPES.SUCCESS)
  },
  setChangeRestrictZone: (zone: IZone) => {
    const state = get()
    state.editRestrictZone(zone)
    state.deleteRoutes()
    state.setEditingZone(undefined)
    state.setTempZone(undefined)
    state.setMode(MISSION_PAGE_MODES.VIEW_ZONES)
    state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
  },
  fetchCalculationStatus: async (missionId: number) => {
    const state = get()
    try {
      const status = await MainApi.fetchCalculationStatus(missionId)
      if (!status) {
        return
      }
      if (status.status !== state.calculatingStatus) {
        const newMission = await MainApi.fetchMission(state.missionInfo?.id || 0)
        state.setMission(newMission)
        state.setCalculatingStatus(newMission.status)
      }
      if (status.is_ready_to_start_calculation !== state.isReadyForCalculation) {
        state.setIsReadyForCalculation(status.is_ready_to_start_calculation)
      }
      state.setZonesStatus(status.cleaning_zones_statuses)
      state.setLayerStatus(status.xyz_layers_statuses)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  },
  deleteCleaningZone: (zone: IZone) => {
    const state = get()
    const newZones = state.getCleaningZones().filter(zone_ => zone_.id !== zone.id)
    state.setCleaningZones(newZones)
    state.setCalculatingStatus(MISSION_CALCULATING_STATUSES.NEW)
    state.deleteRoute(zone.properties?.zone_id as number)
    state.deleteLaunchPoint(zone.properties?.zone_id as number)
  },
  deleteRestrictZone: (zone: IZone) => {
    const state = get()
    const newZones = state.restrictZones.filter(zone_ => zone_.id !== zone.id)
    state.setRestrictZones(newZones)
    state.setCalculatingStatus(MISSION_CALCULATING_STATUSES.NEW)
    state.deleteRoutes()
  },
  startCalculatingRoute: async (zoneId: number) => {
    const state = get()
    try {
      state.setCalculatingStatus(MISSION_CALCULATING_STATUSES.CALCULATING)
      await MainApi.startCalculatingRoute(zoneId)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error start calculating route')
    }
  },
  cancelEditingZone: () => {
    const state = get()
    state.setMode(MISSION_PAGE_MODES.VIEW_ZONES)
    state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
    state.setTempZone(undefined)
    state.setEditingZone(undefined)
    state.setTempLaunchPoint(undefined)
    state.setEditingLaunchPoint(undefined)
  },
  createFeature: async (evt: { features: Feature[] }) => {
    const state = get()
    if (state.mapMode === MISSION_MAP_DRAWING_MODES.DRAW_POLYGON && evt.features[0]) {
      ;(evt.features[0] as Feature<Geometry, GeoJsonProperties>).properties = { name: 'New zone' }
      state.setTempZone(evt.features[0] as IZone)
      state.setTempLaunchPoint(undefined)
      state.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE)
    } else if (state.mapMode === MISSION_MAP_DRAWING_MODES.ADD_ZONE_LAUNCH_POINT && evt.features[0]) {
      state.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE)
      state.setTempLaunchPoint(evt.features[0] as ILaunchPoint)
    } else if (state.mapMode === MISSION_MAP_DRAWING_MODES.ADD_MISSION_START_POINT && evt.features[0]) {
      try {
        const missionId = state.missionInfo?.id
        if (!missionId) {
          return
        }
        const startPoint = await MainApi.changeMissionStartPoint({
          id: missionId,
          startingPoint: (evt.features[0] as IPoint).geometry,
        })
        if (startPoint) {
          state.setMissionStartPoint(startPoint)
        }
        state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error creating start point')
      }
    } else if (state.mapMode === MISSION_MAP_DRAWING_MODES.ADD_MISSION_FINISH_POINT && evt.features[0]) {
      try {
        const missionId = state.missionInfo?.id
        if (!missionId) {
          return
        }
        const finishPoint = await MainApi.changeMissionFinishPoint({
          id: missionId,
          finishingPoint: (evt.features[0] as IPoint).geometry,
        })
        if (finishPoint) {
          state.setMissionFinishPoint(finishPoint)
        }
        state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error creating finish point')
      }
    }
  },
  editFeature: (evt: { features: Feature[] }) => {
    const state = get()
    if (state.mapMode === MISSION_MAP_DRAWING_MODES.DRAW_POLYGON && evt.features[0]) {
      state.setTempZone(evt.features[0] as IZone)
    } else if (state.mapMode === MISSION_MAP_DRAWING_MODES.ADD_ZONE_LAUNCH_POINT && evt.features[0]) {
      state.setTempLaunchPoint(evt.features[0] as ILaunchPoint)
    } else if (state.mapMode === MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE) {
      state.setTempZone(evt.features[0] as IZone)
    } else if (state.mapMode === MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_TRANSFER_ROUTE) {
      state.setTempTransfer(evt.features[0] as ITransferRoute)
    } else if (state.mapMode === MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_MISSION_POINT) {
      state.setMissionTempPoint(evt.features[0] as IPoint)
    } else if (state.mapMode === MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE_LAUNCH_POINT) {
      state.setTempLaunchPoint(evt.features[0] as ILaunchPoint)
    }
  },
  setChangeZone: (zone: IZone) => {
    const state = get()
    state.setEditingZone(zone)
    state.setTempZone(cloneDeep(zone))
    state.setHighlightedFeature(zone)
    state.setMode(MISSION_PAGE_MODES.EDIT_ZONE)
    if (zone.properties.zone_type === MISSION_ZONE_TYPES.CLEANING) {
      const launchPoint: any = state.launchPoints.find(
        (launchPoint: any) => launchPoint.properties?.zone_id === zone.properties.zone_id
      )
      state.setEditingLaunchPoint(launchPoint)
      state.setTempLaunchPoint(launchPoint)
    }
    state.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE)
  },

  setChangeEditingTransfer: (route: ITransferRoute) => {
    const state = get()
    state.setEditingTransfer(route)
    const userGeometryTransfer = cloneDeep(route)
    const tempGeometry = userGeometryTransfer.geometry
    userGeometryTransfer.geometry = cloneDeep(userGeometryTransfer.userGeometry)
    userGeometryTransfer.userGeometry = tempGeometry
    state.setTempTransfer(userGeometryTransfer)
    state.setHighlightedFeature(route)
    state.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_TRANSFER_ROUTE)
  },
  cancelEditingTransfer: () => {
    const state = get()
    state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
    state.setEditingTransfer(undefined)
    state.setTempTransfer(undefined)
  },
  editTransfer: async () => {
    const state = get()
    try {
      const transferId = state.tempTransfer?.properties.id
      if (transferId) {
        await MainApi.patchTransferRoute(transferId, state.tempTransfer?.geometry)
      }
      const tempTransfer = cloneDeep(state.tempTransfer as IZoneTransferRoute)
      if (tempTransfer) {
        tempTransfer.userGeometry = tempTransfer.geometry
        tempTransfer.properties.status = TRANSFER_CALCULATING_STATUSES.NEW
        state.editTransferRoute(tempTransfer)
      }
      state.setEditingTransfer(undefined)
      state.setTempTransfer(undefined)
      state.setMode(MISSION_PAGE_MODES.VIEW_ZONES)
      state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error editing cleaning zone', error)
      state.setEditingTransfer(undefined)
      state.setMode(MISSION_PAGE_MODES.VIEW_ZONES)
      state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
    }
  },
  deleteTransfer: (transferId: number) => {
    const state = get()
    try {
      const newTransferRoutes = state.transferRoutes.filter(route => route.properties.id !== transferId)
      state.setTransferRoutes(newTransferRoutes)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Произошла ошибка:', error)
    }
  },
  deleteStartPoint: () => {
    const state = get()
    state.setMissionStartPoint(undefined)
  },
  deleteFinishPoint: () => {
    const state = get()
    state.setMissionFinishPoint(undefined)
  },
  setEditingPoint: (point: IPoint) => {
    const state = get()
    state.setMissionEditingPoint(point)
    state.setMissionTempPoint(cloneDeep(point))
    state.setHighlightedFeature(point)
    state.setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_MISSION_POINT)
  },
  cancelEditingPoint: () => {
    const state = get()
    state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
    state.setMissionEditingPoint(undefined)
    state.setMissionTempPoint(undefined)
  },
  editPoint: (type: TRANSFER_TYPE, point: IPoint | undefined, startPoint?: IPoint, finishPoint?: IPoint) => {
    const state = get()
    if (!point) {
      return
    }
    if (type === TRANSFER_TYPE.START) {
      state.setMissionStartPoint(startPoint)
    } else {
      state.setMissionFinishPoint(finishPoint)
    }
    state.setMissionTempPoint(undefined)
    state.setMissionEditingPoint(undefined)
    state.setMode(MISSION_PAGE_MODES.VIEW_ZONES)
    state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
  },
  fetchMission: async (missionId: number) => {
    const state = get()
    try {
      state.setLoadingProgress(PROGRESS_TYPES.WORK)
      const mission = await MainApi.fetchMission(missionId)
      state.setMission(mission)
      state.setLoadingProgress(PROGRESS_TYPES.SUCCESS)
    } catch (error) {
      state.setLoadingProgress(PROGRESS_TYPES.ERROR)
    }
  },
  patchMissionTransfer: async () => {
    const state = get()
    try {
      const transferId = state.tempTransfer?.properties.id
      if (transferId) {
        await MainApi.patchMissionPointTransferRoute(transferId, state.tempTransfer?.geometry)
      }
      const tempTransfer = cloneDeep(state.tempTransfer as IZoneTransferRoute)
      if (tempTransfer) {
        tempTransfer.userGeometry = tempTransfer.geometry
        tempTransfer.properties.status = TRANSFER_CALCULATING_STATUSES.NEW
        state.editMissionTransfer(tempTransfer)
      }
      state.setEditingTransfer(undefined)
      state.setTempTransfer(undefined)
      state.setMode(MISSION_PAGE_MODES.VIEW_ZONES)
      state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error editing route')
      state.setEditingTransfer(undefined)
      state.setMode(MISSION_PAGE_MODES.VIEW_ZONES)
      state.setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
    }
  },
}))

export default useMissionStore
