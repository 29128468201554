import { Button } from 'components/Button/Button'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useMissionStore from 'store/mission'
import { MISSION_MAP_DRAWING_MODES } from 'utils/constants'
import { useShallow } from 'zustand/react/shallow'

import style from './LaunchPointSettings.module.css'

enum MODES {
  NO_POINT,
  DRAWING,
  DRAWN,
}

const LaunchPointSettings = () => {
  const { t } = useTranslation()
  const {
    setMapMode,
    setEditingLaunchPoint,
    editingZone,
    editingLaunchPoint,
    mapMode,
    setTempLaunchPoint,
    tempLaunchPoint,
    tempZone,
  } = useMissionStore(
    useShallow(state => ({
      setMapMode: state.setMapMode,
      setEditingLaunchPoint: state.setEditingLaunchPoint,
      editingZone: state.editingZone,
      editingLaunchPoint: state.editingLaunchPoint,
      mapMode: state.mapMode,
      setTempLaunchPoint: state.setTempLaunchPoint,
      tempLaunchPoint: state.tempLaunchPoint,
      tempZone: state.tempZone,
    }))
  )

  const mode = useMemo(() => {
    if (!tempLaunchPoint) {
      if (mapMode === MISSION_MAP_DRAWING_MODES.ADD_ZONE_LAUNCH_POINT) {
        return MODES.DRAWING
      }
      return MODES.NO_POINT
    }
    return MODES.DRAWN
  }, [mapMode, tempLaunchPoint])

  const onAddPointClick = () => {
    setMapMode(MISSION_MAP_DRAWING_MODES.ADD_ZONE_LAUNCH_POINT)
  }

  const onCancelClick = () => {
    setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE)
  }

  const onDeleteClick = () => {
    if (editingLaunchPoint && editingZone) {
      setEditingLaunchPoint(undefined)
    }
    setTempLaunchPoint(undefined)
    setMapMode(MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE)
  }

  const buttons = {
    [`${MODES.NO_POINT}`]: (
      <Button.Outlined disabled={!editingZone && !tempZone} className={style.button} onClick={onAddPointClick}>
        {t('zone_details.start_point_add_point')}
      </Button.Outlined>
    ),
    [`${MODES.DRAWING}`]: (
      <Button.Outlined className={style.button} onClick={onCancelClick}>
        {t('zone_details.start_point_cancel_drawing')}
      </Button.Outlined>
    ),
    [`${MODES.DRAWN}`]: (
      <Button.Outlined className={style.button} onClick={onDeleteClick}>
        {t('zone_details.start_point_delete_point')}
      </Button.Outlined>
    ),
  }

  const statuses = {
    [`${MODES.NO_POINT}`]: t('zone_details.start_point_status'),
    [`${MODES.DRAWING}`]: t('zone_details.start_point_status'),
    [`${MODES.DRAWN}`]: t('zone_details.start_point_distance'),
  }

  return (
    <>
      <div className={style.flex}>
        <div className={style.description}>{statuses[mode]}</div>
        {buttons[mode]}
      </div>
      {mode === MODES.NO_POINT && (
        <div className={style.descriptionStart}>{t('zone_details.start_point_description')}</div>
      )}
    </>
  )
}

export default LaunchPointSettings
