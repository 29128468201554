import { useQuery } from '@tanstack/react-query'
import { MainService, UnitRead } from 'api/schema'
import { useParams } from 'react-router-dom'

const useUnit = (id?: number) => {
  const params = useParams()
  const unitId = Number(id) || Number(params.unitId)

  return useQuery<UnitRead, Error>({
    queryKey: ['unit', unitId],
    queryFn: () => MainService.mainUnitRetrieve(unitId),
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: true,
    enabled: !!unitId,
  })
}
export default useUnit
