import { Button, Stack } from '@mui/material'
import { ReactComponent as PlusSvg } from 'images/icons/plus.svg'
import useMissionStore from 'store/mission'
import { MISSION_MAP_DRAWING_MODES, MISSION_PAGE_MODES } from 'utils/constants'
import { useShallow } from 'zustand/react/shallow'

const CreateZoneButtons = () => {
  const { setMode, setMapMode, missionPoints } = useMissionStore(
    useShallow(state => ({
      setMode: state.setMode,
      setMapMode: state.setMapMode,
      missionPoints: state.missionPoints,
    }))
  )

  const handleZoneClick = () => {
    setMode(MISSION_PAGE_MODES.DRAW_ZONE)
    setMapMode(MISSION_MAP_DRAWING_MODES.DRAW_POLYGON)
  }

  const handleStartPointClick = () => {
    setMode(MISSION_PAGE_MODES.VIEW_ZONES)
    setMapMode(MISSION_MAP_DRAWING_MODES.ADD_MISSION_START_POINT)
  }

  const handleFinishPointClick = () => {
    setMode(MISSION_PAGE_MODES.VIEW_ZONES)
    setMapMode(MISSION_MAP_DRAWING_MODES.ADD_MISSION_FINISH_POINT)
  }

  return (
    <Stack direction="row" width="100%" my={1}>
      <Button onClick={handleZoneClick} variant="contained" fullWidth endIcon={<PlusSvg />} sx={{ marginX: 1 }}>
        New area
      </Button>
      <Button
        onClick={handleStartPointClick}
        disabled={!!missionPoints.startPoint}
        variant="contained"
        fullWidth
        endIcon={<PlusSvg />}
      >
        Start Point
      </Button>
      <Button
        onClick={handleFinishPointClick}
        disabled={!!missionPoints.finishPoint}
        variant="contained"
        fullWidth
        endIcon={<PlusSvg />}
        sx={{ marginX: 1 }}
      >
        Finish Point
      </Button>
    </Stack>
  )
}

export default CreateZoneButtons
