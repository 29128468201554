import { useMutation } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { IZone, ZoneUpdateData } from 'utils/interfaces'

const useEditCleaningZone = () =>
  useMutation<IZone, Error, ZoneUpdateData>({
    mutationFn: MainApi.patchRestrictZone,
  })

export default useEditCleaningZone
