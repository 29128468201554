import { Box } from '@mui/material'
import useMissionStore from 'store/mission'
import { useShallow } from 'zustand/react/shallow'

import CalculateZoneButton from './CalculateZoneButton'
import CreateZoneButtons from './CreateZoneButtons'
import ViewZonesMode from './ViewZonesMode'

const ViewCreateZones = () => {
  const { cleaningZones, restrictZones } = useMissionStore(
    useShallow(state => ({
      cleaningZones: state.cleaningZones,
      restrictZones: state.restrictZones,
    }))
  )

  return (
    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
      <Box overflow="auto" height="100%">
        <CreateZoneButtons />
        <ViewZonesMode cleaningZones={cleaningZones} restrictZones={restrictZones} />
      </Box>
      <CalculateZoneButton />
    </Box>
  )
}

export default ViewCreateZones
