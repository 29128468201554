import { Grid } from '@mui/material'
import Loader from 'components/Loader/Loader'
import useRobot from 'hooks/useRobot'
import useToggleRobotLock from 'hooks/useToggleRobotLock'
import { useState } from 'react'
import useTelemetryStore from 'store/robotTelemetry'
import { ROBOT_EQUIPMENT_COMMAND } from 'utils/constants'

import RobotLock from './RobotLock'

const RobotControlLocks = () => {
  const { data: robot } = useRobot()
  const { mutate: toggleRobotLock, isPending } = useToggleRobotLock()
  const telemetry = useTelemetryStore(s => s.telemetry)

  const [currentLockToggle, setCurrentLockToggle] = useState<ROBOT_EQUIPMENT_COMMAND | null>(null)

  const handleLockChanged = (command: ROBOT_EQUIPMENT_COMMAND, value: boolean) => {
    if (!robot?.id) return
    setCurrentLockToggle(command)
    toggleRobotLock({ id: robot?.id, command, value: value ? '1' : '2' })
  }

  if (!telemetry?.locks) return <Loader />

  return (
    <Grid container spacing={1} height="50%">
      <Grid item xs={6} md={3}>
        <RobotLock
          header="Trash lock"
          tooltip="¯\_(ツ)_/¯"
          checked={telemetry?.locks?.trash}
          isLoading={
            (isPending && currentLockToggle === ROBOT_EQUIPMENT_COMMAND.TRASH) || telemetry?.locks?.trashIsLoading
          }
          onChange={e => handleLockChanged(ROBOT_EQUIPMENT_COMMAND.TRASH, e.target.checked)}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <RobotLock
          header="Body lock"
          tooltip="¯\_(ツ)_/¯"
          checked={telemetry?.locks?.body}
          isLoading={
            (isPending && currentLockToggle === ROBOT_EQUIPMENT_COMMAND.BODY) || telemetry?.locks?.bodyIsLoading
          }
          onChange={e => handleLockChanged(ROBOT_EQUIPMENT_COMMAND.BODY, e.target.checked)}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <RobotLock
          header="Front lock"
          tooltip="¯\_(ツ)_/¯"
          checked={telemetry?.locks?.front}
          isLoading={
            (isPending && currentLockToggle === ROBOT_EQUIPMENT_COMMAND.FRONT) || telemetry?.locks?.frontIsLoading
          }
          onChange={e => handleLockChanged(ROBOT_EQUIPMENT_COMMAND.FRONT, e.target.checked)}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <RobotLock
          header="Charge lock"
          tooltip="¯\_(ツ)_/¯"
          checked={telemetry?.locks?.charge}
          isLoading={
            (isPending && currentLockToggle === ROBOT_EQUIPMENT_COMMAND.CHARGE) || telemetry?.locks?.chargeIsLoading
          }
          onChange={e => handleLockChanged(ROBOT_EQUIPMENT_COMMAND.CHARGE, e.target.checked)}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <RobotLock
          header="Cleaning equipment"
          tooltip="¯\_(ツ)_/¯"
          checked={telemetry?.cleaningEquipmentState === 1}
          isLoading={
            (isPending && currentLockToggle === ROBOT_EQUIPMENT_COMMAND.CLEANING_EQUIPMENT) ||
            telemetry?.cleaningEquipmentStateIsLoading
          }
          onChange={e => handleLockChanged(ROBOT_EQUIPMENT_COMMAND.CLEANING_EQUIPMENT, e.target.checked)}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <RobotLock
          header="Watering"
          tooltip="¯\_(ツ)_/¯"
          checked={telemetry?.locks?.watering}
          isLoading={
            (isPending && currentLockToggle === ROBOT_EQUIPMENT_COMMAND.WATER) || telemetry?.locks?.wateringIsLoading
          }
          onChange={e => handleLockChanged(ROBOT_EQUIPMENT_COMMAND.WATER, e.target.checked)}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <RobotLock
          header="Light"
          tooltip="¯\_(ツ)_/¯"
          checked={telemetry?.locks?.light}
          isLoading={
            (isPending && currentLockToggle === ROBOT_EQUIPMENT_COMMAND.LIGHT) || telemetry?.locks?.lightIsLoading
          }
          onChange={e => handleLockChanged(ROBOT_EQUIPMENT_COMMAND.LIGHT, e.target.checked)}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <RobotLock
          header="Emergency stop"
          tooltip="¯\_(ツ)_/¯"
          checked={telemetry?.locks?.dangerButton}
          isLoading={
            (isPending && currentLockToggle === ROBOT_EQUIPMENT_COMMAND.DANGER) ||
            telemetry?.locks?.dangerButtonIsLoading
          }
          onChange={e => handleLockChanged(ROBOT_EQUIPMENT_COMMAND.DANGER, e.target.checked)}
        />
      </Grid>
    </Grid>
  )
}

export default RobotControlLocks
