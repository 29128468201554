import { useQuery } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { ObjectOfInterest } from 'api/schema'
import { useParams } from 'react-router-dom'

const useRobotObjectsOfInterest = (id?: number) => {
  const params = useParams()
  const robotId = Number(id) || Number(params.assetId)

  return useQuery<ObjectOfInterest[], Error>({
    queryKey: ['objectsOfInterest', robotId],
    queryFn: () => MainApi.fetchObjectsOfInterest(robotId),
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: true,
    enabled: !!robotId,
  })
}
export default useRobotObjectsOfInterest
