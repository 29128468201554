import { Accordion, AccordionDetails, AccordionSummary, Button, Stack } from '@mui/material'
import Loader from 'components/Loader/Loader'
import useDownloadMission from 'hooks/mission/useDownloadMission'
import useDuplicateMission from 'hooks/mission/useDuplicateMission'

interface Props {
  missionId: number
  missionName: string
}

function ExportMission({ missionId, missionName }: Props) {
  const { mutate: duplicate, isPending } = useDuplicateMission()
  const { getMission, isLoading, error } = useDownloadMission(missionId, missionName)
  return (
    <Accordion>
      <AccordionSummary>Export mission</AccordionSummary>
      {isPending || isLoading ? (
        <Loader />
      ) : (
        <AccordionDetails>
          <Stack spacing={2} direction="row">
            <Button
              onClick={() => getMission()}
              sx={{ maxWidth: '250px', minHeight: '44px' }}
              fullWidth
              variant="contained"
            >
              Save to file
            </Button>
            <Button
              sx={{ maxWidth: '250px', minHeight: '44px', color: '#424040' }}
              onClick={() => duplicate(missionId)}
              fullWidth
              variant="outlined"
            >
              Duplicate
            </Button>
          </Stack>
          {error && <div>{error}</div>}
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default ExportMission
