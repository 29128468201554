import { Stack } from '@mui/material'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import Loader from 'components/Loader/Loader'
import Search from 'components/Serch/Search'
import useAllMissions from 'hooks/useAllMissions'
import { useFilteredMissions } from 'hooks/useFilteredMissions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MISSION_CALCULATING_STATUSES } from 'utils/constants'
import { IMissionInfo } from 'utils/interfaces'

import MissionsList from './MissionsList'

import style from './SelectMissionModal.module.css'

interface IDialogProps {
  selectedMissionId?: number
  isOpen: boolean
  onConfirmClick: (mission: IMissionInfo | undefined) => void
  onClose: () => void
}

const SelectMissionModal = ({
  isOpen,
  selectedMissionId,
  onConfirmClick,
  onClose,
}: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()
  const { filteredMissions, setSearchInput, searchInput } = useFilteredMissions()
  const [selectedMission, setSelectedMission] = useState<IMissionInfo | undefined>(undefined)

  const { data: missions, isLoading, error } = useAllMissions()

  return (
    <ConfirmDialog
      isOpen={isOpen}
      className={style.root}
      onClose={onClose}
      onConfirmClick={() => onConfirmClick(selectedMission)}
      title={t('robot.modal_select_mission_title')}
      confirmText={t('robot.modal_select_mission_confirm')}
      confirmButtonDisabled={!selectedMission}
    >
      <div className={style.content}>
        {error && <div>{error.message}</div>}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Stack width="100%" marginTop={{ xs: 1, md: 3 }}>
              <Search
                onSearch={value => {
                  setSearchInput(value)
                }}
                searchInput={searchInput}
              />
            </Stack>
            <MissionsList
              missions={filteredMissions.filter(
                mission => mission.status === MISSION_CALCULATING_STATUSES.OK && mission.id !== selectedMissionId
              )}
              onSelect={mission => setSelectedMission(mission)}
              selectedMission={selectedMission}
            />
          </>
        )}
        {!isLoading && !missions.length && <>{t('robot.modal_missions_empty')}</>}
      </div>
    </ConfirmDialog>
  )
}

export default SelectMissionModal
