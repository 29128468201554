import { Box, IconButton, InputBase } from '@mui/material'
import { MainService } from 'api/schema'
import LocationIcon from 'images/icons/location.svg'
import { ReactComponent as SearchIcon } from 'images/icons/search.svg'
import Feature from 'ol/Feature'
import Control from 'ol/control/Control'
import Point from 'ol/geom/Point'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'
import { useEffect, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import useMapStore from 'store/map'

import style from './Search.module.css'

type IFormInputs = {
  changeText: string
}

const SearchWidgets = () => {
  const map = useMapStore(s => s.map)
  const controlRef = useRef<HTMLDivElement | null>(null)
  const { handleSubmit, control } = useForm<IFormInputs>()

  const [searchLayer, setSearchLayer] = useState<VectorLayer<VectorSource> | null>(null)

  useEffect(() => {
    if (!map || !controlRef) return

    const legendControl = new Control({
      element: controlRef.current as HTMLInputElement,
    })

    map.addControl(legendControl)

    const layer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 3,
      style: new Style({
        image: new Icon({
          src: LocationIcon,
        }),
      }),
    })
    map.addLayer(layer)
    setSearchLayer(layer)

    return () => {
      map.removeControl(legendControl)
      map.removeLayer(layer)
    }
  }, [map, controlRef])

  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    const response = await MainService.mainGeocodeRetrieve(data.changeText)
    if (!response) return
    const { lat, lng } = response
    if (!lat || !lng) return

    const view = map?.getView()
    view?.setCenter(fromLonLat([lng, lat]))
    view?.setZoom(18)

    createPin(lat, lng)
  }

  const createPin = (x: number, y: number) => {
    if (!map) return

    const feature = new Feature({
      geometry: new Point(fromLonLat([y, x])),
    })

    searchLayer?.getSource()?.clear()
    searchLayer?.getSource()?.addFeature(feature)
  }

  const removePin = (value: string) => {
    if (value) return

    searchLayer?.getSource()?.clear()
  }
  return (
    <div
      ref={controlRef}
      className="ol-unselectable ol-control ol-control-no-border"
      style={{ top: '20px', right: '.9em', backgroundColor: 'transparent', display: 'flex' }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="changeText"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <Box className={style.searchInput}>
              <InputBase
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                  removePin(e.target.value)
                }}
                placeholder="Search"
                inputProps={{ 'aria-label': 'Search' }}
                sx={{ margin: '12px 12px 12px 20px' }}
              />
              <IconButton type="submit" sx={{ transform: 'translateY(-1px)' }}>
                <SearchIcon />
              </IconButton>
            </Box>
          )}
        />
      </form>
    </div>
  )
}

export default SearchWidgets
