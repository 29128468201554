import useAllRobots from 'hooks/useAllRobots'
import { useState } from 'react'

export const useFilteredRobots = () => {
  const { data: robots = [], error, refetch, isFetching } = useAllRobots()
  const [searchInput, setSearchInput] = useState('')

  const filteredRobots =
    searchInput === '' ? robots : robots.filter(robot => robot.name.toLowerCase().includes(searchInput.toLowerCase()))

  return { filteredRobots, setSearchInput, searchInput, error, refetch, isFetching }
}
