import { CircularProgress, IconButton, Stack, Switch, Tooltip, Typography } from '@mui/material'
import { ReactComponent as QuestionMarkIcon } from 'images/icons/faq.svg'
import { ChangeEvent } from 'react'

interface Props {
  header: string
  tooltip: string
  checked?: boolean
  isLoading?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const RobotLock = ({ header, tooltip, checked, isLoading, onChange }: Props) => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      height="100%"
      borderRadius={2}
      paddingX={1}
      bgcolor="info.main"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" align="center" color="#9E9E9E">
          {header}
        </Typography>
        {isLoading && <CircularProgress color="secondary" size={18} />}
        <Tooltip title={tooltip} placement="top">
          <IconButton size="small">
            <QuestionMarkIcon />
          </IconButton>
        </Tooltip>
      </Stack>

      <Stack justifyContent="center" alignItems="center" height="80%">
        <Switch checked={checked} onChange={onChange} disabled={isLoading} />
      </Stack>
    </Stack>
  )
}

export default RobotLock
