import { Box, IconButton } from '@mui/material'
import clsx from 'clsx'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'
import { ReactComponent as MinusSvg } from 'images/icons/minus-small.svg'
import { ReactComponent as PlusSvg } from 'images/icons/plus-small.svg'
import ViewZonesMode from 'pages/Mission/MissionView/Modes/ViewZone/ViewZonesMode'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MISSION_CALCULATING_STATUSES, MISSION_ZONE_TYPES } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { ICleaningZone, IRestrictZone } from 'utils/interfaces'

import style from './CollapsibleTableScenarios.module.css'

type ViewZonesModedata = {
  cleaningZones: Array<ICleaningZone>
  restrictZones: Array<IRestrictZone>
  hideActions?: boolean
}

const data: ViewZonesModedata[] = [
  {
    cleaningZones: [
      {
        geometry: {
          coordinates: [
            [
              [37.787785851177546, 55.93178961240315],
              [37.787890573510396, 55.93178877435588],
              [37.78789108887463, 55.931343245860205],
              [37.78778485265562, 55.93134266240884],
              [37.787785851177546, 55.93178961240315],
            ],
          ],
          type: 'Polygon',
        },
        id: 'cleaning_526',
        properties: {
          area: 327.5588988837553,
          description: '',
          executionTime: 1442.7630615234375,
          id_on_map: 'cleaning_526',
          length: 432.9716320417987,
          name: 'Zone 1',
          readyForCalculation: true,
          status: MISSION_CALCULATING_STATUSES.OK,
          zone_id: 526,
          zone_type: MISSION_ZONE_TYPES.CLEANING,
        },
        type: 'Feature',
      },
    ],
    restrictZones: [
      {
        geometry: {
          coordinates: [
            [
              [37.787785851177546, 55.93178961240315],
              [37.787890573510396, 55.93178877435588],
              [37.78789108887463, 55.931343245860205],
              [37.78778485265562, 55.93134266240884],
              [37.787785851177546, 55.93178961240315],
            ],
          ],
          type: 'Polygon',
        },
        id: 'restrict_281',
        properties: {
          description: '',
          id_on_map: 'restrict_281',
          name: 'Res 1',
          zone_id: 281,
          zone_type: MISSION_ZONE_TYPES.CLEANING,
        },
        type: 'Feature',
      },
    ],
  },

  {
    cleaningZones: [
      {
        geometry: {
          coordinates: [
            [
              [37.787785851177546, 55.93178961240315],
              [37.787890573510396, 55.93178877435588],
              [37.78789108887463, 55.931343245860205],
              [37.78778485265562, 55.93134266240884],
              [37.787785851177546, 55.93178961240315],
            ],
          ],
          type: 'Polygon',
        },
        id: 'cleaning_526',
        properties: {
          area: 327.5588988837553,
          description: '',
          executionTime: 1442.7630615234375,
          id_on_map: 'cleaning_526',
          length: 432.9716320417987,
          name: 'Zone 1',
          readyForCalculation: true,
          status: MISSION_CALCULATING_STATUSES.OK,
          zone_id: 526,
          zone_type: MISSION_ZONE_TYPES.CLEANING,
        },
        type: 'Feature',
      },
    ],
    restrictZones: [
      {
        geometry: {
          coordinates: [
            [
              [37.787785851177546, 55.93178961240315],
              [37.787890573510396, 55.93178877435588],
              [37.78789108887463, 55.931343245860205],
              [37.78778485265562, 55.93134266240884],
              [37.787785851177546, 55.93178961240315],
            ],
          ],
          type: 'Polygon',
        },
        id: 'restrict_281',
        properties: {
          description: '',
          id_on_map: 'restrict_281',
          name: 'Res 1',
          zone_id: 281,
          zone_type: MISSION_ZONE_TYPES.CLEANING,
        },
        type: 'Feature',
      },
    ],
  },
]

const CollapsibleTableScenarios = () => {
  const [openIndexes, setOpenIndexes] = useState(Array(data.length).fill(false))
  const { t } = useTranslation()

  const handleToggle = (index: number) => {
    const newOpenIndexes = [...openIndexes]
    newOpenIndexes[index] = !newOpenIndexes[index]
    setOpenIndexes(newOpenIndexes)
  }

  const ToggleIconButton = ({ open, onToggle }: { open: boolean; onToggle: () => void }) => {
    return (
      <IconButton
        className={style.collapseBtn}
        sx={{ backgroundColor: '#ffffff', borderRadius: '4px' }}
        aria-label="expand row"
        size="small"
        onClick={onToggle}
      >
        {open ? <MinusSvg className={style.collapseIcon} /> : <PlusSvg className={style.collapseIcon} />}
      </IconButton>
    )
  }

  return (
    <>
      <div className={style.tableWrapper}>
        <div className={style.tableHeader}>
          <div className={clsx(style.tableHeaderItem, 'text-12-500')}>{'№'}</div>
          <div className={clsx(style.tableHeaderItem, 'text-12-500')}>{t('units.scenarios')}</div>
          <div className={clsx(style.tableHeaderItem, 'text-12-500')}>{t('units.robot_id')}</div>
          <div className={clsx(style.tableHeaderItem, 'text-12-500')}>{t('units.length')}</div>
          <div className={clsx(style.tableHeaderItem, 'text-12-500')}>{t('units.area')}</div>
          <div className={clsx(style.tableHeaderItem, 'text-12-500')}>{t('units.launch_time')}</div>
        </div>
        {data.map((item, index) => (
          <div key={index}>
            <div className={style.tableHeader}>
              <div className={clsx(style.tableCellItem, 'text-14-600')}>
                {<ToggleIconButton open={openIndexes[index]} onToggle={() => handleToggle(index)} />}
              </div>
              <div className={clsx(style.tableCellItem, 'text-14-600')}>{t('1')}</div>
              <div className={clsx(style.tableCellItem, 'text-14-600')}>{t('2')}</div>
              <div className={clsx(style.tableCellItem, 'text-14-600')}>{t('3')}</div>
              <div className={clsx(style.tableCellItem, 'text-14-600')}>{t('4')}</div>
              <div className={clsx(style.tableCellItem, 'text-14-600')}>{t('5')}</div>
              <div className={clsx(style.tableCellItem, 'text-14-600')}>
                {
                  <ActionsMenu>
                    <ActionsMenuItem onClick={() => {}} data-test-id={getTestId('edit-zone-list')}>
                      <span className={style.menuItemIcon} />
                      <span data-test-id={getTestId('edit-title')}>{'Edit'}</span>
                    </ActionsMenuItem>
                    <ActionsMenuItem onClick={() => {}} data-test-id={getTestId('remove-zone-list')}>
                      <RemoveSvg className={style.menuItemIcon} />
                      <span data-test-id={getTestId('delete-title')}>{'Delete'}</span>
                    </ActionsMenuItem>
                  </ActionsMenu>
                }
              </div>
            </div>
            {openIndexes[index] && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                <div style={{ width: '90%' }}>
                  <ViewZonesMode key={index} cleaningZones={item.cleaningZones} restrictZones={item.restrictZones} />
                </div>
              </Box>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default CollapsibleTableScenarios
