import { GridColDef, GridSortModel } from '@mui/x-data-grid'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import OverlayNoRowsTable from 'components/OverlayNoRowsTable/OverlayNoRowsTable'
import { useConfUpdate } from 'hooks/useConfUpdate'
import useDeleteMission from 'hooks/useDeleteMission'
import { normalizeAccount, useUser } from 'hooks/user'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'
import { ReactComponent as SortIcon } from 'images/icons/sort_icon.svg'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { IMissionInfo } from 'utils/interfaces'
import { StyledDataGrid } from 'utils/themeGrid'

import style from './MissionList.module.css'

const MissionList = ({ missions, onMissionsUpdated }: { missions: IMissionInfo[]; onMissionsUpdated: () => void }) => {
  const { t } = useTranslation()
  const [selectedMission, setSelectedMission] = useState()
  const { mutateAsync: deleteSelectedMission } = useDeleteMission()
  const [deleteMissionDialog, setDeleteMissionDialog] = useState(false)
  const navigate = useNavigate()
  const user = useUser()
  const { mutateAsync: setConfUpdate } = useConfUpdate()

  const goToMission = (missionId: any) => {
    navigate(`${PATH.MISSION}${missionId}`)
  }

  const deleteMission = async (value?: any) => {
    await deleteSelectedMission(value.id)
    setDeleteMissionDialog(false)
    onMissionsUpdated()
  }

  const handleSortChange = (newData: GridSortModel) => {
    setConfUpdate(newData).then(res => {
      const data = normalizeAccount(res)
      user?.setState(data)
    })
  }

  const columnsDataGrid: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      disableColumnMenu: true,
      flex: 0.03,
    },
    {
      field: 'name',
      headerName: 'Name',
      disableColumnMenu: true,
      flex: 0.1,
    },
    {
      field: 'status',
      headerName: 'Mission status',
      disableColumnMenu: true,
      flex: 0.1,
    },
    {
      field: 'launchDate',
      headerName: 'Launch date',
      disableColumnMenu: true,
      flex: 0.1,
      type: 'dateTime',
      valueGetter: params => new Date(params.row.createdAt),
      renderCell: params => moment(params.row.createdAt).format('DD.MM.YYYY hh:mm'),
    },
    {
      field: 'plannedCoverage',
      headerName: 'Planned coverage, %',
      disableColumnMenu: true,
      flex: 0.1,
      valueGetter: params => params.row.plannedCoverage,
    },
    {
      field: 'actualCoverage',
      headerName: 'Actual coverage, %',
      sortable: true,
      disableColumnMenu: true,
      flex: 0.08,
      valueGetter: params => params.row.actualCoverage,
    },
    {
      field: 'timeLeft',
      headerName: 'Time left, s',
      flex: 0.05,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: params => params.row.timeLeft,
    },
    {
      field: 'missionType',
      headerName: 'Mission type',
      flex: 0.05,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: params => params.row.missionType,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.01,
      renderCell: params => {
        return (
          <div className={style.disableBorder}>
            <ActionsMenu>
              <ActionsMenuItem
                onClick={() => {
                  setSelectedMission(params.row)
                  setDeleteMissionDialog(true)
                }}
                data-test-id={getTestId('remove-zone-list')}
              >
                <RemoveSvg className={style.menuItemIcon} />
                <span data-test-id={getTestId('delete-title')}>{t('common.delete')}</span>
              </ActionsMenuItem>
            </ActionsMenu>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <StyledDataGrid
        rows={missions}
        columns={columnsDataGrid}
        showColumnVerticalBorder={false}
        showCellVerticalBorder={false}
        disableRowSelectionOnClick={true}
        columnHeaderHeight={45}
        initialState={{
          sorting: {
            sortModel: user?.state.confMission,
          },
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        slots={{
          columnSortedAscendingIcon: SortIcon,
          columnSortedDescendingIcon: SortIcon,
          noRowsOverlay: () => <OverlayNoRowsTable />,
        }}
        sx={{
          cursor: 'pointer',
          boxShadow: 0,
          border: 0,
          fontStyle: 'normal',
        }}
        onCellClick={params => {
          goToMission(params.id)
        }}
        onSortModelChange={debounce(newData => handleSortChange(newData), 1000)}
      />
      <ConfirmDialog
        title={t('robots.delete_question')}
        isOpen={deleteMissionDialog}
        onClose={() => setDeleteMissionDialog(false)}
        onConfirmClick={() => deleteMission(selectedMission)}
        confirmText={t('common.delete')}
        danger={true}
      />
    </>
  )
}

export default MissionList
