/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CleaningRoutes } from '../models/CleaningRoutes';
import type { CleaningZone } from '../models/CleaningZone';
import type { CleaningZoneRead } from '../models/CleaningZoneRead';
import type { CleaningZoneRequest } from '../models/CleaningZoneRequest';
import type { CleaningZonesToMap } from '../models/CleaningZonesToMap';
import type { GeoTiffLayer } from '../models/GeoTiffLayer';
import type { GeoTiffLayerRequest } from '../models/GeoTiffLayerRequest';
import type { GeoTiffLayerUploadFileRequest } from '../models/GeoTiffLayerUploadFileRequest';
import type { Maintenance } from '../models/Maintenance';
import type { Mission } from '../models/Mission';
import type { MissionGeoTiffLayers } from '../models/MissionGeoTiffLayers';
import type { MissionGeoTiffLayersRequest } from '../models/MissionGeoTiffLayersRequest';
import type { MissionHistory } from '../models/MissionHistory';
import type { MissionJob } from '../models/MissionJob';
import type { MissionJobRead } from '../models/MissionJobRead';
import type { MissionJobRequest } from '../models/MissionJobRequest';
import type { MissionLayerUploadFileRequest } from '../models/MissionLayerUploadFileRequest';
import type { MissionMapLayer } from '../models/MissionMapLayer';
import type { MissionMapLayerRead } from '../models/MissionMapLayerRead';
import type { MissionMapLayerRequest } from '../models/MissionMapLayerRequest';
import type { MissionPointTransferRoute } from '../models/MissionPointTransferRoute';
import type { MissionPointTransferRouteRead } from '../models/MissionPointTransferRouteRead';
import type { MissionPointTransferRouteRequest } from '../models/MissionPointTransferRouteRequest';
import type { MissionRead } from '../models/MissionRead';
import type { MissionRequest } from '../models/MissionRequest';
import type { MissionStatus } from '../models/MissionStatus';
import type { MissionXyzLayer } from '../models/MissionXyzLayer';
import type { MissionXyzLayerRead } from '../models/MissionXyzLayerRead';
import type { MissionXyzLayerRequest } from '../models/MissionXyzLayerRequest';
import type { ObjectOfInterest } from '../models/ObjectOfInterest';
import type { PaginatedGeoTiffLayerList } from '../models/PaginatedGeoTiffLayerList';
import type { PaginatedMaintenanceList } from '../models/PaginatedMaintenanceList';
import type { PaginatedMissionHistoryList } from '../models/PaginatedMissionHistoryList';
import type { PaginatedMissionJobReadList } from '../models/PaginatedMissionJobReadList';
import type { PaginatedMissionMapLayerReadList } from '../models/PaginatedMissionMapLayerReadList';
import type { PaginatedMissionPointTransferRouteReadList } from '../models/PaginatedMissionPointTransferRouteReadList';
import type { PaginatedMissionReadListList } from '../models/PaginatedMissionReadListList';
import type { PaginatedMissionXyzLayerReadList } from '../models/PaginatedMissionXyzLayerReadList';
import type { PaginatedObjectOfInterestList } from '../models/PaginatedObjectOfInterestList';
import type { PaginatedRestrictZoneReadList } from '../models/PaginatedRestrictZoneReadList';
import type { PaginatedRobotListList } from '../models/PaginatedRobotListList';
import type { PaginatedTransferRouteReadList } from '../models/PaginatedTransferRouteReadList';
import type { PaginatedUnitReadList } from '../models/PaginatedUnitReadList';
import type { PatchedCleaningZoneRequest } from '../models/PatchedCleaningZoneRequest';
import type { PatchedGeoTiffLayerRequest } from '../models/PatchedGeoTiffLayerRequest';
import type { PatchedMaintenanceRequest } from '../models/PatchedMaintenanceRequest';
import type { PatchedMissionMapLayerRequest } from '../models/PatchedMissionMapLayerRequest';
import type { PatchedMissionPointTransferRouteRequest } from '../models/PatchedMissionPointTransferRouteRequest';
import type { PatchedMissionRequest } from '../models/PatchedMissionRequest';
import type { PatchedMissionXyzLayerRequest } from '../models/PatchedMissionXyzLayerRequest';
import type { PatchedRestrictZoneRequest } from '../models/PatchedRestrictZoneRequest';
import type { PatchedRobotRequest } from '../models/PatchedRobotRequest';
import type { PatchedTransferRouteRequest } from '../models/PatchedTransferRouteRequest';
import type { PatchedUnitRequest } from '../models/PatchedUnitRequest';
import type { RestrictZone } from '../models/RestrictZone';
import type { RestrictZoneRead } from '../models/RestrictZoneRead';
import type { RestrictZoneRequest } from '../models/RestrictZoneRequest';
import type { Robot } from '../models/Robot';
import type { RobotRead } from '../models/RobotRead';
import type { RobotRequest } from '../models/RobotRequest';
import type { RobotsInUnitRequest } from '../models/RobotsInUnitRequest';
import type { ShpFileRequest } from '../models/ShpFileRequest';
import type { TransferRoute } from '../models/TransferRoute';
import type { TransferRouteRead } from '../models/TransferRouteRead';
import type { TransferRouteRequest } from '../models/TransferRouteRequest';
import type { Unit } from '../models/Unit';
import type { UnitRead } from '../models/UnitRead';
import type { UnitRequest } from '../models/UnitRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MainService {

    /**
     * @param inBbox Specify a bounding box as filter: in_bbox=min_lon,min_lat,max_lon,max_lat
     * @param ordering Which field to use when ordering the results.
     * @param search A search term.
     * @returns CleaningRoutes
     * @throws ApiError
     */
    public static mainCleaningRoutesLayerList(
        inBbox?: string,
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        search?: string,
    ): CancelablePromise<Array<CleaningRoutes>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/cleaning-routes-layer/',
            query: {
                'in_bbox': inBbox,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this cleaning zone.
     * @returns CleaningRoutes
     * @throws ApiError
     */
    public static mainCleaningRoutesLayerRetrieve(
        id: number,
    ): CancelablePromise<CleaningRoutes> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/cleaning-routes-layer/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param missionId
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param search A search term.
     * @returns PaginatedMissionReadListList
     * @throws ApiError
     */
    public static mainCleaningZoneList(
        missionId?: Array<number>,
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        page?: number,
        pageSize?: number,
        search?: string,
    ): CancelablePromise<PaginatedMissionReadListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/cleaning-zone/',
            query: {
                'mission_id': missionId,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @param requestBody
     * @returns CleaningZone
     * @throws ApiError
     */
    public static mainCleaningZoneCreate(
        requestBody: CleaningZoneRequest,
    ): CancelablePromise<CleaningZone> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/cleaning-zone/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param inBbox Specify a bounding box as filter: in_bbox=min_lon,min_lat,max_lon,max_lat
     * @param ordering Which field to use when ordering the results.
     * @param search A search term.
     * @returns CleaningZonesToMap
     * @throws ApiError
     */
    public static mainCleaningZoneLayerList(
        inBbox?: string,
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        search?: string,
    ): CancelablePromise<Array<CleaningZonesToMap>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/cleaning-zone-layer/',
            query: {
                'in_bbox': inBbox,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this cleaning zone.
     * @returns CleaningZonesToMap
     * @throws ApiError
     */
    public static mainCleaningZoneLayerRetrieve(
        id: number,
    ): CancelablePromise<CleaningZonesToMap> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/cleaning-zone-layer/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this cleaning zone.
     * @returns CleaningZoneRead
     * @throws ApiError
     */
    public static mainCleaningZoneRetrieve(
        id: number,
    ): CancelablePromise<CleaningZoneRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/cleaning-zone/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this cleaning zone.
     * @param requestBody
     * @returns CleaningZone
     * @throws ApiError
     */
    public static mainCleaningZonePartialUpdate(
        id: number,
        requestBody?: PatchedCleaningZoneRequest,
    ): CancelablePromise<CleaningZone> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/cleaning-zone/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this cleaning zone.
     * @returns void
     * @throws ApiError
     */
    public static mainCleaningZoneDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/cleaning-zone/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this cleaning zone.
     * @returns any No response body
     * @throws ApiError
     */
    public static mainCleaningZoneStartCalculationCreate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/cleaning-zone/{id}/start-calculation/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param q Query parameter for geocoding
     * @returns any No response body
     * @throws ApiError
     */
    public static mainGeocodeRetrieve(
        q: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/geocode/',
            query: {
                'q': q,
            },
        });
    }

    /**
     * @param description
     * @param inBbox Specify a bounding box as filter: in_bbox=min_lon,min_lat,max_lon,max_lat
     * @param name
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns PaginatedGeoTiffLayerList
     * @throws ApiError
     */
    public static mainGeotiffLayerList(
        description?: string,
        inBbox?: string,
        name?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PaginatedGeoTiffLayerList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/geotiff-layer/',
            query: {
                'description': description,
                'in_bbox': inBbox,
                'name': name,
                'page': page,
                'page_size': pageSize,
            },
        });
    }

    /**
     * @param requestBody
     * @returns GeoTiffLayer
     * @throws ApiError
     */
    public static mainGeotiffLayerCreate(
        requestBody?: GeoTiffLayerRequest,
    ): CancelablePromise<GeoTiffLayer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/geotiff-layer/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this GeoTIFF layer.
     * @returns GeoTiffLayer
     * @throws ApiError
     */
    public static mainGeotiffLayerRetrieve(
        id: number,
    ): CancelablePromise<GeoTiffLayer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/geotiff-layer/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this GeoTIFF layer.
     * @param requestBody
     * @returns GeoTiffLayer
     * @throws ApiError
     */
    public static mainGeotiffLayerUpdate(
        id: number,
        requestBody?: GeoTiffLayerRequest,
    ): CancelablePromise<GeoTiffLayer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/main/geotiff-layer/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this GeoTIFF layer.
     * @param requestBody
     * @returns GeoTiffLayer
     * @throws ApiError
     */
    public static mainGeotiffLayerPartialUpdate(
        id: number,
        requestBody?: PatchedGeoTiffLayerRequest,
    ): CancelablePromise<GeoTiffLayer> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/geotiff-layer/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this GeoTIFF layer.
     * @returns void
     * @throws ApiError
     */
    public static mainGeotiffLayerDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/geotiff-layer/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param robot
     * @param search A search term.
     * @param state
     * @param unit
     * @returns PaginatedMaintenanceList
     * @throws ApiError
     */
    public static mainMaintenanceList(
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        page?: number,
        pageSize?: number,
        robot?: number,
        search?: string,
        state?: 0 | 1 | 2 | 3 | 4,
        unit?: number,
    ): CancelablePromise<PaginatedMaintenanceList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/maintenance/',
            query: {
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'robot': robot,
                'search': search,
                'state': state,
                'unit': unit,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this maintenance.
     * @returns Maintenance
     * @throws ApiError
     */
    public static mainMaintenanceRetrieve(
        id: number,
    ): CancelablePromise<Maintenance> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/maintenance/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this maintenance.
     * @param requestBody
     * @returns Maintenance
     * @throws ApiError
     */
    public static mainMaintenancePartialUpdate(
        id: number,
        requestBody?: PatchedMaintenanceRequest,
    ): CancelablePromise<Maintenance> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/maintenance/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param search A search term.
     * @returns PaginatedMissionReadListList
     * @throws ApiError
     */
    public static mainMissionList(
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        page?: number,
        pageSize?: number,
        search?: string,
    ): CancelablePromise<PaginatedMissionReadListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission/',
            query: {
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Mission
     * @throws ApiError
     */
    public static mainMissionCreate(
        requestBody?: MissionRequest,
    ): CancelablePromise<Mission> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param mission
     * @param name
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param robot
     * @returns PaginatedMissionHistoryList
     * @throws ApiError
     */
    public static mainMissionHistoryList(
        mission?: number,
        name?: string,
        page?: number,
        pageSize?: number,
        robot?: number,
    ): CancelablePromise<PaginatedMissionHistoryList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission-history/',
            query: {
                'mission': mission,
                'name': name,
                'page': page,
                'page_size': pageSize,
                'robot': robot,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission history.
     * @returns MissionHistory
     * @throws ApiError
     */
    public static mainMissionHistoryRetrieve(
        id: number,
    ): CancelablePromise<MissionHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission-history/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param missionId
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param robotId
     * @param search A search term.
     * @param status
     * @returns PaginatedMissionJobReadList
     * @throws ApiError
     */
    public static mainMissionJobList(
        missionId?: Array<number>,
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        page?: number,
        pageSize?: number,
        robotId?: Array<number>,
        search?: string,
        status?: 'new' | 'scenario_calculating' | 'scenario_done' | 'scenario_error',
    ): CancelablePromise<PaginatedMissionJobReadList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission-job/',
            query: {
                'mission_id': missionId,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'robot_id': robotId,
                'search': search,
                'status': status,
            },
        });
    }

    /**
     * @param requestBody
     * @returns MissionJob
     * @throws ApiError
     */
    public static mainMissionJobCreate(
        requestBody: MissionJobRequest,
    ): CancelablePromise<MissionJob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission-job/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this mission job.
     * @returns MissionJobRead
     * @throws ApiError
     */
    public static mainMissionJobRetrieve(
        id: number,
    ): CancelablePromise<MissionJobRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission-job/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission job.
     * @returns any No response body
     * @throws ApiError
     */
    public static mainMissionJobStartCalculationCreate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission-job/{id}/start-calculation/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param missionId
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param search A search term.
     * @returns PaginatedMissionMapLayerReadList
     * @throws ApiError
     */
    public static mainMissionMapLayerList(
        missionId?: Array<number>,
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        page?: number,
        pageSize?: number,
        search?: string,
    ): CancelablePromise<PaginatedMissionMapLayerReadList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission-map-layer/',
            query: {
                'mission_id': missionId,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @param requestBody
     * @returns MissionMapLayer
     * @throws ApiError
     */
    public static mainMissionMapLayerCreate(
        requestBody: MissionMapLayerRequest,
    ): CancelablePromise<MissionMapLayer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission-map-layer/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this mission map.
     * @returns MissionMapLayerRead
     * @throws ApiError
     */
    public static mainMissionMapLayerRetrieve(
        id: number,
    ): CancelablePromise<MissionMapLayerRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission-map-layer/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission map.
     * @param requestBody
     * @returns MissionMapLayer
     * @throws ApiError
     */
    public static mainMissionMapLayerPartialUpdate(
        id: number,
        requestBody?: PatchedMissionMapLayerRequest,
    ): CancelablePromise<MissionMapLayer> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/mission-map-layer/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this mission map.
     * @returns void
     * @throws ApiError
     */
    public static mainMissionMapLayerDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/mission-map-layer/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission map.
     * @param formData
     * @returns MissionMapLayerRead
     * @throws ApiError
     */
    public static mainMissionMapLayerUploadFileCreate(
        id: number,
        formData: MissionLayerUploadFileRequest,
    ): CancelablePromise<MissionMapLayerRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission-map-layer/{id}/upload-file/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param cleaningZone
     * @param missionId
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param search A search term.
     * @returns PaginatedMissionPointTransferRouteReadList
     * @throws ApiError
     */
    public static mainMissionPointTransferRouteList(
        cleaningZone?: Array<number>,
        missionId?: Array<number>,
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        page?: number,
        pageSize?: number,
        search?: string,
    ): CancelablePromise<PaginatedMissionPointTransferRouteReadList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission-point-transfer-route/',
            query: {
                'cleaning_zone': cleaningZone,
                'mission_id': missionId,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @param requestBody
     * @returns MissionPointTransferRoute
     * @throws ApiError
     */
    public static mainMissionPointTransferRouteCreate(
        requestBody: MissionPointTransferRouteRequest,
    ): CancelablePromise<MissionPointTransferRoute> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission-point-transfer-route/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this mission point transfer route.
     * @returns MissionPointTransferRouteRead
     * @throws ApiError
     */
    public static mainMissionPointTransferRouteRetrieve(
        id: number,
    ): CancelablePromise<MissionPointTransferRouteRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission-point-transfer-route/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission point transfer route.
     * @param requestBody
     * @returns MissionPointTransferRoute
     * @throws ApiError
     */
    public static mainMissionPointTransferRoutePartialUpdate(
        id: number,
        requestBody?: PatchedMissionPointTransferRouteRequest,
    ): CancelablePromise<MissionPointTransferRoute> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/mission-point-transfer-route/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this mission point transfer route.
     * @returns void
     * @throws ApiError
     */
    public static mainMissionPointTransferRouteDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/mission-point-transfer-route/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission point transfer route.
     * @returns any No response body
     * @throws ApiError
     */
    public static mainMissionPointTransferRouteStartCalculationCreate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission-point-transfer-route/{id}/start-calculation/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param missionId
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param search A search term.
     * @returns PaginatedMissionXyzLayerReadList
     * @throws ApiError
     */
    public static mainMissionXyzLayerList(
        missionId?: Array<number>,
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at' | 'sorting' | '-sorting'>,
        page?: number,
        pageSize?: number,
        search?: string,
    ): CancelablePromise<PaginatedMissionXyzLayerReadList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission-xyz-layer/',
            query: {
                'mission_id': missionId,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @param requestBody
     * @returns MissionXyzLayer
     * @throws ApiError
     */
    public static mainMissionXyzLayerCreate(
        requestBody: MissionXyzLayerRequest,
    ): CancelablePromise<MissionXyzLayer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission-xyz-layer/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this mission XYZ layer.
     * @returns MissionXyzLayerRead
     * @throws ApiError
     */
    public static mainMissionXyzLayerRetrieve(
        id: number,
    ): CancelablePromise<MissionXyzLayerRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission-xyz-layer/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission XYZ layer.
     * @param requestBody
     * @returns MissionXyzLayer
     * @throws ApiError
     */
    public static mainMissionXyzLayerPartialUpdate(
        id: number,
        requestBody?: PatchedMissionXyzLayerRequest,
    ): CancelablePromise<MissionXyzLayer> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/mission-xyz-layer/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this mission XYZ layer.
     * @returns void
     * @throws ApiError
     */
    public static mainMissionXyzLayerDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/mission-xyz-layer/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission XYZ layer.
     * @param formData
     * @returns MissionXyzLayerRead
     * @throws ApiError
     */
    public static mainMissionXyzLayerUploadFileCreate(
        id: number,
        formData: MissionLayerUploadFileRequest,
    ): CancelablePromise<MissionXyzLayerRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission-xyz-layer/{id}/upload-file/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id A unique integer value identifying this mission.
     * @returns MissionRead
     * @throws ApiError
     */
    public static mainMissionRetrieve(
        id: number,
    ): CancelablePromise<MissionRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission.
     * @param requestBody
     * @returns Mission
     * @throws ApiError
     */
    public static mainMissionPartialUpdate(
        id: number,
        requestBody?: PatchedMissionRequest,
    ): CancelablePromise<Mission> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/mission/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this mission.
     * @returns void
     * @throws ApiError
     */
    public static mainMissionDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/mission/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission.
     * @returns binary
     * @throws ApiError
     */
    public static mainMissionDownloadShpRetrieve(
        id: number,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission/{id}/download-shp/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission.
     * @returns MissionRead
     * @throws ApiError
     */
    public static mainMissionDuplicateCreate(
        id: number,
    ): CancelablePromise<MissionRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission/{id}/duplicate/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission.
     * @param requestBody
     * @returns MissionGeoTiffLayers
     * @throws ApiError
     */
    public static mainMissionGeotiffLayersCreate(
        id: number,
        requestBody?: MissionGeoTiffLayersRequest,
    ): CancelablePromise<MissionGeoTiffLayers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission/{id}/geotiff-layers/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this mission.
     * @returns MissionStatus
     * @throws ApiError
     */
    public static mainMissionGetStatusRetrieve(
        id: number,
    ): CancelablePromise<MissionStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/mission/{id}/get-status/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Чтобы принудительно перерасчитать маршруты для всего надо дослать `?force=1`
     * @param id A unique integer value identifying this mission.
     * @returns any No response body
     * @throws ApiError
     */
    public static mainMissionStartCalculationCreate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission/{id}/start-calculation/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this mission.
     * @param formData
     * @returns GeoTiffLayer
     * @throws ApiError
     */
    public static mainMissionUploadGeotiffCreate(
        id: number,
        formData: GeoTiffLayerUploadFileRequest,
    ): CancelablePromise<GeoTiffLayer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission/{id}/upload-geotiff/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns MissionRead
     * @throws ApiError
     */
    public static mainMissionUploadShpCreate(
        formData: ShpFileRequest,
    ): CancelablePromise<MissionRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/mission/upload-shp/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param inBbox Specify a bounding box as filter: in_bbox=min_lon,min_lat,max_lon,max_lat
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param robot
     * @param search A search term.
     * @param status
     * @param type
     * @param unit
     * @returns PaginatedObjectOfInterestList
     * @throws ApiError
     */
    public static mainObjectsOfInterestList(
        inBbox?: string,
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        page?: number,
        pageSize?: number,
        robot?: number,
        search?: string,
        status?: 0,
        type?: 0 | 1 | 2 | 3,
        unit?: number,
    ): CancelablePromise<PaginatedObjectOfInterestList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/objects-of-interest/',
            query: {
                'in_bbox': inBbox,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'robot': robot,
                'search': search,
                'status': status,
                'type': type,
                'unit': unit,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this ObjectOfInterest layer.
     * @returns ObjectOfInterest
     * @throws ApiError
     */
    public static mainObjectsOfInterestRetrieve(
        id: number,
    ): CancelablePromise<ObjectOfInterest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/objects-of-interest/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param missionId
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param search A search term.
     * @returns PaginatedRestrictZoneReadList
     * @throws ApiError
     */
    public static mainRestrictZoneList(
        missionId?: Array<number>,
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        page?: number,
        pageSize?: number,
        search?: string,
    ): CancelablePromise<PaginatedRestrictZoneReadList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/restrict-zone/',
            query: {
                'mission_id': missionId,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @param requestBody
     * @returns RestrictZone
     * @throws ApiError
     */
    public static mainRestrictZoneCreate(
        requestBody: RestrictZoneRequest,
    ): CancelablePromise<RestrictZone> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/restrict-zone/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this restrict zone.
     * @returns RestrictZoneRead
     * @throws ApiError
     */
    public static mainRestrictZoneRetrieve(
        id: number,
    ): CancelablePromise<RestrictZoneRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/restrict-zone/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this restrict zone.
     * @param requestBody
     * @returns RestrictZone
     * @throws ApiError
     */
    public static mainRestrictZonePartialUpdate(
        id: number,
        requestBody?: PatchedRestrictZoneRequest,
    ): CancelablePromise<RestrictZone> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/restrict-zone/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this restrict zone.
     * @returns void
     * @throws ApiError
     */
    public static mainRestrictZoneDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/restrict-zone/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param noUnit
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param search A search term.
     * @param unitId
     * @returns PaginatedRobotListList
     * @throws ApiError
     */
    public static mainRobotList(
        noUnit?: boolean,
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        page?: number,
        pageSize?: number,
        search?: string,
        unitId?: number,
    ): CancelablePromise<PaginatedRobotListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/robot/',
            query: {
                'no_unit': noUnit,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
                'unit_id': unitId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Robot
     * @throws ApiError
     */
    public static mainRobotCreate(
        requestBody: RobotRequest,
    ): CancelablePromise<Robot> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/robot/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this robot.
     * @returns RobotRead
     * @throws ApiError
     */
    public static mainRobotRetrieve(
        id: number,
    ): CancelablePromise<RobotRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/robot/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this robot.
     * @param requestBody
     * @returns Robot
     * @throws ApiError
     */
    public static mainRobotPartialUpdate(
        id: number,
        requestBody?: PatchedRobotRequest,
    ): CancelablePromise<Robot> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/robot/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this robot.
     * @returns void
     * @throws ApiError
     */
    public static mainRobotDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/robot/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param cmd
     * @param id A unique integer value identifying this robot.
     * @returns any No response body
     * @throws ApiError
     */
    public static mainRobotExecCommandCreate(
        cmd: string,
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/robot/{id}/exec-command/{cmd}/',
            path: {
                'cmd': cmd,
                'id': id,
            },
        });
    }

    /**
     * @param cmd
     * @param id A unique integer value identifying this robot.
     * @param value
     * @returns any No response body
     * @throws ApiError
     */
    public static mainRobotExecEquipmentCommandCreate(
        cmd: string,
        id: number,
        value: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/robot/{id}/exec-equipment-command/{cmd}/{value}/',
            path: {
                'cmd': cmd,
                'id': id,
                'value': value,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this robot.
     * @param mode
     * @returns any No response body
     * @throws ApiError
     */
    public static mainRobotSwitchModeCreate(
        id: number,
        mode: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/robot/{id}/switch-mode/{mode}/',
            path: {
                'id': id,
                'mode': mode,
            },
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static mainTestRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/test/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static mainTest2Retrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/test2/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static mainTest3Retrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/test3/',
        });
    }

    /**
     * @param cleaningZoneFromId
     * @param cleaningZoneToId
     * @param missionId
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param search A search term.
     * @returns PaginatedTransferRouteReadList
     * @throws ApiError
     */
    public static mainTransferRouteList(
        cleaningZoneFromId?: Array<number>,
        cleaningZoneToId?: Array<number>,
        missionId?: Array<string>,
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        page?: number,
        pageSize?: number,
        search?: string,
    ): CancelablePromise<PaginatedTransferRouteReadList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/transfer-route/',
            query: {
                'cleaning_zone_from_id': cleaningZoneFromId,
                'cleaning_zone_to_id': cleaningZoneToId,
                'mission_id': missionId,
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @param requestBody
     * @returns TransferRoute
     * @throws ApiError
     */
    public static mainTransferRouteCreate(
        requestBody?: TransferRouteRequest,
    ): CancelablePromise<TransferRoute> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/transfer-route/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this transfer route.
     * @returns TransferRouteRead
     * @throws ApiError
     */
    public static mainTransferRouteRetrieve(
        id: number,
    ): CancelablePromise<TransferRouteRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/transfer-route/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this transfer route.
     * @param requestBody
     * @returns TransferRoute
     * @throws ApiError
     */
    public static mainTransferRoutePartialUpdate(
        id: number,
        requestBody?: PatchedTransferRouteRequest,
    ): CancelablePromise<TransferRoute> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/transfer-route/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this transfer route.
     * @returns void
     * @throws ApiError
     */
    public static mainTransferRouteDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/transfer-route/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this transfer route.
     * @returns any No response body
     * @throws ApiError
     */
    public static mainTransferRouteStartCalculationCreate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/transfer-route/{id}/start-calculation/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param search A search term.
     * @returns PaginatedUnitReadList
     * @throws ApiError
     */
    public static mainUnitList(
        ordering?: Array<'id' | '-id' | 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at'>,
        page?: number,
        pageSize?: number,
        search?: string,
    ): CancelablePromise<PaginatedUnitReadList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/unit/',
            query: {
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Unit
     * @throws ApiError
     */
    public static mainUnitCreate(
        requestBody?: UnitRequest,
    ): CancelablePromise<Unit> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/unit/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this unit.
     * @returns UnitRead
     * @throws ApiError
     */
    public static mainUnitRetrieve(
        id: number,
    ): CancelablePromise<UnitRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/main/unit/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this unit.
     * @param requestBody
     * @returns Unit
     * @throws ApiError
     */
    public static mainUnitPartialUpdate(
        id: number,
        requestBody?: PatchedUnitRequest,
    ): CancelablePromise<Unit> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/main/unit/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this unit.
     * @returns void
     * @throws ApiError
     */
    public static mainUnitDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/main/unit/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this unit.
     * @param requestBody
     * @returns UnitRead
     * @throws ApiError
     */
    public static mainUnitRobotsCreate(
        id: number,
        requestBody: RobotsInUnitRequest,
    ): CancelablePromise<UnitRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/main/unit/{id}/robots/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
