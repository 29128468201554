import {
  Box,
  Button, // CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { ReactComponent as QuestionMarkIcon } from 'images/icons/faq.svg'

interface Props {
  missionName?: string
}

const UnitMissionControl = ({ missionName }: Props) => {
  return (
    <Box bgcolor="info.main" display="flex" flexDirection="column" height="100%" width="100%" borderRadius={2}>
      <Stack direction="row" justifyContent="space-between" padding={1} paddingBottom={'21px'}>
        <Typography variant="caption" color="#9e9e9e">
          Current mission
          {/* {true && <CircularProgress color="secondary" size={14} />} */}
        </Typography>
        <Tooltip title="¯\_(ツ)_/¯" placement="top">
          <IconButton size="small">
            <QuestionMarkIcon />
          </IconButton>
        </Tooltip>
      </Stack>

      <Grid container spacing={1} padding={1} paddingTop={0}>
        <Grid item xs={12} paddingBottom={'19px'}>
          <Stack direction="column" alignItems="center" width="100%">
            {missionName || 'Mission not selected'}
          </Stack>
        </Grid>
        {/* <Grid item xs={6}>
          <Button
            disabled={
              telemetry?.activeMode === 2 ||
              uploadingState === MISSION_JOB_STATUSES.CALCULATING ||
              selectMissionLoading ||
              uploadMissionLoading
            }
            onClick={() => setShowModal(true)}
            variant="outlined"
            color="secondary"
            fullWidth
          >
            {uploadingState === MISSION_JOB_STATUSES.CALCULATING || selectMissionLoading || uploadMissionLoading ? (
              <>
                Loading... <CircularProgress size={15} color="secondary" />
              </>
            ) : (
              'Select mission'
            )}
          </Button>
        </Grid> */}

        <Grid item xs={6}>
          <Button
            onClick={() => {}}
            disabled={false}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ ':disabled': { bgcolor: '#9ADAA3', color: '#fff' }, padding: '11px 16px;' }}
          >
            Start
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => {}}
            disabled={true}
            variant="contained"
            color="warning"
            fullWidth
            sx={{ ':disabled': { bgcolor: '#E19E9F', color: '#fff' }, padding: '11px 16px;' }}
          >
            Stop
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UnitMissionControl
