import { Box } from '@mui/material'
import { GridCellParams, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid'
import OverlayNoRowsTable from 'components/OverlayNoRowsTable/OverlayNoRowsTable'
import { ReactComponent as AttenSvg } from 'images/icons/attention.svg'
import { ReactComponent as SortIcon } from 'images/icons/sort_icon.svg'
import { Fragment } from 'react'
import { LuParkingCircle } from 'react-icons/lu'
import { MdOutlineBattery0Bar, MdOutlineLocalCarWash } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { SLOT_STATUSES } from 'utils/constants'
import { IUnit, StatusUtils } from 'utils/interfaces'
import { StyledDataGrid } from 'utils/themeGrid'

import style from './UnitList.module.css'

interface UnitsProps {
  units: IUnit[]
}

const unitStatus = {
  0: 'Not set',
  1: 'In progress',
}

const UnitList: React.FC<UnitsProps> = ({ units }) => {
  const navigate = useNavigate()

  const statusUtils: StatusUtils = {
    0: <LuParkingCircle />,
    1: <MdOutlineLocalCarWash />,
    2: <MdOutlineBattery0Bar />,
  }

  const statusIcon = (status: SLOT_STATUSES[]): JSX.Element[] => {
    return status.map(s => <Fragment key={s}>{statusUtils[s]}</Fragment>)
  }

  const columnsDataGrid: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Unit ID',
      disableColumnMenu: true,
      flex: 0.04,
    },
    {
      field: 'name',
      headerName: 'Name',
      disableColumnMenu: true,
      flex: 0.08,
      renderCell: (params: GridCellParams<IUnit>) => {
        const checkIcon = !params.row.slotsStatuses || params.row.slotsStatuses?.length < 1
        return (
          <div className={style.iconBox}>
            {params.value as string}
            {checkIcon && (
              <div className={style.iconFlex}>
                <AttenSvg />
              </div>
            )}
          </div>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Unit status',
      disableColumnMenu: true,
      flex: 0.07,
      valueGetter: ({ row }: GridValueGetterParams<IUnit>) => (row.status !== undefined ? unitStatus[row.status] : ''),
    },
    {
      field: 'boxingStatus',
      headerName: 'Boxing Status',
      disableColumnMenu: true,
      flex: 0.07,
      renderCell: ({ row }: GridRenderCellParams<IUnit>) => (
        <div key={row.id} className={style.iconBox}>
          {<div className={style.iconFlex}>{row.slotsStatuses ? statusIcon(row.slotsStatuses) : ''}</div>}
        </div>
      ),
    },
    {
      field: 'missionName',
      headerName: 'Mission',
      sortable: true,
      disableColumnMenu: true,
      flex: 0.06,
    },
    {
      field: 'robotsCount',
      headerName: 'Robots',
      flex: 0.04,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: 'missionCompletion',
      headerName: 'Completed, %',
      flex: 0.07,
      sortable: true,
      disableColumnMenu: true,
    },
  ]
  return (
    <Box>
      <StyledDataGrid
        rows={units}
        columns={columnsDataGrid}
        showColumnVerticalBorder={false}
        showCellVerticalBorder={false}
        disableRowSelectionOnClick={true}
        autoHeight={true}
        hideFooter={true}
        columnHeaderHeight={35}
        slots={{
          columnSortedAscendingIcon: SortIcon,
          columnSortedDescendingIcon: SortIcon,
          noRowsOverlay: () => <OverlayNoRowsTable />,
        }}
        sx={{
          cursor: 'pointer',
          boxShadow: 0,
          border: 0,
          fontStyle: 'normal',
        }}
        onCellClick={({ row }: GridCellParams<IUnit>) => navigate(`/units/${row.id}`)}
      />
    </Box>
  )
}

export default UnitList
