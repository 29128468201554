// src/components/CustomControl.js
import Control from 'ol/control/Control'
import { useEffect, useRef } from 'react'
import useMapStore from 'store/map'

import styles from './ZoomToRobot.module.css'

interface Props {
  onClick: () => void
}

const CustomControl = ({ onClick }: Props) => {
  const map = useMapStore(s => s.map)

  const controlRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!map || !controlRef) return

    const zoomToRobotControl = new Control({
      element: controlRef.current as HTMLInputElement,
    })

    map.addControl(zoomToRobotControl)

    return () => {
      map?.removeControl(zoomToRobotControl)
    }
  }, [map, controlRef])

  return (
    <div
      ref={controlRef}
      className="ol-unselectable ol-control ol-control-custom"
      style={{ bottom: '296px', right: '.5em' }}
    >
      <button onClick={onClick} className={styles.icon}></button>
    </div>
  )
}

export default CustomControl
