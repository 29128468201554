import { Button } from 'components/Button/Button'
import UnitMap from 'components/Map/UnitMap'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import { Point } from 'geojson'
import { useTranslation } from 'react-i18next'
import { IUnit } from 'utils/interfaces'

import style from '../AddRobotsModal.module.css'

const UnitView = ({
  unit,
  isNew,
  onNameChanged,
  onPointChanged,
  onUnitDeleted,
}: {
  unit: IUnit
  isNew: boolean
  onNameChanged: (name: string) => void
  onPointChanged: (point: Point) => void
  onUnitDeleted: () => void
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={style.rowAlignEnd}>
        <TextFieldDescribed
          className={style.textfield}
          description={'Maintance name'}
          value={unit.name}
          placeholder={t('robots.robot_name_placeholder')}
          onChange={e => onNameChanged(e.target.value)}
        />
        <Button.Outlined className={style.buttonDelete} onClick={onUnitDeleted} disabled={isNew}>
          Delete Unit
        </Button.Outlined>
      </div>
      <div className={style.map}>
        <UnitMap units={unit.point ? [unit.point] : []} onPointChanged={onPointChanged} />
      </div>
    </>
  )
}

export default UnitView
