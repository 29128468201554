import { useMutation } from '@tanstack/react-query'
import { RobotApi } from 'api/RobotApi'
import { ROBOT_EQUIPMENT_COMMAND } from 'utils/constants'

interface SendData {
  id: number
  command: ROBOT_EQUIPMENT_COMMAND
  value: string
}
const useToggleRobotLock = () =>
  useMutation<void, Error, SendData>({
    mutationFn: RobotApi.execEquipmentCommand,
  })

export default useToggleRobotLock
