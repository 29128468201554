import { Box, Button } from '@mui/material'
import { MainApi } from 'api/MainApi'
import { useCallback } from 'react'
import useMissionStore from 'store/mission'
import { MISSION_CALCULATING_STATUSES } from 'utils/constants'
import { useShallow } from 'zustand/react/shallow'

const CalculateZoneButton = () => {
  const { setCalculatingStatus, isReadyForCalculation, missionInfo } = useMissionStore(
    useShallow(state => ({
      setCalculatingStatus: state.setCalculatingStatus,
      isReadyForCalculation: state.isReadyForCalculation,
      missionInfo: state.missionInfo,
    }))
  )
  const onCalculateRouteClicked = useCallback(async () => {
    if (!isReadyForCalculation) {
      return
    }
    try {
      setCalculatingStatus(MISSION_CALCULATING_STATUSES.CALCULATING)
      await MainApi.startCalculatingRoutes(missionInfo?.id || -1)
    } catch (error) {
      setCalculatingStatus(MISSION_CALCULATING_STATUSES.ERROR)
    }
  }, [isReadyForCalculation, setCalculatingStatus, missionInfo?.id])

  return (
    <Box display="flex" width="100%">
      <Button
        disabled={!isReadyForCalculation}
        onClick={onCalculateRouteClicked}
        variant="contained"
        fullWidth
        sx={{ margin: 2 }}
      >
        Calculate mission
      </Button>
    </Box>
  )
}

export default CalculateZoneButton
