import { GridSortModel } from '@mui/x-data-grid'
import { AccService, WhoAmI } from 'api/schema/index'

export const AccountApi = {
  login: async (email: string, password: string): Promise<WhoAmI> => {
    const user = await AccService.accLoginCreate({ email, password })
    return user
  },
  fetchUser: async (): Promise<WhoAmI> => {
    const user = await AccService.accWhoamiRetrieve()
    return user
  },
  logout: async () => {
    await AccService.accLogoutCreate()
  },
  restorePassword: async (email: string): Promise<void> => {
    await AccService.accPasswordResetCreate({ email })
  },
  setNewPassword: async (token: string, password: string): Promise<void> => {
    await AccService.accPasswordResetConfirmCreate({ token, password })
  },
  setNewSort: async (data: GridSortModel): Promise<WhoAmI> => {
    const conf = data.reduce((acc, { field, sort }) => ({ ...acc, [field]: sort }), {})
    return AccService.accWhoamiPartialUpdate({ conf })
  },
}
