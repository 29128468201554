import { useMutation } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { IPoint, PropsPoints } from 'utils/interfaces'

const useChangeFinishPoint = () =>
  useMutation<IPoint | undefined, Error, PropsPoints>({
    mutationFn: MainApi.changeMissionFinishPoint,
  })

export default useChangeFinishPoint
