import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { MainApi } from 'api/MainApi'
import { ApiError } from 'api/schema'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import { ReactComponent as CancelSvg } from 'images/icons/cancel.svg'
import { ReactComponent as SaveSvg } from 'images/icons/save.svg'
import isEqual from 'lodash.isequal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMissionStore from 'store/mission'
import { DEFAULT_VIZUALIZATION_SETTINGS, MISSION_MAP_DRAWING_MODES, MISSION_PAGE_MODES } from 'utils/constants'
import { useShallow } from 'zustand/react/shallow'

import ExportMission from './ExportMission/ExportMission'
import ScheduleSettings from './ScheduleSettings/ScheduleSettings'
import VisualSettings from './VisualSettings/VisualSettings'

import style from './MissionSettingsModal.module.css'

interface IDialogProps {
  // isOpen: boolean
  missionId: number
}

const MissionSettingsModal = ({ missionId }: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()
  const {
    setMode,
    setMapMode,
    setMissionName,
    setVisualSettings,
    visualSettings,
    setTempVisualSettings,
    tempVisualSettings,
    missionInfo,
  } = useMissionStore(
    useShallow(state => ({
      setMode: state.setMode,
      setMapMode: state.setMapMode,
      setMissionName: state.setMissionName,
      setVisualSettings: state.setVisualSettings,
      setTempVisualSettings: state.setTempVisualSettings,
      visualSettings: state.visualSettings,
      tempVisualSettings: state.tempVisualSettings,
      missionInfo: state.missionInfo,
    }))
  )
  const [tempMissionName, setTempMissionName] = useState(missionInfo?.name || '')
  const [hasNameError, setNameHasError] = useState(false)
  const [actionInProgress, setActionInProgress] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const onMissionNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setNameHasError(true)
    } else {
      setNameHasError(false)
      setTempMissionName(e.target.value)
    }
  }

  const onCancelClicked = () => {
    setErrorMessage('')
    if (!actionInProgress) {
      setMode(MISSION_PAGE_MODES.VIEW_ZONES)
      setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
      if (!isEqual(tempVisualSettings, visualSettings)) {
        setTempVisualSettings({ ...visualSettings })
      }
    }
  }

  const onSaveClicked = async () => {
    setActionInProgress(true)

    try {
      if (tempMissionName !== missionInfo?.name) {
        const mission = await MainApi.changeMissionName(missionId, tempMissionName)
        setMissionName(mission.name || '')
        setErrorMessage('')
      }

      if (!isEqual(tempVisualSettings, visualSettings)) {
        const mission = await MainApi.changeMissionVisualSettings(missionId, tempVisualSettings)
        setVisualSettings({ ...DEFAULT_VIZUALIZATION_SETTINGS, ...mission.visualization_settings })
        setErrorMessage('')
      }
    } catch (error) {
      if (error instanceof ApiError) {
        setErrorMessage(error.body.details.name[0])
      } else {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }

    setActionInProgress(false)
  }

  const isSaveButtonDisabled =
    (tempMissionName === missionInfo?.name || tempMissionName === '') && isEqual(visualSettings, tempVisualSettings)

  return (
    <div className={style.modal}>
      <div className={style.head}>
        <Typography variant="h5" className={style.title}>
          {t('mission_settings.title')}
        </Typography>
      </div>
      <div className={style.content}>
        <TextFieldDescribed
          className={style.textfield}
          description={t('mission_settings.name_description')}
          error={t('mission_settings.name_error')}
          placeholder={t('mission_settings.name_placeholder')}
          hasError={hasNameError}
          defaultValue={missionInfo?.name}
          onChange={onMissionNameChanged}
        />
        <div className={style.contentColumns}>
          <TextFieldDescribed
            sxInput={{ width: '120px' }}
            description={t('mission_settings.buffer_description')}
            rightLabel={t('mission_settings.meters')}
            placeholder={t('mission_settings.name_placeholder')}
            defaultValue="25"
            onChange={() => {}}
          />
          <TextFieldDescribed
            sxInput={{ width: '120px' }}
            className={style.settingsMeters}
            description={t('mission_settings.start_point_max_distance')}
            rightLabel={t('mission_settings.meters')}
            placeholder={t('mission_settings.name_placeholder')}
            defaultValue="33"
            onChange={() => {}}
          />
        </div>
        <VisualSettings />
        <ScheduleSettings />
        <ExportMission missionId={missionId} missionName={missionInfo?.name} />
      </div>
      {errorMessage && (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <div className={style.errorMessage}>{errorMessage}</div>
        </Stack>
      )}
      <div className={style.footer}>
        <Button
          onClick={onCancelClicked}
          disabled={actionInProgress}
          color="error"
          variant="contained"
          fullWidth
          endIcon={<CancelSvg />}
        >
          {t('mission_settings.label_cancel')}
        </Button>
        <Button
          disabled={isSaveButtonDisabled || actionInProgress}
          onClick={onSaveClicked}
          fullWidth
          variant="contained"
          color="primary"
          endIcon={actionInProgress ? <CircularProgress size={16} color="secondary" /> : <SaveSvg />}
        >
          {t('mission_settings.label_save')}
        </Button>
      </div>
    </div>
  )
}

export default MissionSettingsModal
