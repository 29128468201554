import { Box, Divider, Stack, Typography } from '@mui/material'
import RobotDataBlock from 'components/Robot/RobotDataBlock'
import useUnitTelemetryStore from 'store/unitTelemetry'

import UnitMissionControl from './UnitMissionControl'
import UnitRobotsList from './UnitRobotsList'

const UnitDetails = () => {
  const telemetry = useUnitTelemetryStore(s => s.telemetry)

  return (
    <Box
      height="100%"
      width="100%"
      display="grid"
      gridTemplateRows={{ xs: '370px 20px 75px 20px', sm: '165px 20px 75px 20px' }}
      gridTemplateColumns="100%"
    >
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} minHeight={{ sm: '110px' }}>
        <Box width={{ xs: '100%', sm: '50%' }}>
          <UnitMissionControl missionName={''} />
        </Box>
        <Box width={{ xs: '100%', sm: '25%' }}>
          <RobotDataBlock header="Mission completion, %" value={2} tooltip="" />
        </Box>
        <Box width={{ xs: '100%', sm: '25%' }}>
          <RobotDataBlock header="Time left" value="0" tooltip="" />
        </Box>
      </Stack>
      <Box paddingY={1}>
        <Divider />
      </Box>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          bgcolor={'info.main'}
          p={2}
          borderRadius={4}
          maxHeight="45px"
        >
          <Box mr={1}>
            <Typography fontSize={{ xs: 12, sm: 14 }} mr={1}>
              Boxes:
            </Typography>
            <Typography fontSize={{ xs: 12, sm: 14 }}>{telemetry.telemetry?.slots?.length ?? '-'}</Typography>
          </Box>
          <Box mr={1}>
            <Typography fontSize={{ xs: 12, sm: 14 }} mr={1}>
              Battery count:
            </Typography>
            <Typography fontSize={{ xs: 12, sm: 14 }}>{telemetry.telemetry?.batteryCount ?? '-'}</Typography>
          </Box>
          <Box mr={1}>
            <Typography fontSize={{ xs: 12, sm: 14 }} mr={1}>
              Power consumption:
            </Typography>
            <Typography fontSize={{ xs: 12, sm: 14 }}>
              {telemetry.telemetry?.powerConsumption?.toFixed(2) ?? '-'}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={{ xs: 12, sm: 14 }} mr={1}>
              Water reserve:
            </Typography>
            <Typography fontSize={{ xs: 12, sm: 14 }}>{telemetry.telemetry?.waterReserve?.toFixed() ?? '-'}</Typography>
          </Box>
        </Stack>
      </Box>
      <Box paddingY={1}>
        <Divider />
      </Box>
      <Box>
        <UnitRobotsList />
      </Box>
    </Box>
  )
}

export default UnitDetails
