import { Menu, MenuItem, Stack } from '@mui/material'
import { Button } from 'components/Button/Button'
import Title from 'components/Title/Title'
import { useState } from 'react'

import style from './ListHeader.module.css'

interface IMenuItem {
  text: string
  onClick: () => void
  id: string | number
}

interface ListHeaderProps {
  onClickHeader?: () => void
  loading?: boolean
  buttonStyled?: any
  titleHeader?: string
  buttonName?: string
  menu?: IMenuItem[]
}

export default function ListHeader({
  onClickHeader,
  loading,
  buttonStyled,
  titleHeader,
  buttonName,
  menu = [],
}: ListHeaderProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (func?: () => void) => {
    if (func) {
      func()
    }

    setAnchorEl(null)
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      marginBottom={{ xs: 0, sm: 0, lg: 5 }}
      marginTop={{ xs: 2, sm: 2, lg: 0 }}
    >
      <Title className={style.title} text={titleHeader} />
      {buttonName && (
        <div>
          <Button.Standart
            className={buttonStyled}
            onClick={menu.length > 0 ? handleClick : onClickHeader}
            loading={loading}
          >
            {buttonName}
          </Button.Standart>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              handleClose()
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {menu.map(item => (
              <MenuItem
                key={item.id}
                onClick={() => {
                  handleClose(item.onClick)
                }}
              >
                {item.text}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </Stack>
  )
}
