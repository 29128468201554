import { Telemetry } from 'utils/interfaces'
import { create } from 'zustand'

interface TelemetryStore {
  telemetry: Telemetry
  setTelemetry: (data: Telemetry) => void
  trajectory: [number, number][]
  pushTrajectory: (data: [number, number]) => void
  clearTrajectory: () => void
  setInitialTrajectory: (data: [number, number][]) => void
}

const useTelemetryStore = create<TelemetryStore>((set, get) => ({
  telemetry: {},
  setTelemetry: (data: Telemetry) => set({ telemetry: data }),
  trajectory: [],
  pushTrajectory: (data: [number, number]) => {
    return set({ trajectory: [...get().trajectory, data] })
  },
  clearTrajectory: () => set({ trajectory: [] }),
  setInitialTrajectory: trajectory => set({ trajectory }),
}))

export default useTelemetryStore
