import { Box, Chip, Grid, Stack, Typography } from '@mui/material'
import robotImage from 'images/robot.png'
import { useNavigate } from 'react-router-dom'
import { PATH, robotModes } from 'utils/constants'
import { RobotPopupData } from 'utils/interfaces'

interface Props {
  data?: RobotPopupData
}

const RobotPopup = ({ data }: Props) => {
  const navigate = useNavigate()

  if (!data) return null

  const { id, name, jobName, unitName, speed, battery, operatingMode, networkStatus } = data

  const handleClick = () => {
    navigate(`${PATH.ROBOT_LIST}/${id}`)
  }

  return (
    <Grid display="flex" container spacing={1}>
      <Grid item xs={3}>
        <Box
          width="50px"
          height="60px"
          bgcolor="info.main"
          borderRadius={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img src={robotImage} width="60" height="50" alt="robot" style={{ objectFit: 'contain' }} />
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Box display="flex">
          <Typography onClick={() => handleClick()} variant="body1" marginRight={1} sx={{ cursor: 'pointer' }}>
            {name}
          </Typography>
          {networkStatus && (
            <Chip
              label={networkStatus}
              variant="filled"
              color={networkStatus === 'online' ? 'primary' : 'warning'}
              size="small"
            />
          )}
        </Box>
        <Stack direction="column">
          <Typography variant="caption" color="#9E9E9E">
            Unit: {unitName || '-'}
          </Typography>
          <Typography variant="caption" color="#9E9E9E">
            {jobName}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          borderRadius={2}
          paddingX={1}
          bgcolor="info.main"
        >
          <Typography variant="caption" align="left" color="#9E9E9E" marginTop={1}>
            Speed
          </Typography>
          <Stack direction="row" alignItems="baseline">
            <Typography variant="h6" align="left" marginRight="2px">
              {speed ?? '-'}
            </Typography>
            <Typography variant="caption">km/h</Typography>
          </Stack>

          <div></div>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          borderRadius={2}
          paddingX={1}
          bgcolor="info.main"
        >
          <Typography variant="caption" align="left" color="#9E9E9E" marginTop={1}>
            Battery
          </Typography>
          <Stack direction="row" alignItems="baseline">
            <Typography variant="h6" align="left" marginRight="2px">
              {battery?.toFixed() ?? '-'}
            </Typography>
            <Typography variant="caption">%</Typography>
          </Stack>
          <div></div>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          borderRadius={2}
          paddingX={1}
          bgcolor="info.main"
        >
          <Typography variant="caption" align="left" color="#9E9E9E" marginTop={1}>
            Operating mode
          </Typography>
          <Typography variant="body2" align="left" marginBottom={1}>
            {operatingMode !== undefined ? robotModes[operatingMode] : '-'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default RobotPopup
