import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import CircleStyle from 'ol/style/Circle'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import { useEffect, useState } from 'react'
import useMapStore from 'store/map'
import { IPoint } from 'utils/interfaces'

interface Props {
  startPoint?: IPoint
  finishPoint?: IPoint
}
function CleaningStartFinishPoints({ startPoint, finishPoint }: Props) {
  const map = useMapStore(s => s.map)
  const [layer, setLayer] = useState<VectorLayer<VectorSource> | null>(null)

  useEffect(() => {
    if (!layer) return
    layer.getSource()?.clear()
    startPoint &&
      layer.getSource()?.addFeature(
        new Feature({
          geometry: new Point(fromLonLat([startPoint.geometry.coordinates[0], startPoint.geometry.coordinates[1]])),
          ...startPoint.properties,
        })
      )
    finishPoint &&
      layer.getSource()?.addFeature(
        new Feature({
          geometry: new Point(fromLonLat([finishPoint.geometry.coordinates[0], finishPoint.geometry.coordinates[1]])),
          ...finishPoint.properties,
        })
      )
  }, [startPoint, finishPoint, layer])

  useEffect(() => {
    const source = new VectorSource({})
    startPoint &&
      source.addFeature(
        new Feature({
          geometry: new Point(fromLonLat([startPoint.geometry.coordinates[0], startPoint.geometry.coordinates[1]])),
          ...startPoint.properties,
        })
      )
    finishPoint &&
      source.addFeature(
        new Feature({
          geometry: new Point(fromLonLat([finishPoint.geometry.coordinates[0], finishPoint.geometry.coordinates[1]])),
          ...finishPoint.properties,
        })
      )
    const layer = new VectorLayer({
      source,
      zIndex: 2,
      style: feature => {
        return new Style({
          image: new CircleStyle({
            radius: 8,
            fill: new Fill({
              color: feature.getProperties().id_on_map === 'mission_start_point' ? 'white' : '#9747FF',
            }),
            stroke: new Stroke({
              color: feature.getProperties().id_on_map === 'mission_start_point' ? '#9747FF' : 'white',
              width: 2,
            }),
          }),
        })
      },
    })
    map?.addLayer(layer)
    setLayer(layer)
    return () => {
      map?.removeLayer(layer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  return null
}

export default CleaningStartFinishPoints
