import { useQuery } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { useParams } from 'react-router-dom'
import { IMission } from 'utils/interfaces'

const useMission = (id?: number) => {
  const params = useParams()
  const missionId = Number(id) || Number(params.missionId)

  return useQuery<IMission, Error>({
    queryKey: ['mission', missionId],
    queryFn: () => MainApi.fetchMission(missionId),
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: true,
    enabled: !!missionId,
  })
}
export default useMission
