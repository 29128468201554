import { useMutation } from '@tanstack/react-query'
import { RobotApi } from 'api/RobotApi'
import { ROBOT_COMMANDS } from 'utils/constants'

interface SendData {
  id: number
  command: ROBOT_COMMANDS
}
const useSetRobotCommand = () =>
  useMutation<void, Error, SendData>({
    mutationFn: RobotApi.execCommand,
  })

export default useSetRobotCommand
