import { Accordion, AccordionDetails, AccordionSummary, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/material'
import { ReactComponent as QuestionMarkIcon } from 'images/icons/faq.svg'
import { useTranslation } from 'react-i18next'

import LastMissions from './RobotMissionDetails/LastMissions'
import MissionProperties from './RobotMissionDetails/MissionProperties'
import PlannedMissions from './RobotMissionDetails/PlannedMissions'

import style from './RobotMissionDetails.module.css'

const RobotMissionDetails = () => {
  const { t } = useTranslation()

  return (
    <div className={style.tableWrapperOverflow}>
      <Box className={style.listSelection} paddingTop={1.5} paddingLeft={2} paddingRight={2} paddingBottom={1}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="headerRobot">Mission properties</Typography>
          <Tooltip title="¯\_(ツ)_/¯" placement="top">
            <IconButton size="small">
              <QuestionMarkIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Accordion>
          <AccordionSummary>{t('mission_details.title_current_mission')}</AccordionSummary>
          <AccordionDetails>
            <MissionProperties />
          </AccordionDetails>
        </Accordion>
      </Box>

      <Divider sx={{ m: 1 }} />

      <Box className={style.listSelection} paddingTop={0.5} paddingLeft={2} paddingRight={2} paddingBottom={0.5}>
        <Accordion>
          <AccordionSummary>{t('mission_details.title_planned_mission')}</AccordionSummary>
          <AccordionDetails>
            <PlannedMissions />
          </AccordionDetails>
        </Accordion>
      </Box>

      <Divider sx={{ m: 1 }} />

      <Box className={style.listSelection} paddingTop={0.5} paddingLeft={2} paddingRight={2} paddingBottom={0.5}>
        <Accordion>
          <AccordionSummary>{t('mission_details.title_last_missions')}</AccordionSummary>
          <AccordionDetails>
            <LastMissions />
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  )
}

export default RobotMissionDetails
