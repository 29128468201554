import { useQuery } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { ObjectOfInterest } from 'api/schema'

const useObjectsOfInterest = () => {
  return useQuery<ObjectOfInterest[], Error>({
    queryKey: ['allObjectsOfInterest'],
    queryFn: () => MainApi.fetchObjectsOfInterest(),
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: true,
  })
}
export default useObjectsOfInterest
