import { GeoTiffLayerSchema } from 'api/schema'
import TileLayer from 'ol/layer/WebGLTile'
import GeoTIFF from 'ol/source/GeoTIFF.js'
import { useEffect } from 'react'
import useMapStore from 'store/map'

interface Props {
  geoTiffs: GeoTiffLayerSchema[]
}

function GeoTiffsLayer({ geoTiffs }: Props) {
  const map = useMapStore(s => s.map)

  useEffect(() => {
    const geoTiffLayers = geoTiffs.map(geotiff => {
      const layer = new TileLayer({
        source: new GeoTIFF({
          sources: [
            {
              url: (geotiff.file as string).replace('https://testfms.168robotics.com', ''),
              overviews: [`${(geotiff.file as string).replace('https://testfms.168robotics.com', '')}.ovr`],
            },
          ],
        }),
        zIndex: geotiff.sort_order || 1,
        opacity: geotiff.opacity || 1,
      })
      layer.setProperties({ name: geotiff.name, showInLayerList: true, isGeoTiff: true })
      return layer
    })
    geoTiffLayers.forEach(layer => map?.addLayer(layer))
    return () => {
      geoTiffLayers.forEach(l => map?.removeLayer(l))
    }
  }, [map, geoTiffs])

  return null
}

export default GeoTiffsLayer
