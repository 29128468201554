import { Box, IconButton, ListItem, Slider, Stack } from '@mui/material'
import { GeoTiffLayer } from 'api/schema'
import useZoomToPolygon from 'hooks/useZoomToPolygon'
import Feature from 'ol/Feature'
import { Polygon } from 'ol/geom'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import useMapStore from 'store/map'

const GeoTiffsInMission = () => {
  const { zoomToPolygon } = useZoomToPolygon()

  const displayGeotiffs = useMapStore(s => s.displayGeotiffs)
  const toggleGeotiff = useMapStore(s => s.toggleGeotiff)
  const getGeoTiffByUrl = useMapStore(s => s.getGeoTiffByUrl)

  const handleOpacityChange = (geoTiff: GeoTiffLayer, value: number) => {
    const layer = getGeoTiffByUrl(geoTiff.file?.replace('https://testfms.168robotics.com', ''))
    layer?.setOpacity(value / 100)
  }

  const handleGeoTiffClick = (geotiff: GeoTiffLayer) => {
    if (!geotiff.bbox || !geotiff.bbox.coordinates) return

    const feature = new Feature({
      geometry: new Polygon(geotiff.bbox.coordinates).transform('EPSG:4326', 'EPSG:3857'),
    })

    zoomToPolygon(feature.getGeometry() as Polygon)
  }

  return (
    <>
      {displayGeotiffs.map(geoTiff => (
        <ListItem key={geoTiff.id} dense={true}>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center">
              <span>
                <IconButton onClick={() => toggleGeotiff(geoTiff.id)}>
                  <AiOutlineCheckCircle color="#41C04E" />
                </IconButton>
              </span>
              <span onClick={() => handleGeoTiffClick(geoTiff)} style={{ fontSize: '1rem', cursor: 'pointer' }}>
                {geoTiff.name || '-'}
              </span>
            </Box>

            <Box width="40%" display="flex" paddingX={1} justifyContent="center" alignItems="center">
              <Slider
                defaultValue={geoTiff.opacity ? geoTiff.opacity * 100 : 100}
                size="small"
                onChange={(e, newVal) => handleOpacityChange(geoTiff, newVal as number)}
                step={5}
              />
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              {new Date(geoTiff.created_at).toLocaleDateString('ru-RU')}
            </Box>
          </Stack>
        </ListItem>
      ))}
    </>
  )
}

export default GeoTiffsInMission
