import { FormControl, InputLabel } from '@mui/material'
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select'
import { ReactNode } from 'react'

function SelectForm({ value = '', onChange, label, children, name, sx = {} }: SelectProps<string>) {
  const handleChange = (e: SelectChangeEvent, elem: ReactNode) => {
    if (onChange) {
      onChange(e, elem)
    }
  }

  return (
    <FormControl fullWidth className="selectForm" sx={sx}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select name={name} value={value} onChange={handleChange}>
        {children}
      </Select>
    </FormControl>
  )
}

export default SelectForm
