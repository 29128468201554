import { useMutation } from '@tanstack/react-query'
import { UnitApi } from 'api/UnitApi'
import { UnitRead } from 'api/schema'

interface SendData {
  id: number
  robots: number[]
}

const useUpdateUnitRobots = () =>
  useMutation<UnitRead, Error, SendData>({
    mutationFn: UnitApi.updateUnitRobots,
  })

export default useUpdateUnitRobots
