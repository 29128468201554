import { useState } from 'react'
import { SketchPicker } from 'react-color'
import { Popover } from 'react-tiny-popover'

import style from './ColorPicker.module.css'

const ColorPicker = ({ color, onChange }: { color: string; onChange: (colorHex: string) => void }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onChangeComplete = (color: { hex: string }) => {
    // setIsOpen(false)
    onChange(color.hex)
  }

  return (
    <Popover
      onClickOutside={() => setIsOpen(false)}
      isOpen={isOpen}
      positions={['left', 'right']} // preferred positions by priority
      reposition={true}
      containerStyle={{ zIndex: '999' }}
      content={() => <SketchPicker color={color} onChangeComplete={onChangeComplete} disableAlpha />}
    >
      <div className={style.colorButton} style={{ backgroundColor: color }} onClick={() => setIsOpen(true)}></div>
    </Popover>
  )
}

export default ColorPicker
