import { createTheme } from '@mui/material'
import type { SxProps } from '@mui/material'
import { ReactComponent as Accordion } from 'images/icons/accordion.svg'

declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyVariants {
    headerRobot: SxProps
    headerTile: SxProps
    textTile: React.CSSProperties
    textMinTile: React.CSSProperties
    subTitleTile: React.CSSProperties
    textDefault: React.CSSProperties
    textPopup: React.CSSProperties
    textTitlePopup: React.CSSProperties
    panelMode: SxProps
    tooltipMode: SxProps
    headerServiceModal: SxProps
  }

  // eslint-disable-next-line no-unused-vars
  interface TypographyVariantsOptions {
    headerRobot?: SxProps
    headerTile: SxProps
    textTile: React.CSSProperties
    textMinTile: React.CSSProperties
    subTitleTile: React.CSSProperties
    textDefault: React.CSSProperties
    textPopup: React.CSSProperties
    textTitlePopup: React.CSSProperties
    panelMode: SxProps
    tooltipMode: SxProps
    headerServiceModal: SxProps
  }
}

declare module '@mui/material/Typography' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyPropsVariantOverrides {
    headerRobot: true
    headerTile: true
    textTile: true
    textMinTile: true
    subTitleTile: true
    textDefault: true
    textPopup: true
    textTitlePopup: true
    panelMode: true
    tooltipMode: true
    headerServiceModal: true
  }
}

declare module '@mui/material/Button' {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsVariantOverrides {
    panelMode: true
    'mnl-close': true
    mnl: true
  }
}

declare module '@mui/material/TextField' {
  // eslint-disable-next-line no-unused-vars
  interface TextFieldPropsVariantOverrides {
    'right-label': true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    mobile: true
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1140,
      xl: 1536,
      mobile: 750,
    },
  },

  palette: {
    primary: {
      main: '#41C04E',
      contrastText: '#fff',
    },
    secondary: {
      main: '#212538',
    },
    info: {
      main: '#F4F5F8',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: 'mnl-close' },
          style: {
            backgroundColor: '#ffffff',
            borderRadius: '16px',
            boxShadow: '0px 4px 4px 0px rgba(66, 64, 64, 0.32)',
            backdropFilter: 'blur(25px)',
            color: '#424040',
            padding: '8px 32px',
            fontSize: '16px',
            lineHeight: '0px',

            '&:hover': {
              backgroundColor: '#ffffff',
              boxShadow: '0px 4px 4px 0px rgba(66, 64, 64, 0.32)',
            },
          },
        },
        {
          props: { variant: 'mnl' },
          style: {
            padding: '8px 9px',
            borderRadius: '8px',
            backgroundColor: '#41C04E',
            fontSize: '12px',
            color: '#ffffff',

            '&:hover': {
              backgroundColor: '#41C04E',
            },
          },
        },
        {
          props: { variant: 'mnl', color: 'secondary' },
          style: {
            backgroundColor: 'rgba(255, 255, 255, 0.70)',
            color: '#000000',

            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.70)',
            },
          },
        },
      ],
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          fontSize: '12px',
          fontWeight: 500,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#9E9E9E',
          fontSize: '12px',
          borderBottom: 'none',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#F47E29',
          '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '3px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
            '&:before': {
              display: 'none',
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '75px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: '#F4F5F8',
          padding: '4px',
          borderRadius: '8px',
          minHeight: '40px',

          '&.white': {
            backgroundColor: '#ffffff',

            '.MuiTab-root:not(.Mui-selected)': {
              backgroundColor: '#FFFFFF',
            },
          },

          '.MuiTab-root': {
            minHeight: '40px',
          },

          '.MuiTab-root:not(.Mui-selected)': {
            backgroundColor: '#F4F5F8',
          },

          [theme.breakpoints.between(900, 1093)]: {
            '&.tabsMode': {
              minHeight: '30px',

              '.MuiTab-root': {
                minHeight: '30px',
                minWidth: '70px',
                fontSize: '12px',
              },
            },
          },
        }),
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        iconPosition: 'start',
      },
      styleOverrides: {
        root: {
          backgroundColor: '#F4F5F8',
          borderRadius: '8px',
          flex: '1 1 auto',
          padding: 0,
          '&.Mui-selected': {
            backgroundColor: '#212538',
            color: '#fff',

            '.MuiTab-iconWrapper path': {
              fill: '#ffffff',
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: '0px',
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotate(-90deg)',
          },
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <Accordion />,
      },
      styleOverrides: {
        root: {
          padding: '0px 0px',
          position: 'relative',
          fontWeight: 500,
          '&.Mui-expanded': {
            minHeight: '48px',
          },

          '&::before': {
            position: 'absolute',
            right: '23px',
            top: '50%',
            content: '"Details"',
            transform: 'translateY(-50%)',
            fontWeight: 400,
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: '12px 0px',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '14px 0 0 0',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '&.selectForm': {
            '.MuiFormLabel-root': {
              position: 'static',
              transform: 'none',

              height: 'auto',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '15px',
              letterSpacing: '0px',
              textAlign: 'left',
              color: '#000a19cc',
              marginBottom: '5px',
            },
            '.MuiSelect-select': {
              backgroundColor: '#ffffff',
            },
            '.MuiOutlinedInput-notchedOutline': {
              top: 0,
              legend: {
                display: 'none',
              },
            },
          },
        },
      },
    },
  },
  typography: {
    headerRobot: {
      fontSize: 20,
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      '@media (max-height:900px)': {
        fontSize: '15px',
      },
    },
    headerTile: {
      fontSize: 14,
      fontWeight: 500,
      '@media (max-width:1300px)': {
        fontSize: 13,
      },
    },
    textTile: {
      fontSize: 40,
      fontWeight: 'bold',
    },
    textMinTile: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    subTitleTile: {
      fontSize: 14,
    },
    textDefault: {
      fontSize: 16,
    },
    textPopup: {
      color: '#424040',
      fontWeight: 'bold',
      fontSize: 22,
    },
    textTitlePopup: {
      color: '#9E9E9E',
    },
    headerServiceModal: {
      fontSize: '32px',
      fontWeight: 500,
      '@media (max-width:400px)': {
        fontSize: '20px',
      },
      '@media (min-width:700px)': {
        fontSize: '25px',
      },
      '@media (min-width:900px)': {
        fontSize: '28px',
      },
    },
    panelMode: {
      display: 'flex',
      alignItems: 'center',
      '@media (max-width:1093px)': {
        fontSize: 12,
      },
      '@media (max-height:900px)': {
        fontSize: '13px',
      },
    },
    tooltipMode: {
      alignItems: 'center',
      overflow: 'hidden',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '@media (max-width:1093px)': {
        fontSize: 12,
      },
      '@media (max-height:900px)': {
        fontSize: '13px',
      },
    },
  },
})
