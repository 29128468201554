import { Box } from '@mui/material'
import clsx from 'clsx'
import { ReactComponent as GlassIcon } from 'images/icons/glass.svg'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import styles from './OverlayNoRowsTable.module.css'

interface Props {
  colorIcon?: string
  text?: string
}

function OverlayNoRowsTable({ colorIcon = 'gray', text }: Props) {
  const { t } = useTranslation()
  const location = useLocation()

  const res = location.pathname.match(/^\/|\w+\/?/gm)
  const key = res?.length === 1 ? res[0] : (res && res[1]) || ''

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} width={'100%'} height={'100%'}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <div className={clsx(styles.wrapperIcon, styles[colorIcon])}>
          <GlassIcon className={styles.icon} />
        </div>

        <p className={styles.messageText}>{text || t(`noRowText.${key}`)}</p>
      </Box>
    </Box>
  )
}

export default OverlayNoRowsTable
