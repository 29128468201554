import { ReactComponent as ReturnSvg } from 'images/icons/return.svg'

import styles from './ReturnButton.module.css'

interface Props {
  onClick: () => void
  disabled?: boolean
}

const ReturnButton = ({ onClick, disabled = false }: Props) => {
  return (
    <button className={styles.button} onClick={onClick} disabled={disabled}>
      <ReturnSvg />
    </button>
  )
}

export default ReturnButton
