import OverlayNoRowsTable from 'components/OverlayNoRowsTable/OverlayNoRowsTable'
import { ReactComponent as SortIcon } from 'images/icons/sort_icon.svg'
import { StyledMinDataGrid } from 'utils/themeMinGrid'

const PlannedMissions = () => {
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.1,
      disableColumnMenu: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'plannedMission',
      headerName: 'Planned mission, %',
      flex: 0.8,
      disableColumnMenu: true,
    },
    {
      field: 'LaunchDate',
      headerName: 'Launch date',
      flex: 1,
      disableColumnMenu: true,
    },
  ]

  const rows = [{ id: 1, name: 'Mock data', plannedMission: 100, LaunchDate: '26.01.2023 04:37 pm' }]

  return (
    <StyledMinDataGrid
      rows={rows}
      columns={columns}
      hideFooter={true}
      autoHeight={true}
      showColumnVerticalBorder={false}
      showCellVerticalBorder={false}
      disableRowSelectionOnClick={true}
      columnHeaderHeight={35}
      slots={{
        columnSortedAscendingIcon: SortIcon,
        columnSortedDescendingIcon: SortIcon,
        noRowsOverlay: () => <OverlayNoRowsTable colorIcon="white" />,
      }}
      sx={{ m: 2, border: 0 }}
    />
  )
}

export default PlannedMissions
