// import unit_on_map from 'images/unit_on_map.png'
import unit_on_map from 'images/icons/unit_on_map.svg'
import { Feature, MapBrowserEvent, Overlay } from 'ol'
import { Point } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'
import { useEffect, useRef, useState } from 'react'
import useMapStore from 'store/map'
import { IUnit, UnitPopupData } from 'utils/interfaces'

import UnitPopup from './popup/UnitPopup'

import styles from './popup/RobotsLayerPopup.module.css'

interface Props {
  units: IUnit[]
}

function UnitsLayer({ units }: Props) {
  const popupRef = useRef<any>(null)
  const [popupData, setPopupData] = useState<UnitPopupData>()
  const map = useMapStore(s => s.map)

  useEffect(() => {
    if (!units || units.length < 1 || !map) return

    const features = units
      .filter(r => r.point)
      .map(r => {
        const feature = new Feature({
          geometry: new Point(fromLonLat([r.point!.coordinates[0], r.point!.coordinates[1]])),
          name: r.name,
          id: r.id,
          missionCompletion: r.missionCompletion,
          robotsCount: r.robotsCount,
          slotsStatuses: r.slotsStatuses,
        })

        feature.setStyle(
          new Style({
            image: new Icon({
              src: unit_on_map,
              scale: 0.75,
            }),
          })
        )
        return feature
      })

    const layer = new VectorLayer({
      source: new VectorSource({
        features,
      }),
      zIndex: 2,
    })

    map.addLayer(layer)

    const popupOverlay = new Overlay({
      element: popupRef.current,
    })
    map.addOverlay(popupOverlay)

    const clickHandler = (e: MapBrowserEvent<any>) => {
      const feature = map.forEachFeatureAtPixel(e.pixel, f => f)
      if (!feature) {
        popupRef.current.style.display = 'none'
        return setPopupData(undefined)
      }

      const { id, name, missionCompletion, robotsCount, slotsStatuses } = feature.getProperties()
      if (!robotsCount && robotsCount !== 0) return
      setPopupData({
        id,
        name,
        missionCompletion,
        robotsCount,
        slotsStatuses,
      })
      popupRef.current.style.display = 'block'
      popupOverlay.setPosition((feature.getGeometry() as Point).getCoordinates())
    }

    map.on('singleclick', clickHandler)

    return () => {
      map.removeLayer(layer)
      map.un('singleclick', clickHandler)
      map.removeOverlay(popupOverlay)
    }
  }, [units, map])

  const handleClosePopupClick = () => {
    popupRef.current.style.display = 'none'
  }

  return (
    <div ref={popupRef} className={styles['ol-popup']} style={{ display: 'none' }}>
      <button className={styles['ol-popup-closer']} onClick={handleClosePopupClick}></button>
      <UnitPopup data={popupData} />
    </div>
  )
}

export default UnitsLayer
