import { AppBar, Avatar, Box, Divider, IconButton, Menu, MenuItem, Toolbar } from '@mui/material'
import clsx from 'clsx'
import { useUser } from 'hooks/user'
import { ReactComponent as LogoSvg } from 'images/logo_main.svg'
import { ReactComponent as NotificationSvg } from 'images/notification.svg'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosMenu } from 'react-icons/io'
import { MdLogout } from 'react-icons/md'
import { Link, useMatch } from 'react-router-dom'
import { PATH } from 'utils/constants'

import NotificationsUser from './components/NotificationsUser/NotificationsUser'
import User from './components/User/User'

import style from '../Header.module.css'

const MainHeader = () => {
  const { t } = useTranslation()
  const user = useUser()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  const pages = [
    {
      text: t('header.units'),
      to: PATH.UNITS_LIST,
      active: useMatch(`${PATH.UNITS_LIST}/*`),
    },
    {
      text: t('header.robot'),
      to: PATH.ROBOT_LIST,
      active: useMatch(`${PATH.ROBOT_LIST}/*`),
    },
    {
      text: t('header.mission_planner'),
      to: PATH.MISSION_LIST,
      active: useMatch(window.location.href.indexOf(`${PATH.MISSION}`) != -1 ? `${PATH.MISSION}*` : `/`),
    },
    {
      text: t('header.charts'),
      to: PATH.CHARTS,
      active: useMatch(`${PATH.CHARTS}/*`),
    },
  ]

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const onLogoutClick = useCallback(() => {
    user?.actions.signout()
    handleCloseNavMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppBar position="static">
      <Toolbar
        disableGutters
        sx={{ justifyContent: { xs: 'space-between' }, padding: { xs: '0 16px', mobile: '0 30px' } }}
      >
        <Box sx={{ display: { xs: 'none', mobile: 'flex' }, mr: 1 }}>
          <Link to={PATH.MISSION_LIST} className={style.logo}>
            <LogoSvg />
          </Link>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'flex' },
            position: { mobile: 'absolute' },
            visibility: { mobile: 'hidden' },
            alignItems: 'center',
          }}
        >
          <IconButton
            size="large"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <IoIosMenu className={style.burgerMenuIcon} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            className={style.menuMobile}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block' },
              visibility: { mobile: 'hidden' },
            }}
          >
            {pages.map(page => (
              <MenuItem className={style.wrapperLinkMenu} key={page.to} onClick={handleCloseNavMenu}>
                <Link to={page.to} className={clsx(style.linkMenu, { [style.active]: page.active })}>
                  {page.text}
                </Link>
              </MenuItem>
            ))}
            <Divider />

            <div className={clsx(style.linkMenu, style.noCursor)}>
              <Avatar sx={{ width: '25px', height: '25px', fontSize: '12px', marginRight: '10px' }}>
                {user?.state.email?.substring(0, 2).toUpperCase()}
              </Avatar>
              {user?.state.email}
            </div>
            <MenuItem className={style.linkMenu}>
              <Box
                sx={{
                  width: '25px',
                  height: '25px',
                  marginRight: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <NotificationSvg />
              </Box>
              Уведомления
            </MenuItem>
            <MenuItem className={style.linkMenu} onClick={onLogoutClick}>
              <Box
                sx={{
                  width: '25px',
                  height: '25px',
                  marginRight: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MdLogout className={style.menuIcon} />
              </Box>
              Logout
            </MenuItem>
          </Menu>
          <Box sx={{ display: { xs: 'flex', mobile: 'none' } }}>
            <Link to={PATH.MISSION_LIST} className={style.logo}>
              <LogoSvg />
            </Link>
          </Box>
        </Box>

        <Box
          className={style.middle}
          sx={{
            display: { xs: 'none', mobile: 'flex' },
          }}
        >
          {pages.map(page => (
            <Link to={page.to} className={clsx(style.link, { [style.active]: page.active })} key={page.to}>
              {page.text}
            </Link>
          ))}
        </Box>
        <Box justifyContent="flex-start" sx={{ display: { xs: 'none', mobile: 'flex' } }} className={style.userWrapper}>
          <User email={user?.state.email} />
          <NotificationsUser />
        </Box>
      </Toolbar>
    </AppBar>
  )
}
export default MainHeader
