import { useState } from 'react'

import useAllMissions from './useAllMissions'

export const useFilteredMissions = () => {
  const { data: missions, isFetching, error, refetch } = useAllMissions()
  const [searchInput, setSearchInput] = useState('')

  const filteredMissions =
    searchInput === ''
      ? missions
      : missions.filter(mission => mission.name.toLowerCase().includes(searchInput.toLowerCase()))

  return { filteredMissions, setSearchInput, searchInput, isFetching, error, refetch }
}
