import robot_on_map from 'images/robot_on_map.png'
import { Feature, MapBrowserEvent, Overlay } from 'ol'
import { Point } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'
import { useEffect, useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import useMapStore from 'store/map'
import { IRobotInfo, RobotPopupData } from 'utils/interfaces'

import RobotPopup from './popup/RobotPopup'

import styles from './popup/RobotsLayerPopup.module.css'

interface Props {
  robots: IRobotInfo[]
}
function RobotsLayer({ robots }: Props) {
  const popupRef = useRef<any>(null)
  const [popupData, setPopupData] = useState<RobotPopupData>()
  const map = useMapStore(s => s.map)

  useEffect(() => {
    if (!robots || robots.length < 1 || !map) return

    const features = robots
      .filter(r => r.lastPosition)
      .map(r => {
        const feature = new Feature({
          geometry: new Point(fromLonLat([r.lastPosition!.coordinates[0], r.lastPosition!.coordinates[1]])),
          name: r.name,
          id: r.id,
          missionCompletion: r.job?.progress && r.job?.overall && Math.round((r.job?.progress / r.job?.overall) * 100),
          operatingMode: r.activeMode,
          networkStatus: r.networkStatus,
          speed: r.speed,
          battery: r.robotRaw.telemetry_battery_power,
          jobName: r.job?.missionName,
          unitName: r.unitName,
        })

        if (r.lastOrientationDegrees)
          feature.setStyle(
            new Style({
              image: new Icon({
                src: robot_on_map,
                scale: 0.75,
                rotation: r.lastOrientationDegrees * (Math.PI / 180),
              }),
            })
          )
        return feature
      })

    const layer = new VectorLayer({
      source: new VectorSource({
        features,
      }),
      zIndex: 2,
    })

    map.addLayer(layer)

    const popupOverlay = new Overlay({
      element: popupRef.current,
    })
    map.addOverlay(popupOverlay)

    const clickHandler = (e: MapBrowserEvent<any>) => {
      const feature = map.forEachFeatureAtPixel(e.pixel, f => f)
      if (!feature) {
        popupRef.current.style.display = 'none'
        return setPopupData(undefined)
      }
      const { id, speed, missionCompletion, operatingMode, name, battery, networkStatus, jobName, unitName } =
        feature.getProperties()
      if (!id || !name) return

      setPopupData({
        id,
        speed,
        missionCompletion,
        operatingMode,
        name,
        networkStatus,
        jobName,
        unitName,
        battery,
      })
      popupRef.current.style.display = 'block'
      popupOverlay.setPosition((feature.getGeometry() as Point).getCoordinates())
    }

    map.on('singleclick', clickHandler)

    return () => {
      map.removeLayer(layer)
      map.un('singleclick', clickHandler)
      map.removeOverlay(popupOverlay)
    }
  }, [robots, map])

  const handleClosePopupClick = () => {
    popupRef.current.style.display = 'none'
  }

  return (
    <div ref={popupRef} className={styles['ol-popup']} style={{ display: 'none' }}>
      <button className={styles['ol-popup-closer']} onClick={handleClosePopupClick}>
        <MdClose size={20} />
      </button>
      <RobotPopup data={popupData} />
    </div>
  )
}

export default RobotsLayer
