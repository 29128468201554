import { RobotApi } from 'api/RobotApi'
import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import CommonButton from 'components/Button/ButtonTypes/CommonButton'
import Dropdown, { IOption } from 'components/Dropdown/Dropdown'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import { Point } from 'geojson'
import { ReactComponent as CrossSvg } from 'images/icons/cross.svg'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { IRobotInfo, IUnit } from 'utils/interfaces'

import UnitView from './UnitView/UnitView'

import style from './AddRobotsModal.module.css'

interface IAddRobotModal {
  isOpen: boolean
  onClose: () => void
  onSave: () => void
  onUnitsChanged: () => void
  className?: string
  robotExisting?: IRobotInfo
  units: IUnit[]
  robots: IRobotInfo[]
}

enum unit_OPTIONS {
  NONE = 'none',
  CREATE_unit = 'select',
}

const defaultunit = {
  id: 1,
  name: 'Maintance Unit',
}

const AddRobotModal: React.FC<IAddRobotModal> = props => {
  const { t } = useTranslation()
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState<string>(props.robotExisting?.name || t('robots.new_robot_name'))
  const [description, setDescription] = useState(props.robotExisting?.description || '')
  const [serialId, setSerialId] = useState(
    props.robotExisting ? props.robotExisting?.serialNumber : Math.max(...props.robots.map(r => r.serialNumber)) + 1
  )

  const unit = props.units.find(unit => unit.id === props.robotExisting?.unitId)

  const [selectedunit, setSelectedunit] = useState<IUnit | undefined>(unit)
  const [tempunit, setTempunit] = useState<IUnit>(selectedunit || defaultunit)

  const selectunitOptions: IOption[] = [
    { text: 'No maintaince unit', value: unit_OPTIONS.NONE },
    { text: 'Create maintaince unit...', value: unit_OPTIONS.CREATE_unit },
    ...props.units.map(unit => {
      return { text: unit.name, value: unit.id }
    }),
  ]

  const [currentunitOption, setCurrentunitOption] = useState(
    unit ? { text: unit.name, value: unit.id } : selectunitOptions[0]
  )

  const onSaveClick = async () => {
    try {
      setSaving(true)
      let unitId = null
      if (unitShouldExist) {
        if (!tempunit?.point) {
          throw new Error('unit point should exist')
        }
        if (selectedunit) {
          const newunit = await RobotApi.patchUnit(selectedunit.id, tempunit?.name, tempunit?.point)
          unitId = newunit.id
        } else {
          const newunit = await RobotApi.createUnit(tempunit?.name, tempunit?.point)
          unitId = newunit.id
        }
        props.onUnitsChanged()
      }

      if (props.robotExisting) {
        await RobotApi.patchRobot(props.robotExisting.id, name, description, unitId)
      } else {
        await RobotApi.createRobot(name, description, serialId, unitId)
      }
      props.onSave()
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log('Error creating robot')
    }
    setSaving(false)
  }

  const onunitSelected = (e: IOption) => {
    if (e.value === unit_OPTIONS.NONE) {
      setCurrentunitOption(selectunitOptions[0])
    } else if (e.value === unit_OPTIONS.CREATE_unit) {
      setCurrentunitOption(selectunitOptions[1])
      setTempunit(defaultunit)
      setSelectedunit(undefined)
    } else {
      setCurrentunitOption(e)
      const unit = props.units.find(unit => unit.id === e.value)
      setTempunit(unit || defaultunit)
      setSelectedunit(unit)
    }
  }

  const onunitNameChanged = (name: string) => {
    setTempunit({ ...tempunit, name })
  }

  const onunitPointChanged = (point: Point) => {
    setTempunit({ ...tempunit, point })
  }

  const onUnitDeleted = async () => {
    try {
      if (selectedunit) {
        await RobotApi.deleteUnit(selectedunit.id)
      }
      setSelectedunit(undefined)
      setTempunit(defaultunit)
      setCurrentunitOption(selectunitOptions[0])
      props.onUnitsChanged()
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log('Error deleting unit: ', error)
    }
  }

  const unitShouldExist = currentunitOption.value !== unit_OPTIONS.NONE

  const isButtonsDisabled = unitShouldExist && !tempunit?.point

  const serialIdError = useMemo(() => {
    return !props.robotExisting && (!serialId || !!props.robots.find(robot => robot.serialNumber === serialId))
  }, [props.robotExisting, props.robots, serialId])

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      className={clsx(style.root, props.className)}
      shouldCloseOnOverlayClick={true}
      style={{ overlay: { zIndex: 100 } }}
      ariaHideApp={false}
    >
      <div className={style.titleBlock}>
        <div className={style.title}>{props.robotExisting ? t('robots.edit_existing') : t('robots.add_new')}</div>
        <CommonButton className={style.btnClose} onClick={props.onClose} disabled={false}>
          <CrossSvg />
        </CommonButton>
      </div>
      <div className={style.row}>
        <TextFieldDescribed
          className={style.textfield}
          description={t('robots.robot_name')}
          defaultValue={name}
          placeholder={t('robots.robot_name_placeholder')}
          onChange={e => setName(e.target.value)}
        />
        <TextFieldDescribed
          className={style.textfield}
          description={t('robots.robot_serial_id')}
          defaultValue={String(serialId)}
          placeholder={t('robots.robot_serial_id_placeholder')}
          onChange={e => setSerialId(parseFloat(e.target.value))}
          disabled={!!props.robotExisting}
          type="number"
          error="Serial ID must be unique"
          hasError={serialIdError}
        />
      </div>
      <div className={style.row}>
        <TextFieldDescribed
          className={style.textfield}
          description={t('robots.robot_description')}
          defaultValue={description}
          placeholder={t('robots.robot_description_placeholder')}
          onChange={e => setDescription(e.target.value)}
        />
        <Dropdown
          className={style.textfield}
          currentOption={currentunitOption}
          options={selectunitOptions}
          onClickOption={onunitSelected}
          description="Select maintenance unit:"
        />
      </div>
      {currentunitOption.value !== unit_OPTIONS.NONE && (
        <UnitView
          onNameChanged={onunitNameChanged}
          onPointChanged={onunitPointChanged}
          onUnitDeleted={onUnitDeleted}
          unit={tempunit}
          isNew={!selectedunit}
        />
      )}

      <div className={style.footer}>
        <Button.Outlined className={style.btn} disabled={saving || isButtonsDisabled} onClick={props.onClose}>
          {t('common.cancel')}
        </Button.Outlined>
        <Button.Standart
          className={style.btn}
          disabled={saving || isButtonsDisabled}
          loading={saving}
          onClick={onSaveClick}
        >
          {t('common.save')}
        </Button.Standart>
      </div>
    </Modal>
  )
}

export default AddRobotModal
