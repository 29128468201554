import { Tooltip, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

interface Props {
  description: string
  placement?: any
}

const OverflownText = ({ description, placement }: Props) => {
  const ref = useRef<HTMLElement>(null)
  const [isOverflown, setIsOverflown] = useState(false)
  useEffect(() => {
    const element = ref.current!
    setIsOverflown(element?.scrollWidth > element?.clientWidth)
  }, [])

  return (
    <Tooltip title={description} disableHoverListener={!isOverflown} placement={placement}>
      <Typography position="relative" ref={ref} variant="tooltipMode">
        {description}
      </Typography>
    </Tooltip>
  )
}

export default OverflownText
