import { useMutation } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { IZone, ZoneCreateData } from 'utils/interfaces'

const useCreateRestrictZone = () =>
  useMutation<IZone, Error, ZoneCreateData>({
    mutationFn: MainApi.createRestrictZone,
  })

export default useCreateRestrictZone
