import { Box, Button, Grid } from '@mui/material'
import clsx from 'clsx'
import RobotMissionMap from 'components/Robot/RobotMissionMap'
import RobotJoystick from 'components/RobotJoystick/RobotJoystick'
import useResize from 'hooks/useResize'
import { ReactComponent as SignalIcon } from 'images/icons/signal.svg'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick'

import style from './ManualModeMobile.module.css'

interface Props {
  className?: string
  onBackPage: () => void
}

const ManualModeMobile = ({ className, onBackPage }: Props) => {
  const { width, height } = useResize()
  const handleMove = (event: IJoystickUpdateEvent) => {
    // eslint-disable-next-line no-console
    console.log('event', event)
  }
  const handleStop = (event: IJoystickUpdateEvent) => {
    // eslint-disable-next-line no-console
    console.log('event', event)
  }

  return (
    <Box
      sx={{ flexDirection: 'column', justifyContent: 'space-between' }}
      className={clsx(style.manualModeMobile, className)}
    >
      <Box
        position="absolute"
        top={{ xs: '50%', sm: '10%' }}
        right={{ xs: '-40px', sm: '38%' }}
        sx={{ textAlign: 'center', transform: { xs: 'rotate(90deg);', sm: 'rotate(0deg);' } }}
      >
        <Button variant="mnl-close" endIcon={<IoMdCloseCircleOutline />} onClick={onBackPage}>
          Manual Mode
        </Button>
      </Box>
      <Box
        position="absolute"
        top={{ xs: '50%', sm: '70%' }}
        right={{ sm: '37%' }}
        sx={{ textAlign: 'center', transform: { xs: 'rotate(90deg);', sm: 'rotate(0deg);' } }}
      >
        <Button
          variant="mnl"
          className={style.manualButton}
          sx={{ fontWeight: 600, minWidth: 200 }}
          startIcon={<SignalIcon />}
        >
          Give a signal
        </Button>
      </Box>
      {width < 600 && (
        <Grid
          item
          right="15px"
          top="30px"
          height="150px"
          width="150px"
          position="absolute"
          sx={{ transform: 'rotate(90deg);' }}
        >
          <Box width="100%" height="100%" borderRadius={3} border={3} borderColor="#424040" overflow="hidden">
            <RobotMissionMap hideControls={true} />
          </Box>
        </Grid>
      )}
      {height < 550 && (
        <Grid
          item
          left="35px"
          top="30px"
          height="150px"
          width="150px"
          position="absolute"
          sx={{ transform: 'rotate(0deg);' }}
        >
          <Box width="100%" height="100%" borderRadius={3} border={3} borderColor="#424040" overflow="hidden">
            <RobotMissionMap hideControls={true} />
          </Box>
        </Grid>
      )}
      <Grid
        container
        bottom={{ xs: '30px' }}
        top={{ sm: '30px' }}
        right={{ xs: '15px', sm: '35px' }}
        width="150px"
        position="absolute"
        spacing={1}
        sx={{ transform: { xs: 'rotate(90deg);', sm: 'rotate(0deg);' } }}
        flexDirection="column"
      >
        <Grid item>
          <Button variant="mnl" color="secondary" className={style.manualButton}>
            Speed, km/h: 40
          </Button>
        </Grid>
        <Grid item>
          <Button variant="mnl" color="secondary" className={style.manualButton}>
            Wheels turning angle°: <b>15</b>
          </Button>
        </Grid>
        <Grid item>
          <Button variant="mnl" color="secondary" className={style.manualButton}>
            Passed time: 0:21
          </Button>
        </Grid>
      </Grid>
      <Box
        width="100%"
        height="100%"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'end' },
        }}
      >
        <Box margin="35px">
          <RobotJoystick move={handleMove} stop={handleStop} vertical={true} />
        </Box>
        <Box margin="35px">
          <RobotJoystick move={handleMove} stop={handleStop} />
        </Box>
      </Box>
    </Box>
  )
}

export default ManualModeMobile
