import Feature from 'ol/Feature'
import { asArray } from 'ol/color'
import { Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import Text from 'ol/style/Text'
import { useEffect, useState } from 'react'
import useMapStore from 'store/map'
import { ICleaningZone, IVisualizationSettings } from 'utils/interfaces'

interface Props {
  cleaningZones: ICleaningZone[]
  zoomToZone?: boolean
  visualSettings: IVisualizationSettings
}

function CleaningZonesLayer({ cleaningZones, zoomToZone, visualSettings }: Props) {
  const map = useMapStore(s => s.map)
  const setMissionExtent = useMapStore(s => s.setMissionExtent)
  const [layer, setLayer] = useState<VectorLayer<VectorSource> | null>(null)

  useEffect(() => {
    if (!layer) return
    layer.getSource()?.clear()
    layer.getSource()?.addFeatures(
      cleaningZones.map(zone => {
        const feature = new Feature({
          // @ts-ignore
          geometry: new Polygon(zone.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857'),
          ...zone.properties,
        })
        return feature
      })
    )
  }, [cleaningZones, layer])
  useEffect(() => {
    if (!map) return

    const fillColor = asArray(visualSettings.clean_fill_color)
    fillColor[3] = visualSettings.clean_opacity

    const layer = new VectorLayer({
      source: new VectorSource({
        features: cleaningZones.map(zone => {
          const feature = new Feature({
            // @ts-ignore
            geometry: new Polygon(zone.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857'),
            ...zone.properties,
          })
          return feature
        }),
      }),
      properties: {
        name: 'Cleaning Zones',
        showInLayerList: true,
      },
      zIndex: 3,
      style: feature => {
        return new Style({
          fill: new Fill({
            color: fillColor,
          }),
          stroke: new Stroke({
            color: visualSettings.clean_border_color,
            width: visualSettings.clean_width,
          }),
          text: new Text({
            text: feature?.get('name'),
            font: 'bold 18px sans-serif',
            stroke: new Stroke({ color: 'white', width: 2 }),
            overflow: true,
          }),
        })
      },
    })

    map.addLayer(layer)
    setLayer(layer)

    if (zoomToZone) {
      const extent = layer.getSource()?.getExtent()
      if (extent && !extent?.some(e => e === Infinity || e === -Infinity)) {
        map.getView().fit(extent, { padding: [50, 50, 50, 50] })
        setMissionExtent(extent)
      }
    }

    return () => {
      map.removeLayer(layer)
      setMissionExtent(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  useEffect(() => {
    const fillColor = asArray(visualSettings.clean_fill_color)
    fillColor[3] = visualSettings.clean_opacity

    layer?.setStyle(feature => {
      return new Style({
        fill: new Fill({
          color: fillColor,
        }),
        stroke: new Stroke({
          color: visualSettings.clean_border_color,
          width: visualSettings.clean_width,
        }),
        text: new Text({
          text: feature?.get('name'),
          font: 'bold 18px sans-serif',
          stroke: new Stroke({ color: 'white', width: 2 }),
          overflow: true,
        }),
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualSettings])

  return null
}

export default CleaningZonesLayer
