import clsx from 'clsx'
import Loader from 'components/Loader/Loader'
import useMission from 'hooks/useMission'
import useRobot from 'hooks/useRobot'
import MissionPointTransferItem from 'pages/Mission/MissionView/Modes/ViewZone/TransferItems/MissionPointTransferItem'
import StartFinishPointItem from 'pages/Mission/MissionView/Modes/ViewZone/TransferItems/StartFinishPointItem'
import TransferRouteItem from 'pages/Mission/MissionView/Modes/ViewZone/TransferItems/TransferRouteItem'
import CleaningZoneItem from 'pages/Mission/MissionView/Modes/ViewZone/ZoneItem/CleaningZoneItem'
import RestrictedZoneItem from 'pages/Mission/MissionView/Modes/ViewZone/ZoneItem/RestrictedZoneItem'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TRANSFER_TYPE } from 'utils/constants'

import style from '../RobotMissionDetails.module.css'

const MissionProperties = () => {
  const { t } = useTranslation()

  const { data: robot } = useRobot()
  const { data: mission, isLoading, error } = useMission(robot?.job?.missionId)

  const cleaningZonesList = useMemo(() => {
    return (
      <>
        {mission?.cleaningZones?.map((zone, index) => (
          <div key={zone.properties.zone_id}>
            {index !== 0 && (
              <TransferRouteItem
                type={TRANSFER_TYPE.MIDDLE}
                zoneIdToTransfer={zone.properties.zone_id}
                hideActions={true}
              />
            )}
            {isLoading && <CleaningZoneItem zone={zone} number={index + 1} hideActions={true} />}
          </div>
        ))}
      </>
    )
  }, [mission])

  if (isLoading) return <Loader />
  if (error) return <div>{error.message}</div>

  return (
    <div className={style.tableWrapper}>
      <div className={style.tableWrapper}>
        <div className={style.tableHeader}>
          <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('view_zones.zone_number')}</div>
          <div className={clsx(style.tableHeaderItemSmall, 'text-16-400')}>{t('view_zones.type')}</div>
          <div className={clsx(style.tableHeaderItem, 'text-16-400')}>{t('view_zones.name')}</div>
          <div className={clsx(style.tableHeaderItem, 'text-16-400')}>{t('view_zones.route_status')}</div>
        </div>
        {mission?.cleaningZones.length ||
        mission?.restrictZones.length ||
        mission?.startingPoint ||
        mission?.finishingPoint ? (
          <div className={style.table}>
            {mission.startingPoint && (
              <StartFinishPointItem
                type={TRANSFER_TYPE.START}
                missionPoint={mission.startingPoint}
                hideActions={true}
              />
            )}
            <MissionPointTransferItem type={TRANSFER_TYPE.START} hideActions={true} />
            {cleaningZonesList}
            <MissionPointTransferItem type={TRANSFER_TYPE.FINISH} hideActions={true} />
            {mission.finishingPoint && (
              <StartFinishPointItem
                type={TRANSFER_TYPE.FINISH}
                missionPoint={mission.finishingPoint}
                hideActions={true}
              />
            )}

            {mission?.restrictZones.map((zone, index) => (
              <RestrictedZoneItem
                key={index}
                zone={zone}
                number={index + mission.cleaningZones.length + 1}
                hideActions={true}
              />
            ))}
          </div>
        ) : (
          <div className={style.emptyWrapper}>
            <div className={style.emptyTitle}>{t('view_zones.no_zones_title')}</div>
            <div className={style.emptyDescription}>{t('view_zones.no_zones_description')}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MissionProperties
