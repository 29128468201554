import { Grid, Stack } from '@mui/material'
import Filter from 'components/Filter/Filter'
import ListHeader from 'components/ListHeader/ListHeader'
import Loader from 'components/Loader/Loader'
import MissionList from 'components/MissionList/MissionList/MissionList'
import Search from 'components/Serch/Search'
import useImportMission from 'hooks/mission/useImportMission'
import useCreateMission from 'hooks/useCreateMission'
import { useFilteredMissions } from 'hooks/useFilteredMissions'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'

import style from './MissionList.module.css'

const MissionListPage = () => {
  const navigate = useNavigate()
  const { mutateAsync: createNewMission, error: createError, isPending } = useCreateMission()
  const { filteredMissions, setSearchInput, searchInput, isFetching, error, refetch } = useFilteredMissions()
  const { mutate: importMissionMutation } = useImportMission()
  const unitMissionID = 1
  const menuCreateMission = [
    {
      id: 'robotMission',
      text: 'New robot mission',
      onClick: async () => {
        await createMission()
      },
    },
    {
      id: 'unitMission',
      text: 'New unit mission',
      onClick: () => {
        createUnit()
      },
    },
    {
      id: 'importMission',
      text: 'Import mission',
      onClick: () => importMission(),
    },
  ]

  const createMission = async () => {
    await createNewMission().then(mission => {
      mission?.id && navigate(`${PATH.MISSION}${mission.id}`)
    })
  }

  const createUnit = () => {
    navigate(`${PATH.UNIT_MISSION}/${unitMissionID}`)
  }

  const importMission = () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.zip'
    fileInput.onchange = async (e: any) => {
      const file = e.target.files[0]
      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        importMissionMutation(formData)
      }
    }
    fileInput.click()
  }

  return (
    <div className={style.container}>
      <Stack direction={{ xs: 'column', sm: 'row' }} width="100%" spacing={1} marginTop={{ xs: 1, sm: 2, lg: 3 }}>
        <Search
          onSearch={value => {
            setSearchInput(value)
          }}
          searchInput={searchInput}
        />
        <Filter />
      </Stack>
      <Grid width="100%" xs={12} md={12} item marginTop={{ xs: 1, sm: 1, lg: 0 }}>
        <ListHeader
          menu={menuCreateMission}
          loading={isPending}
          buttonName={'Create New Mission +'}
          titleHeader={t('missions.title')}
          buttonStyled={style.button}
        />
        {(error || createError) && <div>{error?.message || createError?.message}</div>}
        {isFetching ? <Loader /> : <MissionList missions={filteredMissions} onMissionsUpdated={refetch} />}
      </Grid>
    </div>
  )
}

export default MissionListPage
