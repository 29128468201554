import { useMutation } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { Mission } from 'api/schema'

const useCreateMission = () =>
  useMutation<Mission, Error, void>({
    mutationFn: MainApi.createMission,
  })

export default useCreateMission
