import SelectGroupFeature from '../SelectGroupFeature/SelectGroupFeature'
import { Box, Button, MenuItem, Stack } from '@mui/material'
import SelectForm from 'components/SelectForm/SelectForm'
import { ReactComponent as PlusSvg } from 'images/icons/plus.svg'
import { useState } from 'react'

import TableRoutingList from './TableRouting/TableRoutingList'

interface Props {
  showTitle: string
}
const SelectUnitList = ({ showTitle }: Props) => {
  const [selectedUnit, setSelectedUnit] = useState('')
  return (
    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
      <Box
        bgcolor="info.main"
        borderRadius="16px"
        height="34%"
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
      >
        <Stack direction="row" alignItems="center" gap={2} sx={{ paddingX: 2 }}>
          <SelectForm
            sx={{ width: '50%' }}
            label={'Select unit'}
            value={selectedUnit}
            onChange={e => setSelectedUnit(e.target.value)}
          >
            <MenuItem value={'10'}>Ten</MenuItem>
            <MenuItem value={'20'}>Twenty</MenuItem>
            <MenuItem value={'30'}>Thirty</MenuItem>
          </SelectForm>
          <Button
            variant="contained"
            fullWidth
            size="large"
            endIcon={<PlusSvg />}
            sx={{
              width: '50%',
              height: '40px',
              paddingX: 3,
              display: 'flex',
              borderRadius: '8px',
              fontSize: '16px',
              alignSelf: showTitle ? 'flex-end' : null,
            }}
          >
            New area
          </Button>
        </Stack>
        <Stack direction="row" alignItems="flex-end" justifyContent="space-between" gap={2} sx={{ paddingX: 2 }}>
          <SelectGroupFeature />
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ paddingX: 2, paddingY: 1 }}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            endIcon={<PlusSvg />}
            sx={{
              height: '40px',
              display: 'flex',
              borderRadius: '8px',
              fontSize: '16px',
              alignSelf: showTitle ? 'flex-end' : null,
            }}
          >
            Calculate zones
          </Button>
        </Stack>
      </Box>
      <Box
        borderRadius="16px"
        bgcolor="info.main"
        sx={{ position: 'relative', textAlign: 'center', height: '64%', overflowY: 'auto' }}
      >
        <TableRoutingList />
      </Box>
    </Box>
  )
}

export default SelectUnitList
