import { Feature } from 'geojson'
import { Feature as OlFeature } from 'ol'
import GeoJSON from 'ol/format/GeoJSON.js'
import { LineString, MultiPoint, Point, Polygon, SimpleGeometry } from 'ol/geom'
import { Draw, Modify } from 'ol/interaction'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import { getArea } from 'ol/sphere'
import CircleStyle from 'ol/style/Circle'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import Text from 'ol/style/Text'
import { useEffect, useState } from 'react'
import useMapStore from 'store/map'
import { MISSION_MAP_DRAWING_MODES } from 'utils/constants'
import { ILaunchPoint, IPoint, ITransferRoute, IZone } from 'utils/interfaces'

interface Props {
  mode: MISSION_MAP_DRAWING_MODES
  tempZone?: IZone
  tempLaunchPoint?: ILaunchPoint
  tempMissionPoint?: IPoint
  tempTransferRoute?: ITransferRoute
  onCreate: (evt: { features: Feature[] }) => void
  onUpdate: (evt: { features: Feature[]; action: string }) => void
}

function DrawModules({
  mode,
  tempZone,
  tempLaunchPoint,
  tempMissionPoint,
  tempTransferRoute,
  onUpdate,
  onCreate,
}: Props) {
  const map = useMapStore(s => s.map)
  const [polygonDraw, setPolygonDraw] = useState<Draw | null>(null)
  const [pointDraw, setPointDraw] = useState<Draw | null>(null)
  const [lineDraw, setLineDraw] = useState<Draw | null>(null)
  const [modify, setModify] = useState<Modify | null>(null)
  const [drawLayer, setDrawLayer] = useState<VectorLayer<VectorSource> | null>(null)

  const [tempPointLayer, setTempPointLayer] = useState<VectorLayer<VectorSource> | null>(null)

  useEffect(() => {
    if (!tempZone || !drawLayer) return
    drawLayer.getSource()?.clear()

    const feature = new OlFeature({
      // @ts-ignore
      geometry: new Polygon(tempZone.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857'),
      ...tempZone.properties,
    })
    feature.setId(tempZone.id)
    drawLayer.getSource()?.addFeature(feature)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempZone])

  useEffect(() => {
    if (!tempMissionPoint || !drawLayer) return
    drawLayer.getSource()?.clear()

    const feature = new OlFeature({
      // @ts-ignore
      geometry: new Point(
        fromLonLat([tempMissionPoint.geometry.coordinates[0], tempMissionPoint.geometry.coordinates[1]])
      ),
      ...tempMissionPoint.properties,
    })
    feature.setId(tempMissionPoint.id)

    drawLayer.getSource()?.addFeature(feature)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempMissionPoint])

  useEffect(() => {
    tempPointLayer?.getSource()?.clear()
    if (!tempLaunchPoint || !tempPointLayer) return
    tempPointLayer.getSource()?.clear()
    const feature = new OlFeature({
      geometry: new Point(
        fromLonLat([tempLaunchPoint.geometry.coordinates[0], tempLaunchPoint.geometry.coordinates[1]])
      ),
      ...tempLaunchPoint.properties,
    })

    feature.setId(tempLaunchPoint.id)
    tempPointLayer.getSource()?.addFeature(feature)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempLaunchPoint])

  useEffect(() => {
    if (!tempTransferRoute || !drawLayer) return
    drawLayer.getSource()?.clear()

    const feature = new OlFeature({
      // @ts-ignore
      geometry: new LineString(tempTransferRoute.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857'),
      ...tempTransferRoute.properties,
    })
    feature.setId(tempTransferRoute.id)
    drawLayer.getSource()?.addFeature(feature)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempTransferRoute])

  useEffect(() => {
    if (!polygonDraw || !pointDraw || !lineDraw || !modify) return
    modify && map?.removeInteraction(modify)
    polygonDraw && map?.removeInteraction(polygonDraw)
    pointDraw && map?.removeInteraction(pointDraw)
    lineDraw && map?.removeInteraction(lineDraw)
    if (mode === MISSION_MAP_DRAWING_MODES.DRAW_POLYGON) {
      map?.addInteraction(polygonDraw)
    } else if (mode === MISSION_MAP_DRAWING_MODES.ADD_MISSION_START_POINT) {
      map?.addInteraction(pointDraw)
    } else if (mode === MISSION_MAP_DRAWING_MODES.ADD_MISSION_FINISH_POINT) {
      map?.addInteraction(pointDraw)
    } else if (mode === MISSION_MAP_DRAWING_MODES.ADD_ZONE_LAUNCH_POINT) {
      map?.addInteraction(pointDraw)
    } else if (mode === MISSION_MAP_DRAWING_MODES.VIEW_ZONES) {
      drawLayer?.getSource()?.clear()
    } else if (mode === MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_ZONE) {
      map?.addInteraction(modify)
    } else if (mode === MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_MISSION_POINT) {
      map?.addInteraction(modify)
    } else if (mode === MISSION_MAP_DRAWING_MODES.DIRECT_SELECT_TRANSFER_ROUTE) {
      map?.addInteraction(modify)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  useEffect(() => {
    const source = new VectorSource({})
    const vector = new VectorLayer({
      source: source,
      zIndex: 10,
      style: feature => {
        const geom = feature.getGeometry() as Polygon
        return [
          new Style({
            fill: new Fill({
              color: [74, 144, 226, 0.3],
            }),
            stroke: new Stroke({
              color: 'black',
              width: 2,
            }),
            image: new CircleStyle({
              radius: 8,
              fill: new Fill({
                color: 'white',
              }),
              stroke: new Stroke({
                color: 'black',
                width: 3,
              }),
            }),
            text: new Text({
              text: `${Math.round(getArea(geom))} m2`,
              font: 'bold 18px sans-serif',
              stroke: new Stroke({ color: 'white', width: 2 }),
              overflow: true,
            }),
          }),
          new Style({
            image: new CircleStyle({
              radius: 5,
              fill: new Fill({
                color: 'orange',
              }),
              stroke: new Stroke({
                color: 'black',
                width: 1,
              }),
            }),
            geometry: feature => {
              const geom = feature.getGeometry()
              if (geom instanceof SimpleGeometry) {
                const coordinates = geom.getCoordinates()
                if (!coordinates) return

                return new MultiPoint(feature.getGeometry()?.getType() === 'Polygon' ? coordinates[0] : coordinates)
              }
            },
          }),
        ]
      },
    })

    map?.addLayer(vector)
    setDrawLayer(vector)

    const modify = new Modify({ source })
    modify.on('modifyend', evt => {
      evt.features.getArray().forEach(f => {
        f.setGeometry(f.getGeometry()?.transform('EPSG:3857', 'EPSG:4326'))
      })
      const format = new GeoJSON()
      const geoJsonFeatures = evt.features.getArray().map(f => format.writeFeatureObject(f))
      onUpdate({ features: geoJsonFeatures, action: '' })
    })
    setModify(modify)

    const polygonDraw = new Draw({
      source,
      type: 'Polygon',
    })
    polygonDraw.on('drawend', evt => {
      const format = new GeoJSON()
      const geoJson = format.writeFeatureObject(evt.feature, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      })
      onCreate({ features: [geoJson] })
      map?.removeInteraction(polygonDraw)
    })

    setPolygonDraw(polygonDraw)

    const pointSource = new VectorSource({})
    const pointDraw = new Draw({
      source: pointSource,
      type: 'Point',
    })
    pointDraw.on('drawend', evt => {
      const format = new GeoJSON()
      const geoJson = format.writeFeatureObject(evt.feature, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      })
      onCreate({ features: [geoJson] })
      map?.removeInteraction(pointDraw)
    })
    setPointDraw(pointDraw)

    const lineSource = new VectorSource()
    const lineDraw = new Draw({
      source: lineSource,
      type: 'LineString',
    })
    lineDraw.on('drawend', evt => {
      const format = new GeoJSON()
      const geoJson = format.writeFeatureObject(evt.feature, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      })

      onCreate({ features: [geoJson] })
      map?.removeInteraction(lineDraw)
    })
    setLineDraw(lineDraw)

    // temp point layer
    const tempPointSource = new VectorSource()
    const tempPointLayer = new VectorLayer({
      source: tempPointSource,
      zIndex: 11,
      style: new Style({
        image: new CircleStyle({
          radius: 8,
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: 'red',
            width: 2,
          }),
        }),
      }),
    })
    map?.addLayer(tempPointLayer)
    setTempPointLayer(tempPointLayer)

    return () => {
      map?.removeInteraction(polygonDraw)
      map?.removeInteraction(modify)
      map?.removeLayer(vector)
      map?.removeLayer(tempPointLayer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])
  return null
}

export default DrawModules
