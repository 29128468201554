import { useQuery } from '@tanstack/react-query'
import { CleaningZonesToMap, MainService } from 'api/schema'
import useMapStore from 'store/map'

const useAllCleaningZones = () => {
  const zoom = useMapStore(s => s.zoom)
  const extent = useMapStore(s => s.extent)

  return useQuery<CleaningZonesToMap[], Error>({
    queryKey: ['cleaningZones', zoom, extent.join(',')],
    queryFn: () => MainService.mainCleaningZoneLayerList(extent.join(',')),
    refetchOnWindowFocus: true,
    enabled: zoom > 8 && extent.length > 0,
    staleTime: 1000 * 60, // 1 min
  })
}
export default useAllCleaningZones
