import { Layout } from '../components/Layout/Layout'
import { useUser } from '../hooks/user'
import Mission from '../pages/Mission/Mission'
import NotFound from '../pages/NotFound/NotFound'
import NewPassword from '../pages/Signin/NewPassword/NewPassword'
import RestorePassword from '../pages/Signin/RestorePassword/RestorePassword'
import Signin from '../pages/Signin/Signin'
import { PATH } from '../utils/constants'
import ChartsPage from 'pages/Charts/ChartsPage'
import ManualModePage from 'pages/ManualMode/ManualModePage'
import MissionListPage from 'pages/MissionList/MissionListPage'
import RobotPage from 'pages/Robot/RobotPage'
import RobotListPage from 'pages/RobotList/RobotListPage'
import UnitPage from 'pages/Unit/UnitPage'
import UnitListPage from 'pages/UnitList/UnitListPage'
import UnitMissionPage from 'pages/UnitMission/UnitMissionPage'
import { useEffect } from 'react'
import { Navigate, createBrowserRouter, useLocation, useNavigate } from 'react-router-dom'

function PrivatePage({ children }: { children: JSX.Element }) {
  const user = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user?.state.userLoggedIn) {
      navigate(PATH.SIGNIN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.state.userLoggedIn])

  return children
}

function AuthPage({ children }: { children: JSX.Element }) {
  const location = useLocation()
  const user = useUser()
  if (user?.state.userLoggedIn) {
    return <Navigate to={PATH.MISSION_LIST} state={{ from: location }} />
  }
  return children
}

export const router = createBrowserRouter([
  {
    element: <Layout.PublicPage />,
    errorElement: <NotFound />,
    children: [
      {
        path: PATH.SIGNIN,
        element: (
          <AuthPage>
            <Signin />
          </AuthPage>
        ),
      },
      {
        path: PATH.RESTORE_PASSWORD,
        element: (
          <AuthPage>
            <RestorePassword />
          </AuthPage>
        ),
      },
      {
        path: PATH.NEW_PASSWORD,
        element: (
          <AuthPage>
            <NewPassword />
          </AuthPage>
        ),
      },
      {
        path: PATH.NOT_FOUND,
        element: <NotFound />,
      },
    ],
  },
  {
    element: <Layout.MainPage />,
    errorElement: <NotFound />,
    children: [
      {
        path: PATH.MISSION_LIST,
        element: (
          <PrivatePage>
            <MissionListPage />
          </PrivatePage>
        ),
      },
      {
        path: PATH.MISSION_ID,
        element: (
          <PrivatePage>
            <Mission />
          </PrivatePage>
        ),
      },
      {
        path: PATH.ROBOT_LIST,
        element: (
          <PrivatePage>
            <RobotListPage />
          </PrivatePage>
        ),
      },
      {
        path: PATH.ROBOT,
        element: (
          <PrivatePage>
            <RobotPage />
          </PrivatePage>
        ),
      },
      {
        path: PATH.MANUAL_MODE_ID,
        element: (
          <PrivatePage>
            <ManualModePage />
          </PrivatePage>
        ),
      },
      {
        path: PATH.UNIT,
        element: (
          <PrivatePage>
            <UnitPage />
          </PrivatePage>
        ),
      },
      {
        path: PATH.UNIT_MISSION_ID,
        element: (
          <PrivatePage>
            <UnitMissionPage />
          </PrivatePage>
        ),
      },
      {
        path: PATH.UNITS_LIST,
        element: (
          <PrivatePage>
            <UnitListPage />
          </PrivatePage>
        ),
      },
      {
        path: PATH.CHARTS,
        element: (
          <PrivatePage>
            <ChartsPage />
          </PrivatePage>
        ),
      },
    ],
  },
])
