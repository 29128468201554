import { useEffect } from 'react'
import useTelemetryStore from 'store/robotTelemetry'

import useRobot from './useRobot'

export default function useSetRobotTrajectory() {
  const { data: robot } = useRobot()
  const setInitialTrajectory = useTelemetryStore(s => s.setInitialTrajectory)

  useEffect(() => {
    robot?.telemetryRoute && setInitialTrajectory(robot.telemetryRoute.map(({ position }) => [position.x, position.y]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robot])
}
