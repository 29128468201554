import { GridColDef } from '@mui/x-data-grid'
import { MissionHistory } from 'api/schema'
import Loader from 'components/Loader/Loader'
import OverlayNoRowsTable from 'components/OverlayNoRowsTable/OverlayNoRowsTable'
import useRobotLastMissions from 'hooks/useRobotLastMissions'
import { ReactComponent as SortIcon } from 'images/icons/sort_icon.svg'
import { StyledMinDataGrid } from 'utils/themeMinGrid'

const LastMissions = () => {
  const { data, isLoading, error } = useRobotLastMissions()

  if (isLoading) return <Loader />

  if (error) return <div>Something went wrong</div>

  const columns: GridColDef[] = [
    {
      field: 'mission',
      headerName: 'ID',
      flex: 0.2,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'coverage_actual',
      headerName: 'Mission completion, %',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'coverage_plan',
      headerName: 'Planned mission, %',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'mission_time',
      headerName: 'Mission time',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'idle_time',
      headerName: 'Idle time',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'speed_average',
      headerName: 'Average speed, km/h',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'launchDate',
      headerName: 'Launch date',
      disableColumnMenu: true,
      flex: 0.75,
      valueGetter: params => new Date(params.row.created_at).toLocaleString('ru-RU'),
    },
  ]

  return (
    <StyledMinDataGrid
      rows={data as MissionHistory[]}
      columns={columns}
      getRowId={row => row.mission + row.created_at}
      initialState={{
        sorting: {
          sortModel: [{ field: 'launchDate', sort: 'desc' }],
        },
      }}
      hideFooter={true}
      autoHeight={true}
      showColumnVerticalBorder={false}
      showCellVerticalBorder={false}
      disableRowSelectionOnClick={true}
      columnHeaderHeight={35}
      slots={{
        columnSortedAscendingIcon: SortIcon,
        columnSortedDescendingIcon: SortIcon,
        noRowsOverlay: () => <OverlayNoRowsTable colorIcon="white" />,
      }}
      sx={{
        m: 2,
        border: 0,
      }}
    />
  )
}

export default LastMissions
