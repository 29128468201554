import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import style from './ConfirmDialog.module.css'

interface IDialogProps {
  isOpen: boolean
  onClose: () => void
  onConfirmClick: () => void
  title: string
  className?: string
  cancelText?: string
  confirmText?: string
  danger?: boolean
  confirmButtonDisabled?: boolean
}

const ConfirmDialog = (props: React.PropsWithChildren<IDialogProps>) => {
  const { t } = useTranslation()
  const [actionInProgress, setActionInProgress] = useState(false)

  const onAction = async () => {
    try {
      setActionInProgress(true)
      await props.onConfirmClick()
      setActionInProgress(false)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
    setActionInProgress(false)
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      className={clsx(style.root, props.className)}
      shouldCloseOnOverlayClick={false}
      style={{ overlay: { zIndex: 100 } }}
      ariaHideApp={false}
    >
      <div className={style.titleBlock}>
        <div className={style.title}>{props.title}</div>
      </div>
      {props.children}
      <div className={style.footer}>
        <Button.Outlined className={style.btn} disabled={actionInProgress} onClick={props.onClose}>
          {props.cancelText || t('common.cancel')}
        </Button.Outlined>
        <Button.Standart
          className={props.danger ? clsx(style.btn, style.btnRed) : style.btn}
          disabled={actionInProgress || props.confirmButtonDisabled}
          loading={actionInProgress}
          onClick={onAction}
        >
          {props.confirmText || t('common.ok')}
        </Button.Standart>
      </div>
    </Modal>
  )
}

export default ConfirmDialog
