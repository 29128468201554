import { Box, IconButton, MenuItem, Select, Stack, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import useAllRobots from 'hooks/useAllRobots'
import { ReactComponent as QuestionMarkIcon } from 'images/icons/faq.svg'
import { Moment } from 'moment'
import { useRef, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'

import style from './Charts.module.css'

const ChartPage = () => {
  Chart.register(CategoryScale)
  const { data: robots = [] } = useAllRobots()
  const [activeTab, setActiveTab] = useState<'coverage' | 'time' | 'mode'>('coverage')
  const ref = useRef()
  const [valueSelect, setValueSelect] = useState('')
  const [valueDate, setValueDate] = useState<Moment | null>()

  const handleChangeSelect = (event: any) => {
    setValueSelect(event.target.value as string)
  }

  const data = {
    labels: ['10:30 am', '11:00 am', '11:30 am', '12:00 am', '12:30 am', '13:00 am'],
    datasets: [
      {
        id: 1,
        label: 'Cleaning mode',
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: '#942774',
        borderWidth: 2,
        segment: {
          borderColor: (ctx: any) => colorSegment(ctx, ['#342522', '#25a2b3', '#25b329', '#d719e7', '#FF0000']),
        },
      },
    ],
  }

  const colorSegment = function (ctx: any, value: any) {
    return value[ctx.p1DataIndex]
  }

  const data2 = {
    labels: ['26.01.2014', '27.01.2014', '28.01.2014', '29.01.2014'],
    datasets: [
      {
        label: 'Cleaning mode',
        data: [8, 3, 3, 10],
        backgroundColor: '#30B03D33',
        borderColor: '#30B03D',
        borderWidth: 2,
        maxBarThickness: 150,
      },
      {
        label: 'Transport mode',
        data: [5, 7, 10, 4],
        backgroundColor: '#F47E2933',
        borderColor: '#F47E29',
        borderWidth: 2,
        maxBarThickness: 150,
      },
      {
        label: 'Planned',
        data: [5, 8, 5, 4],
        backgroundColor: '#71707033',
        borderColor: '#717070',
        borderWidth: 2,
        maxBarThickness: 150,
      },
    ],
  }

  const data3 = {
    labels: ['26.01.2014'],
    datasets: [
      {
        label: 'Cleaning mode',
        data: [4],
        backgroundColor: '#F47E2933',
        borderColor: '#F47E29',
        borderWidth: 2,
        maxBarThickness: 150,
      },
      {
        label: 'Patrolling',
        data: [6],
        backgroundColor: '#3082B033',
        borderColor: '#3082B0',
        borderWidth: 2,
        maxBarThickness: 150,
      },
      {
        label: 'Transport mode',
        data: [4],
        backgroundColor: '#71707033',
        borderColor: '#717070',
        borderWidth: 2,
        maxBarThickness: 150,
      },
    ],
  }
  const data4 = {
    labels: ['26.01.2014'],
    datasets: [
      {
        label: 'Cleaning mode',
        data: [8, 3, 3, 10],
        backgroundColor: '#30B03D33',
        borderColor: '#30B03D',
        borderWidth: 2,
        maxBarThickness: 150,
      },
      {
        label: 'Transport mode',
        data: [5, 7, 10, 4],
        backgroundColor: '#71707033',
        borderColor: '#717070',
        borderWidth: 2,
        maxBarThickness: 150,
      },
    ],
  }
  const plugin = {
    id: '0',
    //@ts-ignore
    beforeInit: function (chart) {
      const originalFit = chart.legend.fit
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)()
        this.height += 55
      }
    },
  }

  const displayTab = {
    coverage: (
      <Bar
        options={{
          plugins: {
            legend: {
              labels: {
                boxWidth: 51,
                boxHeight: 16,
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        }}
        plugins={[plugin]}
        data={data2}
      />
    ),
    time: (
      <Line
        options={{
          plugins: {
            legend: {
              labels: {
                boxWidth: 51,
                boxHeight: 16,
              },
            },
          },
          maintainAspectRatio: false,
        }}
        ref={ref}
        data={data}
        plugins={[plugin]}
      />
    ),
    mode: (
      <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
        <Box sx={{ width: '50%', marginX: 3 }}>
          <Bar
            data={data3}
            options={{
              plugins: {
                legend: {
                  labels: {
                    boxWidth: 51,
                    boxHeight: 16,
                  },
                },
              },
              maintainAspectRatio: false,
            }}
            plugins={[plugin]}
          />
        </Box>
        <Box sx={{ width: '50%', marginX: 3 }}>
          <Bar
            data={data4}
            options={{
              plugins: {
                legend: {
                  labels: {
                    boxWidth: 51,
                    boxHeight: 16,
                  },
                },
              },
              maintainAspectRatio: false,
            }}
            plugins={[plugin]}
          />
        </Box>
      </Box>
    ),
  }
  return (
    <Box
      bgcolor="info.main"
      width={'100%'}
      height={'auto'}
      borderRadius={5}
      marginY={'30px'}
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      paddingBottom={'16px'}
    >
      <Box display={'flex'} justifyContent={'space-between'} mb={'35px'} margin={2}>
        <Typography variant="headerRobot">Analytics charts</Typography>
        <Tooltip title="???" placement="top">
          <IconButton size="small">
            <QuestionMarkIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" margin={2}>
        <Stack direction="row" spacing="14px" width={{ xs: '100%', sm: '40%' }}>
          <Stack direction="column" className={style.selectBox}>
            <Typography variant="subTitleTile">Select robot</Typography>
            <Select
              value={valueSelect}
              label="Select"
              onChange={handleChangeSelect}
              sx={{
                backgroundColor: 'white',
                borderRadius: '8px',
                height: '40px',
                alignItems: 'center',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '8px',
                  borderColor: '#E9ECF4',
                },
              }}
            >
              {robots.map(robot => (
                <MenuItem key={robot.id} value={robot.id}>
                  {robot.id} - {robot.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack direction="column" className={style.selectBoxData}>
            <Typography variant="subTitleTile">Select date</Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={valueDate}
                onChange={(date: Moment | null) => {
                  setValueDate(date)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{
                      backgroundColor: 'white',
                      height: '40px',
                      borderRadius: '8px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '8px',
                        borderColor: '#E9ECF4',
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>
        <Box width={{ xs: '100%', sm: '50%' }} sx={{ display: 'flex', alignItems: 'end' }}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            className="tabsMode white"
            sx={{ width: '100%' }}
          >
            <Tab value="coverage" label="Coverage" />
            <Tab value="time" label="Time" />
            <Tab value="mode" label="Mode" />
          </Tabs>
        </Box>
      </Stack>
      <Box flexGrow={1} margin={4}>
        {displayTab[activeTab]}
      </Box>
    </Box>
  )
}

export default ChartPage
