import AddRobotModal from 'components/RobotList/RobotListView/AddRobotModal/AddRobotModal'
import { useState } from 'react'
import { IRobotInfo, IUnit } from 'utils/interfaces'

const RobotListHeader = ({
  units,
  onRobotsUpdated,
  onUnitsChanged,
  robots,
}: {
  robots: IRobotInfo[]
  units: IUnit[]
  onRobotsUpdated: () => void
  onUnitsChanged: () => void
}) => {
  const [addingNewRobot, setAddingNewRobot] = useState(false)

  return (
    <>
      <AddRobotModal
        isOpen={addingNewRobot}
        units={units}
        onClose={() => setAddingNewRobot(false)}
        onSave={onRobotsUpdated}
        onUnitsChanged={onUnitsChanged}
        robots={robots}
      />
    </>
  )
}

export default RobotListHeader
