import { useMutation } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { ICleaningZone, ILaunchPoint, ZoneCreateData } from 'utils/interfaces'

interface ReceiveData {
  zone: ICleaningZone
  launchPoint: ILaunchPoint | undefined
}

const useCreateCleaningZone = () =>
  useMutation<ReceiveData, Error, ZoneCreateData>({
    mutationFn: MainApi.createCleaningZone,
  })

export default useCreateCleaningZone
