import useRobotZoom from 'hooks/useRobotZoom'
import RobotOnMapImage from 'images/robot_on_map.png'
import { Feature } from 'ol'
import { LineString, Point } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useMapStore from 'store/map'
import useTelemetryStore from 'store/robotTelemetry'
import WebsocketManager, { SOCKET_MESSAGES } from 'utils/WebsocketManager'

import Widgets from './Widgets'
import ZoomToRobot from './widgets/ZoomToRobot'

function SingleRobotLayer() {
  const map = useMapStore(s => s.map)
  const setTelemetry = useTelemetryStore(s => s.setTelemetry)
  const trajectory = useTelemetryStore(s => s.trajectory)
  const pushTrajectory = useTelemetryStore(s => s.pushTrajectory)
  const clearTrajectory = useTelemetryStore(s => s.clearTrajectory)

  const robotId = useParams().assetId

  const [robotOnMap, setRobotOnMap] = useState<Feature<Point>>()
  const [robotLayer, setRobotLayer] = useState<VectorLayer<VectorSource> | null>(null)
  const [trajectoryLayer, setTrajectoryLayer] = useState<VectorLayer<VectorSource> | null>(null)
  const zoomToRobot = useRobotZoom({ robotOnMap, applyMissionLogic: true })

  useEffect(() => {
    if (!map) return

    const layer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 3,
    })

    map.addLayer(layer)
    setRobotLayer(layer)

    const trajectoryLayer = new VectorLayer({
      source: new VectorSource(),
      style: {
        'stroke-width': 3,
        'stroke-color': '#9747FF',
      },
      zIndex: 2,
    })
    map.addLayer(trajectoryLayer)
    setTrajectoryLayer(trajectoryLayer)

    return () => {
      map?.removeLayer(layer)
      map?.removeLayer(trajectoryLayer)
      clearTrajectory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  useEffect(() => {
    try {
      const feature = new Feature({
        geometry: new LineString(trajectory).transform('EPSG:4326', 'EPSG:3857'),
      })
      trajectoryLayer?.getSource()?.clear()
      trajectoryLayer?.getSource()?.addFeature(feature)
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log('error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trajectory])

  useEffect(() => {
    let websocketMessagesCount = 0

    const callback = (data: any) => {
      const position = data.odometry.pose.pose.position
      const orientation = data.odometry.pose.pose.orientation_degrees

      const feature = new Feature({
        geometry: new Point(fromLonLat([position.x, position.y])),
      })
      feature.setStyle(
        new Style({
          image: new Icon({
            src: RobotOnMapImage,
            scale: 0.75,
            rotation: orientation * (Math.PI / 180),
          }),
        })
      )
      robotLayer?.getSource()?.clear()
      robotLayer?.getSource()?.addFeature(feature)

      setRobotOnMap(feature)

      // initial zoom
      if (websocketMessagesCount === 0) {
        map?.getView().setCenter(fromLonLat([position.x, position.y]))
        map?.getView().setZoom(18)
      }

      websocketMessagesCount += 1
      if (websocketMessagesCount % 3 === 0) {
        setTelemetry({
          timestamp: data.header.stamp.sec,
          batteryPower: data.battery_power,
          waterTankFilling: data.water_tank_filling,
          trashFullness: data.fullness_of_trash,
          locks: {
            body: data.body_lock,
            bodyIsLoading: data.body_lock_is_loading,
            front: data.front_lock,
            frontIsLoading: data.front_lock_is_loading,
            charge: data.charge_lock,
            chargeIsLoading: data.charge_lock_is_loading,
            trash: data.trash_lock,
            trashIsLoading: data.trash_lock_is_loading,
            watering: data.watering,
            wateringIsLoading: data.watering_is_loading,
            light: data.front_light,
            lightIsLoading: data.front_light_is_loading,
            dangerButton: data.danger_button,
            dangerButtonIsLoading: data.danger_button_is_loading,
          },
          cleaningEquipmentState: data.cleaning_equipment_state,
          cleaningEquipmentStateIsLoading: data.cleaning_equipment_state_is_loading,
          rtkMode: data.rtk_mode,
          statusCode: data.status_code,
          orientation: data.odometry.pose.pose.orientation_degrees,
          velocity: data.odometry.twist.twist.linear.x,
          missionStatus: data.mission_status,
          activeCommand: data.robot_active_command,
          activeCommandIsLoading: data.robot_active_command_is_loading,
          activeMode: data.robot_active_mode,
          activeModeIsLoading: data.robot_active_mode_is_loading,
          missionProgress: data.mission_progress,
        })

        pushTrajectory([position.x, position.y])
      }
    }

    WebsocketManager.sendMessage('{"type": "robot.subscribe", "robot_id": ' + robotId + '}')
    WebsocketManager.addEventListener(SOCKET_MESSAGES.ROBOT_TELEMETRY, callback)
    return () => {
      WebsocketManager.removeEventListener(SOCKET_MESSAGES.ROBOT_TELEMETRY, callback)
      WebsocketManager.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robotLayer])

  // const zoomToRobot = () => {
  //   if (!robotOnMap) return
  //   map?.getView().setCenter(robotOnMap.getGeometry()?.getCoordinates())
  //   map?.getView().setZoom(18)
  // }

  return (
    <Widgets>
      <ZoomToRobot onClick={zoomToRobot} />
    </Widgets>
  )
}

export default SingleRobotLayer
