import { IconButton } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'
import { ReactComponent as MinusSvg } from 'images/icons/minus-small.svg'
import { ReactComponent as PlusSvg } from 'images/icons/plus-small.svg'
import { useState } from 'react'
import getTestId from 'utils/getTestId'
import { StyledTableCellCollapse } from 'utils/themeUnit'

import styles from './Row.module.css'

interface Props {
  id: any
  zone: string
  dateNext: string
  datePrevious: number
  dateSecond: number
  dateWorks?: string
  children?: JSX.Element[]
}

function Row({ zone, dateNext, datePrevious, dateSecond, dateWorks, children }: Props) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow>
        <StyledTableCellCollapse>
          {!!children?.length && (
            <IconButton
              className={styles.collapseBtn}
              sx={{ backgroundColor: '#ffffff', borderRadius: '4px' }}
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <MinusSvg className={styles.collapseIcon} /> : <PlusSvg className={styles.collapseIcon} />}
            </IconButton>
          )}
        </StyledTableCellCollapse>
        <StyledTableCellCollapse>{zone}</StyledTableCellCollapse>
        <StyledTableCellCollapse>{dateNext}</StyledTableCellCollapse>
        <StyledTableCellCollapse>{datePrevious}</StyledTableCellCollapse>
        <StyledTableCellCollapse>{dateSecond}</StyledTableCellCollapse>
        <StyledTableCellCollapse sx={{ width: '20%' }}>{dateWorks}</StyledTableCellCollapse>
        <StyledTableCellCollapse>
          {
            <ActionsMenu>
              <ActionsMenuItem onClick={() => {}} data-test-id={getTestId('edit-zone-list')}>
                <span className={styles.menuItemIcon} />
                <span data-test-id={getTestId('edit-title')}>{'Edit'}</span>
              </ActionsMenuItem>
              <ActionsMenuItem onClick={() => {}} data-test-id={getTestId('remove-zone-list')}>
                <RemoveSvg className={styles.menuItemIcon} />
                <span data-test-id={getTestId('delete-title')}>{'Delete'}</span>
              </ActionsMenuItem>
            </ActionsMenu>
          }
        </StyledTableCellCollapse>
      </TableRow>
      {open && children}
    </>
  )
}

export default Row
