/* eslint-disable no-console*/
import EventEmitter from 'events'

export enum SOCKET_MESSAGES {
  ROBOT_TELEMETRY = 'ros.telemetry',
  UNIT_TELEMETRY = 'ros.unit_telemetry',
}

const TIME_TO_RECONNECT = 2000

const WEBSOCKET_PATH = '/api/websocket/'

class WebsocketManager {
  static instance: WebsocketManager | undefined
  socket: WebSocket
  eventEmitter: EventEmitter
  opened: boolean

  private constructor() {
    this.opened = false
    this.eventEmitter = new EventEmitter()
    this.socket = this.getSocket()

    this.socket.onclose = () => {
      setTimeout(() => {
        this.socket = this.getSocket()
      }, TIME_TO_RECONNECT)
    }
  }

  private getSocket() {
    this.opened = false
    const scheme = 'ws' + (window.location.protocol === 'https:' ? 's' : '')
    const url = `${scheme}://${window.location.host}${WEBSOCKET_PATH}`

    const socket = new WebSocket(url)
    socket.onmessage = message => {
      try {
        const messageParsed = JSON.parse(message.data)
        this.eventEmitter.emit(messageParsed.type, messageParsed.data)
      } catch (error) {
        console.error(error)
      }
    }
    socket.onopen = () => {
      console.log('Socket connected')
      this.opened = true
    }
    return socket
  }

  public static addEventListener(message: SOCKET_MESSAGES, listener: (...args: any[]) => void): void {
    if (!WebsocketManager.instance) {
      WebsocketManager.instance = new WebsocketManager()
    }
    WebsocketManager.instance.eventEmitter.addListener(message, listener)
  }

  public static removeEventListener(message: SOCKET_MESSAGES, listener: (...args: any[]) => void): void {
    if (!WebsocketManager.instance) {
      WebsocketManager.instance = new WebsocketManager()
    }
    WebsocketManager.instance.eventEmitter.removeListener(message, listener)
  }

  public static sendMessage(data: string): void {
    if (!WebsocketManager.instance) {
      WebsocketManager.instance = new WebsocketManager()
    }
    if (this.instance?.opened) {
      WebsocketManager.instance.socket.send(data)
    } else {
      setTimeout(() => WebsocketManager.sendMessage(data), 1000)
    }
  }

  public static disconnect(): void {
    if (!WebsocketManager.instance || WebsocketManager.instance.socket.readyState !== 1) {
      return
    }
    WebsocketManager.instance.socket.close()
    WebsocketManager.instance = undefined
  }
}

export default WebsocketManager
