import { IconButton, InputAdornment, ListItem, Stack, TextField } from '@mui/material'
import { AiOutlineSearch } from 'react-icons/ai'
import { FiRefreshCcw } from 'react-icons/fi'

import GeoTiffUpload from './GeoTiffUpload'

import style from './GeoTiffHeader.module.css'

interface Props {
  missionId?: number
  searchValue: string
  onRefreshClick: () => void
  onUploadError: (error: string) => void
  onUpload: () => void
  onSearchChange: (value: string) => void
}

const GeoTiffHeader = ({ missionId, searchValue, onRefreshClick, onUploadError, onUpload, onSearchChange }: Props) => {
  return (
    <ListItem>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" width="100%">
        <TextField
          placeholder="search"
          value={searchValue}
          onChange={e => onSearchChange(e.target.value.toLowerCase())}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch />
              </InputAdornment>
            ),
            className: style.searchInput,
          }}
          size="small"
          fullWidth
        />
        <IconButton onClick={onRefreshClick} className={style.refreshBtn}>
          <FiRefreshCcw />
        </IconButton>
        {missionId && (
          <GeoTiffUpload missionId={missionId} onError={error => onUploadError(error)} onUpload={() => onUpload()} />
        )}
      </Stack>
    </ListItem>
  )
}

export default GeoTiffHeader
