import { GridSortModel } from '@mui/x-data-grid'
import { useMutation } from '@tanstack/react-query'
import { AccountApi } from 'api/AccountApi'
import { WhoAmI } from 'api/schema'

export const useConfUpdate = () => {
  return useMutation<WhoAmI, Error, GridSortModel>({
    mutationFn: AccountApi.setNewSort,
  })
}
