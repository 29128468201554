import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { ReactComponent as ChargeIcon } from 'images/icons/charge.svg'
import { ReactComponent as QuestionMarkIcon } from 'images/icons/faq.svg'

interface Props {
  header: string
  value?: number | string
  tooltip: string
  subtitle?: string
  onSubtitleClick?: boolean
}

const RobotDataBlock = ({ header, value, tooltip, subtitle, onSubtitleClick }: Props) => {
  return (
    <Stack direction="column" height="100%" borderRadius={2} bgcolor="info.main">
      <Stack height="20%" direction="row" alignItems="center" justifyContent="space-between" padding={1}>
        <Typography variant="headerTile" color="#9E9E9E">
          {header}
        </Typography>
        <Tooltip title={tooltip} placement="top">
          <IconButton size="small">
            <QuestionMarkIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack height="80%" display="flex" justifyContent="center">
        <Typography variant="textTile" align="center">
          {value} {onSubtitleClick && <ChargeIcon />}
        </Typography>
        <Typography variant="subTitleTile" align="center" color="#424040">
          {subtitle || ''}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default RobotDataBlock
