import AllCleaningZonesLayer from 'components/Map/AllCleaningZonesLayer'
import BaseMap from 'components/Map/BaseMap'
import CleaningRoutesLayer from 'components/Map/CleaningRoutesLayer'
import CleaningStartFinishPoints from 'components/Map/CleaningStartFinishPoints'
import CleaningZonesLayer from 'components/Map/CleaningZonesLayer'
import Layers from 'components/Map/Layers'
import MapContainer from 'components/Map/MapContainer'
import MissionGeoTiffsLayer from 'components/Map/MissionGeoTiffsLayer'
import ObjectsOfInterestLayer from 'components/Map/ObjectsOfInterestLayer'
import RestrictedZonesLayer from 'components/Map/RestrictedZonesLayer'
import SingleRobotLayer from 'components/Map/SingleRobotLayer'
import TransferRoutesLayer from 'components/Map/TransferRoutesLayer'
import UnitsLayer from 'components/Map/UnitsLayer'
import Widgets from 'components/Map/Widgets'
import LayerList from 'components/Map/widgets/LayerList'
import Legend from 'components/Map/widgets/Legend/Legend'
import Measurment from 'components/Map/widgets/Measurment'
import useAllUnits from 'hooks/useAllUnits'
import useMission from 'hooks/useMission'
import useRobot from 'hooks/useRobot'
import useRobotObjectsOfInterest from 'hooks/useRobotObjectsOfInterest'
import useSetRobotTrajectory from 'hooks/useSetRobotTrajectory'
import { DEFAULT_VIZUALIZATION_SETTINGS } from 'utils/constants'
import { ITrajectory } from 'utils/interfaces'

interface Props {
  hideControls?: boolean
}

const RobotMissionMap = ({ hideControls }: Props) => {
  const { data: robot } = useRobot()
  const { data: mission } = useMission(robot?.job?.missionId)
  const { data: objectsOfInterest } = useRobotObjectsOfInterest()
  const { data: units } = useAllUnits()

  useSetRobotTrajectory()

  const getTransferRoutes = (): ITrajectory[] => {
    return [...(mission?.pointRoutes || []), ...(mission?.transferRoutes || [])]
  }

  const visualSettings = { ...DEFAULT_VIZUALIZATION_SETTINGS, ...mission?.visualizationSettings }

  return (
    <MapContainer hideControls={hideControls}>
      <Layers>
        <BaseMap />
        <SingleRobotLayer />
        <ObjectsOfInterestLayer objectsOfInterest={objectsOfInterest || []} />
        <UnitsLayer units={units} />
        <AllCleaningZonesLayer visible={false} />
        {mission && (
          <>
            <CleaningZonesLayer cleaningZones={mission.cleaningZones} visualSettings={visualSettings} />
            <RestrictedZonesLayer restrictedZones={mission.restrictZones} visualSettings={visualSettings} />
            <CleaningStartFinishPoints startPoint={mission.startingPoint} finishPoint={mission.finishingPoint} />
            <CleaningRoutesLayer
              cleaningRoutes={mission.cleaningRoutes}
              launchPoints={mission.launchPoints}
              visualSettings={visualSettings}
            />
            <TransferRoutesLayer transferRoutes={getTransferRoutes()} visualSettings={visualSettings} />
            <MissionGeoTiffsLayer geoTiffs={mission.geoTiffLayers} />
          </>
        )}
      </Layers>
      {!hideControls && (
        <Widgets>
          <LayerList />
          <Measurment />
          {mission && <Legend visualSettings={visualSettings} />}
        </Widgets>
      )}
    </MapContainer>
  )
}

export default RobotMissionMap
