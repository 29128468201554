import { Box } from '@mui/material'
import clsx from 'clsx'
import { ReactComponent as JoysticDirection } from 'images/joystickDirection.svg'
import { useState } from 'react'
import { Joystick } from 'react-joystick-component'
import { IJoystickProps, IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick'

import style from './RobotJoystick.module.css'

interface Props extends IJoystickProps {
  vertical?: boolean
}

const RobotJoystick = ({ move, stop, vertical }: Props) => {
  const [pos, setPos] = useState<number | null>(null)

  function setMove(event: IJoystickUpdateEvent) {
    vertical ? setPos(event.x) : setPos(event.y)
  }

  const handleMove = (event: IJoystickUpdateEvent) => {
    setMove(event)
    if (move) {
      move(event)
    }
  }
  const handleStop = (event: IJoystickUpdateEvent) => {
    setMove(event)
    if (stop) {
      stop(event)
    }
  }

  return (
    <Box className={style.joystickWrapper}>
      <Joystick
        size={152}
        stickSize={50}
        baseColor="rgba(255, 255, 255, 0.40)"
        stickColor="#424040"
        move={handleMove}
        stop={handleStop}
      ></Joystick>

      <Box className={clsx(style.joystickBackground, { [style.vertical]: vertical })}>
        <JoysticDirection
          className={clsx(style.joystickDirection, style.up, { [style.white]: pos !== null && pos > 0 })}
        />
        <JoysticDirection
          className={clsx(style.joystickDirection, style.down, { [style.white]: pos !== null && pos < 0 })}
        />
      </Box>
    </Box>
  )
}

export default RobotJoystick
