import { ReactNode } from 'react'

interface Props {
  children: ReactNode
}
function Widgets({ children }: Props) {
  return <>{children}</>
}

export default Widgets
