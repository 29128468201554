import { Tab, Tabs } from '@mui/material'
import { ReactComponent as GearSvg } from 'images/icons/gear.svg'
import { ReactComponent as GeotiffSvg } from 'images/icons/geotiff.svg'
import { ReactComponent as SuccessFlowerSvg } from 'images/icons/success_flower.svg'
import useMissionStore from 'store/mission'
import { MISSION_MAP_DRAWING_MODES, MISSION_PAGE_MODES } from 'utils/constants'
import { useShallow } from 'zustand/react/shallow'

const MissionButtons = () => {
  const { mode, missionInfo, setMode, setMapMode } = useMissionStore(
    useShallow(state => ({
      mode: state.mode,
      missionInfo: state.missionInfo,
      setMode: state.setMode,
      setMapMode: state.setMapMode,
    }))
  )

  const clickTab = (value: MISSION_PAGE_MODES) => {
    if (value === MISSION_PAGE_MODES.VIEW_ZONES) {
      setMode(MISSION_PAGE_MODES.VIEW_ZONES)
      setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
    } else if (value === MISSION_PAGE_MODES.VIEW_GEOTIFF) {
      setMode(MISSION_PAGE_MODES.VIEW_GEOTIFF)
      setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_GEOTIFF)
    } else if (value === MISSION_PAGE_MODES.VIEW_SETTINGS) {
      setMode(MISSION_PAGE_MODES.VIEW_SETTINGS)
    }
  }

  if (!missionInfo) {
    return null
  }

  return (
    <>
      {mode !== MISSION_PAGE_MODES.EDIT_ZONE && mode !== MISSION_PAGE_MODES.DRAW_ZONE && (
        <Tabs value={mode} onChange={(e, value: MISSION_PAGE_MODES) => clickTab(value)}>
          <Tab value={MISSION_PAGE_MODES.VIEW_ZONES} icon={<SuccessFlowerSvg />} label="Mission" />
          <Tab value={MISSION_PAGE_MODES.VIEW_GEOTIFF} icon={<GeotiffSvg />} label="GeoTIFF Layers" />
          <Tab value={MISSION_PAGE_MODES.VIEW_SETTINGS} icon={<GearSvg />} label="Settings" />
        </Tabs>
      )}
    </>
  )
}

export default MissionButtons
