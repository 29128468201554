import { useMutation, useQueryClient } from '@tanstack/react-query'
import { RobotApi } from 'api/RobotApi'

const useUploadMission = (robotId: number) => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, number>({
    mutationFn: RobotApi.uploadJobToRobot,

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['robot', robotId] })
    },
  })
}

export default useUploadMission
