import { Box } from '@mui/material'

import style from './Filter.module.css'

const Filter = () => {
  return (
    <Box className={style.filter}>
      <p>Filters will be available soon</p>
    </Box>
  )
}

export default Filter
