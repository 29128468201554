import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Maintenance } from 'api/schema'
import useUpdateUnitRobots from 'hooks/useUpdateUnitRobots'
import moment from 'moment'
import { MdOutlineClose } from 'react-icons/md'

interface Props {
  show: boolean
  onClose: () => void
  services: Maintenance
  type: 'current' | 'planned' | 'last'
}

const RobotServiceModal = ({ show, onClose, services, type }: Props) => {
  const { error } = useUpdateUnitRobots()

  return (
    <Modal open={show} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '70%',
          minWidth: '40%',
          maxHeight: '40%',
          minHeight: '25%',
          overflow: 'hidden',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 2,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography id="modal-modal-title" variant="headerServiceModal" component="h2">
            {type === 'current' ? 'Confirmation of service work' : 'Delete service work'}
          </Typography>
          <IconButton onClick={onClose} aria-label="close">
            <MdOutlineClose />
          </IconButton>
        </Stack>
        <Box>
          <Box
            sx={{
              display: 'flex',
              bgcolor: '#F4F5F8',
              borderRadius: '16px',
              paddingBottom: '10px',
              overflowX: 'auto',
            }}
          >
            <Table aria-label="simple table" style={{ borderCollapse: 'collapse', minWidth: '200px' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingBottom: '0px', fontSize: '16px', whiteSpace: 'nowrap', color: '#424040' }}>
                    Base info
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }}>ID</TableCell>
                  <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }}>Previous date</TableCell>
                  <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }}>Next date</TableCell>
                  <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }}>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ borderBottom: 'none', paddingBottom: '0px' }}>{services.id}</TableCell>
                  <TableCell style={{ borderBottom: 'none', paddingBottom: '0px' }}>
                    {moment(services.date_prev).format('DD.MM.YYYY hh:mm a')}
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none', paddingBottom: '0px' }}>
                    {moment(services.date_next).format('DD.MM.YYYY hh:mm a')}
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none', paddingBottom: '0px' }}>{services.description}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Divider variant="fullWidth" sx={{ my: 1 }} />
          <Button onClick={onClose} color={type === 'current' ? 'primary' : 'error'} variant="contained" fullWidth>
            {type === 'current' ? 'Perform this service work' : 'Delete this service work'}
          </Button>
          {error && <Alert severity="error">{error.message}</Alert>}
        </Box>
      </Box>
    </Modal>
  )
}

export default RobotServiceModal
