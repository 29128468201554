import clsx from 'clsx'
import ActionsMenu, { ActionsMenuItem } from 'components/ActionsMenu/ActionsMenu'
import useDeleteRestrictZone from 'hooks/mission/useDeleteRestrictZone'
import useZoomToPolygon from 'hooks/useZoomToPolygon'
import { ReactComponent as ZoneSvg } from 'images/icons/cleaning_zone_small.svg'
import { ReactComponent as RemoveSvg } from 'images/icons/delete.svg'
import { ReactComponent as ZoneRestrSvg } from 'images/icons/draw_zone_restricted.svg'
import Feature from 'ol/Feature'
import { Polygon } from 'ol/geom'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useMissionStore from 'store/mission'
import { MISSION_ZONE_TYPES } from 'utils/constants'
import getTestId from 'utils/getTestId'
import { IRestrictZone } from 'utils/interfaces'
import { useShallow } from 'zustand/react/shallow'

import style from './ZoneItem.module.css'

const RestrictedZoneItem = ({
  zone,
  number,
  hideActions,
}: {
  zone: IRestrictZone
  number: number
  hideActions?: boolean
}) => {
  const { mutateAsync: setDeleteRestrictZone } = useDeleteRestrictZone()
  const { t } = useTranslation()
  const { zoomToPolygon } = useZoomToPolygon()

  const { deleteRestrictZone, setChangeZone, highlightedFeatureInList } = useMissionStore(
    useShallow(state => ({
      deleteRestrictZone: state.deleteRestrictZone,
      setChangeZone: state.setChangeZone,
      highlightedFeatureInList: state.highlightedFeature,
    }))
  )

  const highlighted = useMemo(() => {
    return highlightedFeatureInList === zone.id
  }, [highlightedFeatureInList, zone.id])

  const onZoneClicked = () => {
    const feature = new Feature({
      // @ts-ignore
      geometry: new Polygon(zone.geometry.coordinates!).transform('EPSG:4326', 'EPSG:3857'),
    })

    zoomToPolygon(feature.getGeometry() as Polygon)
  }

  const onEditZoneClicked = () => {
    setChangeZone(zone)
  }

  const onRemoveZoneClicked = () => {
    if (zone.properties?.zone_id) {
      setDeleteRestrictZone(zone.properties?.zone_id as number)
        .then(() => {
          deleteRestrictZone(zone)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('Error deleting zone', error)
        })
    }
  }

  return (
    <div className={highlighted ? clsx(style.zoneItem, style.highlighted) : style.zoneItem} onClick={onZoneClicked}>
      <div className={style.zoneItemColSmall}>#{number}</div>
      <div className={style.zoneItemColSmall}>
        {zone.properties?.zone_type === MISSION_ZONE_TYPES.RESTRICT ? (
          <ZoneRestrSvg className={style.icon} />
        ) : (
          <ZoneSvg className={style.icon} />
        )}
      </div>
      <div className={style.zoneItemCol}>{zone.properties?.name}</div>
      <div className={style.zoneItemCol}></div>
      <div className={style.zoneItemColSmall}>
        {!hideActions && (
          <ActionsMenu>
            <ActionsMenuItem onClick={onEditZoneClicked} data-test-id={getTestId('edit-zone-list')}>
              <span className={style.menuItemIcon} />
              <span data-test-id={getTestId('edit-title')}>{t('common.edit')}</span>
            </ActionsMenuItem>
            <ActionsMenuItem onClick={onRemoveZoneClicked} data-test-id={getTestId('remove-zone-list')}>
              <RemoveSvg className={style.menuItemIcon} />
              <span data-test-id={getTestId('delete-title')}>{t('common.delete')}</span>
            </ActionsMenuItem>
          </ActionsMenu>
        )}
      </div>
    </div>
  )
}

export default RestrictedZoneItem
