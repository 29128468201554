// src/components/CustomControl.js
import { Checkbox, Divider } from '@mui/material'
import Control from 'ol/control/Control'
import { getUid } from 'ol/util'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import useMapStore from 'store/map'

import styles from './LayerList.module.css'

const LayerList = () => {
  const map = useMapStore(s => s.map)
  const setSatelliteBaseMap = useMapStore(s => s.setSatelliteBaseMap)
  const [isOpen, setIsOpen] = useState(false)
  const [, updateState] = useState({})
  const controlRef = useRef<HTMLDivElement | null>(null)
  const allLayers = map?.getAllLayers().filter(layer => layer.getProperties().showInLayerList)

  useEffect(() => {
    if (!map || !controlRef) return

    // add on map
    const LayerListControl = new Control({
      element: controlRef.current as HTMLInputElement,
    })

    map.addControl(LayerListControl)
    return () => {
      map?.removeControl(LayerListControl)
    }
  }, [map])

  const toggleLayer = (id: string) => {
    const layer = map?.getAllLayers().find(layer => getUid(layer) === id)
    layer?.setVisible(!layer.getVisible())
    updateState({})
  }

  const checkLayerVisible = (id: string) => {
    const layer = map?.getAllLayers().find(layer => getUid(layer) === id)
    return layer !== undefined ? layer.getVisible() : false
  }

  return (
    <div
      ref={controlRef}
      className="ol-unselectable ol-control ol-control-custom"
      style={{ bottom: '152px', right: '.5em' }}
    >
      <Popover
        onClickOutside={() => setIsOpen(false)}
        isOpen={isOpen}
        positions={['left']}
        reposition={false}
        containerStyle={{ zIndex: '999' }}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'#fff'}
            arrowSize={12}
            arrowStyle={{ zIndex: 1 }}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div className={styles.popover}>
              <div className={styles.popoverHeader}>Choose map layers</div>
              <div onClick={() => setSatelliteBaseMap(false)} className={styles.popoverItem}>
                Vector map
              </div>
              <div className={styles.popoverItem} onClick={() => setSatelliteBaseMap(true)}>
                Satellite
              </div>
              <div className={styles.popoverSeparator}></div>

              {allLayers?.map(layer => {
                const props = layer.getProperties()
                const id = getUid(layer)

                return props.showInLayerList ? (
                  <Fragment key={id}>
                    <Divider />
                    <div className={styles.popoverItem} onClick={() => toggleLayer(id)}>
                      <div>{props.name}</div>
                      <Checkbox checked={checkLayerVisible(id)} />
                    </div>
                  </Fragment>
                ) : null
              })}
            </div>
          </ArrowContainer>
        )}
      >
        <button onClick={() => setIsOpen(!isOpen)} className={styles.icon}></button>
      </Popover>
    </div>
  )
}

export default LayerList
