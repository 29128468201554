import { Alert, Box, Button, CircularProgress, IconButton, List, ListItem, Stack } from '@mui/material'
import { GeoTiffLayer } from 'api/schema'
import useZoomToPolygon from 'hooks/useZoomToPolygon'
import { ReactComponent as CancelSvg } from 'images/icons/cancel.svg'
import { ReactComponent as SaveSvg } from 'images/icons/save.svg'
import { Feature } from 'ol'
import { Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import useMapStore from 'store/map'
import useMissionStore from 'store/mission'
import { MISSION_MAP_DRAWING_MODES, MISSION_PAGE_MODES } from 'utils/constants'
import { useShallow } from 'zustand/react/shallow'

import GeoTiffHeader from './GeoTiffHeader'
import GeoTiffsInMission from './GeoTiffsInMission'

function GeoTiffView() {
  const { t } = useTranslation()
  const map = useMapStore(s => s.map)

  const geoTiffsInExtent = useMapStore(s => s.geoTiffsInExtent)
  const getGeotiffsInExtent = useMapStore(s => s.getGeotiffsInExtent)
  const [refreshLoading, setRefreshLoading] = useState(false)
  const displayGeotiffs = useMapStore(s => s.displayGeotiffs)
  const toggleGeotiff = useMapStore(s => s.toggleGeotiff)
  const saveGeotiffForMission = useMapStore(s => s.saveGeotiffForMission)
  const [saveLoading, setSaveLoading] = useState(false)
  const [saveError, setSaveError] = useState('')
  const [uploadError, setUploadError] = useState('')
  const [highlightLayer, setHighlightLayer] = useState<VectorLayer<VectorSource> | null>(null)
  const [allLayer, setAllLayer] = useState<VectorLayer<VectorSource> | null>(null)
  const [search, setSearch] = useState('')

  const { missionInfo, setMode, setMapMode, fetchMission } = useMissionStore(
    useShallow(s => ({
      missionInfo: s.missionInfo,
      setMode: s.setMode,
      setMapMode: s.setMapMode,
      fetchMission: s.fetchMission,
    }))
  )

  const { zoomToPolygon } = useZoomToPolygon()

  const handleRefreshClick = () => {
    setRefreshLoading(true)
    getGeotiffsInExtent().then(() => setRefreshLoading(false))
  }

  const handleSaveClick = () => {
    if (!missionInfo) return
    setSaveLoading(true)
    setSaveError('')
    saveGeotiffForMission(missionInfo.id).then(result => {
      setSaveLoading(false)
      if (result instanceof Error) {
        return setSaveError(result.message)
      }
      setMode(MISSION_PAGE_MODES.VIEW_ZONES)
      setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
      refresh()
    })
  }

  const refresh = () => {
    missionInfo && fetchMission(missionInfo.id)
  }

  const handleGeoTiffClick = (geotiff: GeoTiffLayer) => {
    if (!geotiff.bbox || !geotiff.bbox.coordinates) return

    const feature = new Feature({
      geometry: new Polygon(geotiff.bbox.coordinates).transform('EPSG:4326', 'EPSG:3857'),
    })

    zoomToPolygon(feature.getGeometry() as Polygon)
  }

  const handleCancelClick = () => {
    setMode(MISSION_PAGE_MODES.VIEW_ZONES)
    setMapMode(MISSION_MAP_DRAWING_MODES.VIEW_ZONES)
  }

  const handleHoverEnter = (geotiff: GeoTiffLayer) => {
    if (!geotiff.bbox || !geotiff.bbox.coordinates) return
    const feature = new Feature({
      geometry: new Polygon(geotiff.bbox.coordinates).transform('EPSG:4326', 'EPSG:3857'),
    })
    highlightLayer?.getSource()?.addFeature(feature)
  }
  const handleHoverLeave = () => {
    highlightLayer?.getSource()?.clear()
  }

  useEffect(() => {
    allLayer?.getSource()?.clear()
    if (geoTiffsInExtent.length === 0) return

    const features = geoTiffsInExtent
      .map(geotiff => {
        if (!geotiff.bbox || !geotiff.bbox.coordinates) return null
        return new Feature({
          geometry: new Polygon(geotiff.bbox.coordinates).transform('EPSG:4326', 'EPSG:3857'),
        })
      })
      .filter(f => f !== null) as Feature[]
    allLayer?.getSource()?.addFeatures(features)
  }, [geoTiffsInExtent, map, allLayer])

  useEffect(() => {
    getGeotiffsInExtent()
    const hlLayer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 10,
    })
    map?.addLayer(hlLayer)
    setHighlightLayer(hlLayer)

    const allLayer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 9,
      style: {
        'stroke-color': 'grey',
        'stroke-width': 1,
      },
    })
    map?.addLayer(allLayer)
    setAllLayer(allLayer)

    return () => {
      map?.removeLayer(hlLayer)
      map?.removeLayer(allLayer)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  return (
    <Stack width="100%" height="100%" minHeight="250px" justifyContent="space-between" overflow="auto">
      <List>
        <GeoTiffHeader
          missionId={missionInfo?.id}
          searchValue={search}
          onSearchChange={v => setSearch(v)}
          onRefreshClick={handleRefreshClick}
          onUpload={() => refresh()}
          onUploadError={e => setUploadError(e)}
        />
        <ListItem>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Box paddingLeft="8px">File Name</Box>
            <Box width="40%" paddingLeft="76px">
              Opacity
            </Box>
            <Box paddingRight="36px">Date</Box>
          </Stack>
        </ListItem>
        {uploadError && <Alert severity="error">{uploadError}</Alert>}
        <GeoTiffsInMission />
        {refreshLoading ? (
          <ListItem>
            <CircularProgress />
          </ListItem>
        ) : (
          geoTiffsInExtent
            .filter(tiff => !displayGeotiffs.some(l => l.id === tiff.id) && tiff.name?.toLowerCase().includes(search))
            .map(geoTiff => (
              <ListItem key={geoTiff.id} onMouseEnter={() => handleHoverEnter(geoTiff)} onMouseLeave={handleHoverLeave}>
                <Stack direction="row" justifyContent="space-between" width="100%">
                  <Box display="flex" alignItems="center">
                    <span>
                      <IconButton onClick={() => toggleGeotiff(geoTiff.id)}>
                        <AiOutlineCheckCircle />
                      </IconButton>
                    </span>
                    <span onClick={() => handleGeoTiffClick(geoTiff)} style={{ fontSize: '1rem', cursor: 'pointer' }}>
                      {geoTiff.name || '-'}
                    </span>
                  </Box>

                  <Box display="flex" justifyContent="center" alignItems="center">
                    {new Date(geoTiff.created_at).toLocaleDateString('ru-RU')}
                  </Box>
                </Stack>
              </ListItem>
            ))
        )}
      </List>

      {saveError && <Alert severity="error">{saveError}</Alert>}
      <Stack direction="row" justifyContent="flex-end" padding={2} gap={2}>
        <Button onClick={handleCancelClick} color="warning" variant="contained" fullWidth endIcon={<CancelSvg />}>
          {t('mission_settings.label_cancel')}
        </Button>
        <Button
          disabled={saveLoading}
          onClick={handleSaveClick}
          color="primary"
          variant="contained"
          fullWidth
          endIcon={saveLoading ? <CircularProgress size={16} color="secondary" /> : <SaveSvg />}
        >
          {t('mission_settings.label_save')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default GeoTiffView
