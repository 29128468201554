import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import XYZ from 'ol/source/XYZ'
import { useEffect } from 'react'
import useMapStore from 'store/map'

function BaseMap() {
  const map = useMapStore(s => s.map)
  const isSatellite = useMapStore(s => s.isSatellite)

  useEffect(() => {
    if (!map) return

    const layer = new TileLayer({
      source: isSatellite
        ? new XYZ({
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            maxZoom: 19,
          })
        : new OSM(),
      zIndex: 0,
    })

    map.addLayer(layer)
    return () => {
      map.removeLayer(layer)
    }
  }, [map, isSatellite])

  return null
}

export default BaseMap
