import RobotServiceModal from '../RobotServiceModal'
import { Button } from '@mui/material'
import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { Maintenance } from 'api/schema'
import OverlayNoRowsTable from 'components/OverlayNoRowsTable/OverlayNoRowsTable'
import { ReactComponent as SortIcon } from 'images/icons/sort_icon.svg'
import { useState } from 'react'
import { serviceStatusWorks } from 'utils/constants'
import { StyledMinDataGrid } from 'utils/themeMinGrid'

interface PropsService {
  services: Maintenance[]
  type: 'current' | 'planned' | 'last'
}

const ServiceWorksTable = ({ services, type }: PropsService) => {
  const [showModal, setShowModal] = useState(false)
  const [index, setIndex] = useState(0)

  const handleModalClose = () => {
    setShowModal(false)
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.2,
      disableColumnMenu: true,
    },
    {
      field: 'date_prev',
      headerName: 'Previous date',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: ({ row }: GridValueGetterParams<Maintenance>): string =>
        row.date_prev ? new Date(row.date_prev).toLocaleString() : '',
    },
    {
      field: 'date_next',
      headerName: 'Next date',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: ({ row }: GridValueGetterParams<Maintenance>): string =>
        row.date_next ? new Date(row.date_next).toLocaleString() : '',
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.5,
      renderCell: (params: GridCellParams<Maintenance>) => (
        <Button
          color={type === 'current' ? 'primary' : 'warning'}
          variant="contained"
          size="small"
          // eslint-disable-next-line no-console
          onClick={() => {
            setShowModal(true), setIndex(services.findIndex(item => item.id === params.row.id))
          }}
        >
          {type === 'current' ? 'Perform' : 'Delete'}
        </Button>
      ),
    },
  ]

  if (type === 'last') {
    columns[4] = {
      field: 'stage',
      headerName: 'State',
      flex: 0.5,
      disableColumnMenu: true,
      valueGetter: ({ row }: GridValueGetterParams<Maintenance>): string =>
        row.state ? serviceStatusWorks[row.state] : '',
    }
  }

  return (
    <>
      <StyledMinDataGrid
        rows={services}
        columns={columns}
        hideFooter={true}
        autoHeight={true}
        showColumnVerticalBorder={false}
        showCellVerticalBorder={false}
        disableRowSelectionOnClick={true}
        columnHeaderHeight={35}
        slots={{
          columnSortedAscendingIcon: SortIcon,
          columnSortedDescendingIcon: SortIcon,
          noRowsOverlay: () => <OverlayNoRowsTable colorIcon="white" text="No service works found" />,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'dateNext', sort: 'asc' }],
          },
        }}
        sx={{ m: 0, border: 0 }}
      />
      {showModal && (
        <RobotServiceModal show={showModal} onClose={handleModalClose} services={services[index]} type={type} />
      )}
    </>
  )
}

export default ServiceWorksTable
