import { useQuery } from '@tanstack/react-query'
import { RobotApi } from 'api/RobotApi'
import { PaginatedMaintenanceList } from 'api/schema'
import { useParams } from 'react-router-dom'

export const useMaintenanceRobot = () => {
  const params = useParams()

  return useQuery<PaginatedMaintenanceList, Error>({
    queryKey: ['services', params.assetId],
    queryFn: () => RobotApi.fetchMaintenanceList(Number(params.assetId)),
    staleTime: 1000 * 60 * 3, // 3 minutes
    refetchOnWindowFocus: true,
  })
}
