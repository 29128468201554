import { Box, Button, Stack, Typography } from '@mui/material'
import OverlayNoRowsTable from 'components/OverlayNoRowsTable/OverlayNoRowsTable'
import useRobotsInUnitTable from 'hooks/useRobotsInUnitTable'
import { ReactComponent as AddSvg } from 'images/icons/add.svg'
import { ReactComponent as SortIcon } from 'images/icons/sort_icon.svg'
import { useState } from 'react'
import { StyledMinDataGrid } from 'utils/themeMinGrid'

import UnitRobotModal from './UnitRobotModal'

const UnitRobotsList = () => {
  const { columnsUnitRobots, robots, countRobots } = useRobotsInUnitTable()

  const [showModal, setShowModal] = useState(false)

  const handleModalClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <Stack direction="column" bgcolor="info.main" height="100%" borderRadius={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" padding={1} paddingX={2}>
          <Box display="flex">
            <Typography variant="body2" mr={1}>
              Robots:
            </Typography>
            <Typography variant="body2">{countRobots}</Typography>
          </Box>

          <Button
            onClick={() => setShowModal(true)}
            variant="contained"
            color="primary"
            endIcon={<AddSvg />}
            size="small"
          >
            Add / Remove robot
          </Button>
        </Stack>

        <StyledMinDataGrid
          rows={robots}
          columns={columnsUnitRobots}
          hideFooter={true}
          showColumnVerticalBorder={false}
          showCellVerticalBorder={false}
          disableRowSelectionOnClick={true}
          columnHeaderHeight={35}
          slots={{
            columnSortedAscendingIcon: SortIcon,
            columnSortedDescendingIcon: SortIcon,
            noRowsOverlay: () => <OverlayNoRowsTable colorIcon="white" />,
          }}
          sx={{ my: 1, mx: 2, border: 0 }}
        />
      </Stack>
      {showModal && <UnitRobotModal show={showModal} onClose={handleModalClose} unitRobots={robots} />}
    </>
  )
}

export default UnitRobotsList
