import { ReactNode } from 'react'

interface Props {
  children: ReactNode
}
function Layers({ children }: Props) {
  return <>{children}</>
}

export default Layers
