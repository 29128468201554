import { Box, Grid } from '@mui/material'
import Loader from 'components/Loader/Loader'
import AllCleaningZonesLayer from 'components/Map/AllCleaningZonesLayer'
import BaseMap from 'components/Map/BaseMap'
import Layers from 'components/Map/Layers'
import MapContainer from 'components/Map/MapContainer'
import ObjectsOfInterestLayer from 'components/Map/ObjectsOfInterestLayer'
import UnitLayer from 'components/Map/UnitLayer'
import Widgets from 'components/Map/Widgets'
import LayerList from 'components/Map/widgets/LayerList'
import UnitView from 'components/Unit/UnitView'
import useObjectsOfInterest from 'hooks/useObjectsOfInterest'
import useUnit from 'hooks/useUnit'
import { useParams } from 'react-router-dom'

import style from './UnitPage.module.css'

const UnitPage = () => {
  const params = useParams()
  const { data: unit, isLoading } = useUnit(Number(params.unitId))
  const { data: objectsOfInterest = [] } = useObjectsOfInterest()

  return (
    <div className={style.container}>
      <Grid container height="100%" width="100%">
        <Grid xs={12} md={6} item height="100%" paddingY={2}>
          {isLoading ? <Loader /> : <UnitView unit={unit} />}
        </Grid>

        <Grid xs={12} md={6} item height="100%" paddingY={2}>
          <Box width="100%" height="100%" borderRadius={2} overflow="hidden">
            <MapContainer>
              <Layers>
                <BaseMap />
                <UnitLayer point={unit?.point} id={params.unitId} />
                <ObjectsOfInterestLayer objectsOfInterest={objectsOfInterest} />
                <AllCleaningZonesLayer />
              </Layers>
              <Widgets>
                <LayerList />
              </Widgets>
            </MapContainer>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default UnitPage
