import { useMutation } from '@tanstack/react-query'
import { RobotApi } from 'api/RobotApi'
import { ROBOT_MODES } from 'utils/constants'

interface SendData {
  id: number
  mode: ROBOT_MODES
}
const useSetRobotMode = () =>
  useMutation<void, Error, SendData>({
    mutationFn: RobotApi.switchMode,
  })

export default useSetRobotMode
