import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Loader from 'components/Loader/Loader'
import { UserProvider, getInitialAccountState } from 'hooks/user'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import './i18n'
import reportWebVitals from './reportWebVitals'
import { router } from './router/router'
import { theme } from './utils/theme'

import './index.css'

const queryClient = new QueryClient()

const init = async () => {
  const initialAccountState = await getInitialAccountState()
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

  root.render(
    <React.StrictMode>
      <Suspense fallback={<Loader />}>
        <QueryClientProvider client={queryClient}>
          <UserProvider initialState={initialAccountState}>
            <ThemeProvider theme={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </UserProvider>
        </QueryClientProvider>
      </Suspense>
    </React.StrictMode>
  )
}
/* eslint-disable-next-line no-console */
init().then(() => console.info('App is init. 🔥'))
reportWebVitals()
