import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { ReactComponent as QuestionMarkIcon } from 'images/icons/faq.svg'

interface Props {
  distance?: number
  passed?: number
  timeLeft?: string
  possibleCov?: number
  cleanedPercent?: number
}

const RobotRouteBlock = ({ distance, passed, timeLeft, possibleCov, cleanedPercent }: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      height="100%"
      borderRadius={2}
      paddingX={1}
      bgcolor="info.main"
    >
      <Stack direction="column" paddingY={1}>
        <Typography variant="headerTile" color="#9E9E9E" marginBottom={2}>
          Current route
        </Typography>
        <Stack direction="row" alignItems="center">
          <Typography variant="subTitleTile" marginRight={1}>
            Distance:
          </Typography>
          <Typography variant="textMinTile">{distance ? Math.round(distance) : distance === 0 ? 0 : '-'}m</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography variant="subTitleTile" marginRight={1}>
            Passed:
          </Typography>
          <Typography variant="textMinTile">{passed ? Math.round(passed) : passed === 0 ? 0 : '-'}m</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography variant="subTitleTile" marginRight={1}>
            Time left:
          </Typography>
          <Typography variant="textMinTile">{timeLeft || '-'}</Typography>
        </Stack>
      </Stack>
      <Stack direction="column" paddingY={1}>
        <Typography variant="headerTile" color="#9E9E9E" marginBottom={2}>
          Current zone
        </Typography>
        <Stack direction="row" alignItems="center">
          <Typography variant="subTitleTile" marginRight={1}>
            Possible cov:
          </Typography>
          <Typography variant="textMinTile">
            {possibleCov ? Math.round(possibleCov * 100) : possibleCov === 0 ? 0 : '-'}%
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography variant="subTitleTile" marginRight={1}>
            Cleaned percent:
          </Typography>
          <Typography variant="textMinTile">
            {cleanedPercent ? Math.round(cleanedPercent * 100) : cleanedPercent === 0 ? 0 : '-'}%
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="column" paddingY={1}>
        <Tooltip title="" placement="top">
          <IconButton size="small">
            <QuestionMarkIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default RobotRouteBlock
