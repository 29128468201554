import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import Loader from 'components/Loader/Loader'
import { useMaintenanceRobot } from 'hooks/useAllMaintenanceRobot'
import { ReactComponent as QuestionMarkIcon } from 'images/icons/faq.svg'

import ServiceWorksTable from './RobotService/ServiceWorksTable'

import style from './RobotMissionDetails.module.css'

const RobotService = () => {
  const { data: services, isLoading } = useMaintenanceRobot()

  if (isLoading) return <Loader />

  return (
    <div className={style.tableWrapperOverflow}>
      <Box className={style.listSelection} paddingTop={1.5} paddingLeft={2} paddingRight={2} paddingBottom={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="headerRobot">Service works</Typography>
          <Tooltip title="¯\_(ツ)_/¯" placement="top">
            <IconButton size="small">
              <QuestionMarkIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Accordion>
          <AccordionSummary>Current service works</AccordionSummary>
          <AccordionDetails>
            <ServiceWorksTable services={(services?.results || []).filter(work => work.state === 1)} type="current" />
          </AccordionDetails>
        </Accordion>
      </Box>

      <Divider sx={{ m: 1 }} />

      <Box className={style.listSelection} paddingTop={0.5} paddingLeft={2} paddingRight={2} paddingBottom={0.5}>
        <Accordion>
          <AccordionSummary>Planned service works</AccordionSummary>
          <AccordionDetails>
            <ServiceWorksTable services={(services?.results || []).filter(work => work.state === 0)} type="planned" />
          </AccordionDetails>
        </Accordion>
      </Box>

      <Divider sx={{ m: 1 }} />

      <Box className={style.listSelection} paddingTop={0.5} paddingLeft={2} paddingRight={2} paddingBottom={0.5}>
        <Accordion>
          <AccordionSummary>Last service works</AccordionSummary>
          <AccordionDetails>
            <ServiceWorksTable
              services={(services?.results || []).filter(work => work.state !== 0 && work.state !== 1)}
              type="last"
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  )
}

export default RobotService
