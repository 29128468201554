import { useEffectOnce } from 'hooks/useEffectOnce'
import { Map, View } from 'ol'
import Attribution from 'ol/control/Attribution.js'
import Zoom from 'ol/control/Zoom.js'
import { ReactNode, useRef } from 'react'
import useMapStore from 'store/map'

import './ol.css'

interface Props {
  children?: ReactNode
  hideControls?: boolean
}
function MapContainer({ children, hideControls }: Props) {
  const setMap = useMapStore(s => s.setMap)
  const mapRef = useRef<null | HTMLDivElement>(null)

  useEffectOnce(() => {
    const control = hideControls
      ? []
      : [
          new Zoom({
            className: 'ol-control-custom ol-control-custom-zoom',
            zoomInLabel: '',
            zoomOutLabel: '',
          }),
          new Attribution(),
        ]

    const map = new Map({
      controls: control,
      layers: [],
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
    })
    if (mapRef.current) map.setTarget(mapRef.current)
    setMap(map)
  })
  return (
    <div ref={mapRef} style={{ width: '100%', height: '100%' }}>
      {children}
    </div>
  )
}

export default MapContainer
