import { useQuery } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { IMissionInfo } from 'utils/interfaces'

const useAllMissions = () => {
  return useQuery<IMissionInfo[], Error>({
    queryKey: ['missions'],
    queryFn: () => MainApi.fetchMissions(500),
    initialData: [],
    refetchOnWindowFocus: true,
  })
}
export default useAllMissions
