import { ReactComponent as ZoneEmpty } from 'images/icons/zone_empty.svg'
import { useTranslation } from 'react-i18next'

import CollapsibleTableScenarios from './CollapsibleTableScenarios/CollapsibleTableScenarios'

import style from './TableScenariosList.module.css'

interface PropsTableUnit {
  showTable?: boolean
}
const TableScenariosList = ({ showTable = true }: PropsTableUnit) => {
  const { t } = useTranslation()
  return (
    <div>
      {showTable ? (
        <CollapsibleTableScenarios />
      ) : (
        <div>
          <div className={style.emptyWrapper}>
            <ZoneEmpty />
            <div className={style.emptyTitle}>{t('units.no_zones_title_scenarios')}</div>
            <div className={style.emptyDescription}>{t('units.no_zones_description')}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TableScenariosList
